import brand from '@helpers/brand';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
const Container = styled.div `
  display: flex;
  gap: 15px;
  align-items: center;
  font-size: 14px;
  user-select: none;
  cursor: pointer;
  width: max-content;
  max-width: 100%;
  transition: opacity 0.3s ease-in-out;

  ${({ disabled }) => disabled &&
    css `
      opacity: 0.7;
      pointer-events: none;
      cursor: not-allowed;
    `}
`;
const Box = styled.div `
  width: 25px;
  height: 25px;
  background-color: ${brand.white};
  border: 1px solid ${brand.black};
  position: relative;
`;
const Check = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            y: 10,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
        },
    },
    transition: {
        duration: 0.35,
    },
})) `
  width: 100%;
  height: 100%;
  color: ${brand.primary.alt};

  svg {
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    display: block;
    stroke-width: 3px;
    top: -7px;
    left: 0px;
    position: relative;
  }
`;
export const CheckboxStyles = {
    Container,
    Box,
    Check,
};
