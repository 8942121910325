import React, { useEffect } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { DimensionsStyles as S } from './Dimensions.styles';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import InfoIcon from '@ys/Components/InfoIcon/InfoIcon';
import NumberInput from '../../Shared/NumberInput/NumberInput';
// Visualizer settings.
export const MAX_SIZE = 140;
const Dimensions = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    useEffect(() => {
        //
        dispatch({
            type: 'set-footer',
            payload: {
                beforeNext: () => dispatch({ type: 'validate-dimensions' }),
            },
        });
    }, []);
    const handleSet = (value, isWidth) => {
        if (value === undefined) {
            if (isWidth) {
                setFormField('width', undefined);
            }
            else {
                setFormField('length', undefined);
            }
            return;
        }
        let floatValue = parseFloat(value);
        if (floatValue > 99999) {
            floatValue = 99999;
        }
        if (value === '' || floatValue <= 0 || isNaN(floatValue)) {
            if (isWidth) {
                setFormField('width', undefined);
            }
            else {
                setFormField('length', undefined);
            }
            return;
        }
        if (isWidth) {
            setFormField('width', floatValue);
        }
        else {
            setFormField('length', floatValue);
        }
    };
    const ratio = (state.form.width || 1) / (state.form.length || 1);
    const actualHeight = ratio > 1 ? (1 / ratio) * MAX_SIZE : MAX_SIZE;
    const actualWidth = ratio < 1 ? ratio * MAX_SIZE : MAX_SIZE;
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            React.createElement(SharedStyles.TitleWithInfoIcon, null,
                React.createElement("h2", null, state.labels['heading']),
                state.labels['tooltip'] && (React.createElement(InfoIcon, { onClick: () => {
                        dispatch({
                            type: 'set-modal',
                            payload: {
                                description: state.labels['tooltip'],
                                fullWidth: true,
                                size: 'normal',
                            },
                        });
                    } }))),
            React.createElement(FormStyles.FormRow, null,
                React.createElement(S.Container, null,
                    React.createElement(S.Outer, { style: { width: `${actualHeight}px`, height: `${actualWidth}px` } },
                        React.createElement(S.Inner, null,
                            React.createElement(S.Arrow, { direction: "width", style: { height: `${actualWidth}px` } }, !isNaN(state.form.width || 0) && (React.createElement("span", null,
                                (state.form.width || 0).toFixed(2),
                                "m"))),
                            React.createElement(S.Arrow, { direction: "length", style: { width: `${actualHeight}px` } }, !isNaN(state.form.width || 0) && (React.createElement("span", null,
                                (state.form.length || 0).toFixed(2),
                                "m"))))))),
            React.createElement(FormStyles.FormRow, { split: true },
                React.createElement(FormStyles.FormControl, { invalid: state.errors['length'] !== undefined },
                    React.createElement("label", { htmlFor: "txtLength" }, "Length"),
                    React.createElement(NumberInput, { id: "txtLength", value: state.form.length, onChange: (e) => handleSet(e, false), step: 0.25 }),
                    React.createElement(FormStyles.FormSub, null, "m")),
                React.createElement(FormStyles.FormControl, { invalid: state.errors['width'] !== undefined },
                    React.createElement("label", { htmlFor: "txtWidth" }, "Width"),
                    React.createElement(NumberInput, { id: "txtWidth", value: state.form.width, onChange: (e) => handleSet(e, true), step: 0.25 }),
                    React.createElement(FormStyles.FormSub, null, "m"))))));
};
export default Dimensions;
