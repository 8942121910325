import { isSSR } from '@helpers/ssr';
import React, { useEffect, useMemo, useState } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { StepPickerStyles as S } from './StepPicker.styles';
import { ReactComponent as Chevron } from '@img/icons/chevron-right.svg';
import { StepID } from '@ys/Context/types';
import { AnimatePresence } from 'framer-motion';
const StepPicker = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { state, dispatch } = useYSPContext();
    const filteredSteps = state.steps.filter((x) => x.title !== undefined);
    const currentStep = useMemo(() => {
        const currentGlobalStep = state.steps[state.globalStep];
        if (currentGlobalStep.title) {
            return currentGlobalStep;
        }
        else {
            let step = undefined;
            let i = state.globalStep - 1;
            do {
                const current = state.steps[i];
                if (current.title) {
                    step = current;
                }
                if (i <= 0 && !step) {
                    // Should never reach here but just in case //
                    step = {
                        id: -1,
                        step: StepID.Landing,
                        type: 'default',
                        title: '???',
                    };
                }
                i--;
            } while (step === undefined);
            return step;
        }
    }, [state.globalStep]);
    const handleWindowClick = () => {
        setIsOpen(false);
    };
    useEffect(() => {
        if (!isSSR()) {
            window.addEventListener('click', handleWindowClick);
        }
        return () => window.removeEventListener('click', handleWindowClick);
    }, []);
    if (currentStep === undefined) {
        return null;
    }
    return (React.createElement(S.Container, { zoomModalOpen: state.zoomModalOpen, active: isOpen, onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
            setIsOpen(!isOpen);
        } },
        React.createElement(S.BlurLine, null),
        React.createElement(S.SelectedStep, { isOpen: isOpen },
            currentStep.title,
            React.createElement(Chevron, null)),
        React.createElement(AnimatePresence, null,
            React.createElement(S.Items, { key: 'StepPicker', active: isOpen },
                React.createElement(S.ItemsInner, null, filteredSteps.map((step) => (React.createElement(S.Step, { key: step.id, active: step.id === currentStep.id, completed: step.id < currentStep.id, onClick: (e) => {
                        e.preventDefault();
                        if (step.id >= currentStep.id) {
                            return;
                        }
                        dispatch({ type: 'go-to-step', payload: step.id });
                    } }, step.title))))))));
};
export default StepPicker;
