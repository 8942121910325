import brand from '@helpers/brand';
import { Device, from } from '@helpers/media';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
const Container = styled(m.div).attrs(() => ({
    layout: true,
})) `
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;
const Item = styled.div `
  border: 1px solid #c7c7c7;
  padding: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-bottom: 18px;
  align-self: center;
  margin-right: 12px;
  position: relative;

  ${({ invalid }) => invalid &&
    css `
      border: 1px solid ${brand.red.base};
    `}

  ${({ active }) => active &&
    css `
      border: 1px solid ${brand.primary.light};

      ${() => Value}::after {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    `};

  &:last-child {
    margin-right: 0px;
  }

  @media ${from(Device.MobileMedium)} {
    align-self: auto;
    padding: 20px;
  }
`;
const BottomEffect = styled(m.div) `
  top: 100%;
  left: -1px;
  position: absolute;
  width: calc(100% + 2px);
  height: 6px;
  background-color: ${brand.primary.light};
`;
const Value = styled.div `
  width: 24px;
  height: 24px;
  border: 1px solid ${brand.grey.grey20};
  border-radius: 50%;
  margin-right: 7px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    background-color: ${brand.primary.light};
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
    transition: opacity 0.15s ease-in-out, transform 0.25s ease-in-out;
  }
`;
const Label = styled.div `
  font-size: 14px;
  font-weight: normal;

  @media ${from(Device.MobileMedium)} {
    font-size: 17px;
  }
`;
export const RadiosStyles = {
    Container,
    Item,
    Value,
    Label,
    BottomEffect,
};
