import { imageUrl } from '@helpers/cloudinary';
import { ReactComponent as Caret } from '@img/icons/caret.svg';
import { ReactComponent as Check } from '@img/ysp/icon-check-circle.svg';
import { ReactComponent as Restart } from '@img/ysp/restart.svg';
import { ReactComponent as Tick } from '@img/ysp/tick.svg';
import { WYSIWYGStyles } from '@stories/Components/Content/WYSIWYG/WYSIWYG.styles';
import { StepID, Tab } from '@stories/Widgets/YourSolution/YS/Context/types';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { Containers } from '@ys/Components/Shared/Step.styles';
import { YSLinkButton } from '@ys/Components/Shared/YSButton/YSButton';
import React, { useState } from 'react';
import Chart from '../Chart/Chart';
import Graph from '../Graph/Graph';
import { MomentsTable, StrutsTable } from '../Solution/Solution';
import { SolutionStyles } from '../Solution/Solution.styles';
import ZoomImage from '../ZoomImage/ZoomImage';
import { SummaryStyles as S } from './Summary.styles';
const Summary = () => {
    const { state, dispatch } = useYSPContext();
    const [loading, setLoading] = useState(false);
    if (!state.selectedSolution) {
        return React.createElement(React.Fragment, null);
    }
    const sendToEngineer = async () => {
        dispatch({
            type: 'set-modal',
            payload: {
                size: 'normal',
                fullWidth: false,
                engineerCheckLabels: {
                    heading: state.labels['engineer.modal.heading'],
                    content: state.labels['engineer.modal.content'],
                    success: state.labels['engineer.modal.success'],
                },
            },
        });
        setLoading(false);
    };
    const data = state.selectedSolution;
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(S.GridWrapper, null,
            React.createElement(Containers.Wide, null,
                React.createElement(S.Container, null,
                    React.createElement(S.TopContent, null,
                        React.createElement(Tick, null),
                        state.labels['content'] && (React.createElement(WYSIWYGStyles.Wrapper, { dangerouslySetInnerHTML: {
                                __html: state.labels['content'].replaceAll('{email}', state.form.email ?? ''),
                            } })),
                        React.createElement(S.BackButton, { onClick: () => dispatch({ type: 'previous-step' }) },
                            React.createElement(Caret, null),
                            "Return to your solutions"),
                        React.createElement(S.ButtonContainer, null,
                            React.createElement(SolutionStyles.LongSmallButton, { orange: true, disabled: loading, onClick: () => sendToEngineer() }, "Request a check from Groundforce"),
                            React.createElement(S.RestartButton, { onClick: () => {
                                    if (confirm('Are you sure you want to restart? This will erase all your data entered thus far.')) {
                                        dispatch({ type: 'restart' });
                                        dispatch({ type: 'set-tab', payload: Tab.YourSolutionPlus });
                                        dispatch({ type: 'go-to-step', payload: StepID.Landing });
                                    }
                                } },
                                React.createElement(Restart, null),
                                "Restart Your",
                                React.createElement("span", null, "Solution"),
                                React.createElement("span", null, "+")))),
                    React.createElement(S.Solution, null,
                        React.createElement(S.TopSection, null,
                            React.createElement(ZoomImage, { imageUrl: imageUrl(data.solution.image, 'c_fill,w_300') }),
                            React.createElement(S.ImageWrapper, null,
                                React.createElement("img", { src: imageUrl(data.solution.image, 'c_fill,w_300'), alt: data.solution.name })),
                            React.createElement(S.Details, null,
                                React.createElement(S.Title, null, data.solution.name),
                                React.createElement(S.DesignReference, null,
                                    "Design reference ",
                                    data.designReference),
                                React.createElement(S.Table, null, data.boxInfo.map((info, i) => (React.createElement(S.Row, { key: `Summary_BoxInfo_${i}` },
                                    React.createElement("span", null,
                                        info.item1,
                                        ":"),
                                    " ",
                                    React.createElement("span", null, info.item2)))))),
                            React.createElement(S.SolutionTag, null,
                                React.createElement(Check, null),
                                " Solution Generated"),
                            React.createElement(S.EndSection, null,
                                React.createElement(YSLinkButton, { fill: "outline", href: data.designDoc, target: "_blank" }, "Download Solution"))),
                        React.createElement(S.TableContainers, null,
                            React.createElement(S.TableWrapper, null,
                                React.createElement(MomentsTable, { solution: data.solution, labels: state.labels })),
                            React.createElement(S.TableWrapper, null,
                                React.createElement(StrutsTable, { solution: data.solution, labels: state.labels }))),
                        React.createElement(S.GraphsWrapper, null,
                            React.createElement(Graph, null),
                            React.createElement(Chart, { chart: data.graphs.horizontalPressure, title: data.graphs.horizontalPressure.heading }),
                            React.createElement(Chart, { chart: data.graphs.shearForce, title: data.graphs.shearForce.heading }),
                            React.createElement(Chart, { chart: data.graphs.kickingMoments, title: data.graphs.kickingMoments.heading }))))))));
};
export default Summary;
