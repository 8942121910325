import ApiService from '@core/api/services/ApiService';
import Loading from '@ys/Components/Loading/Loading';
import StepLoading from '@ys/Components/StepLoading/StepLoading';
import Groundwater from '@ys/Components/Steps/Conditions/Groundwater/Groundwater';
import GroundwaterDepth from '@ys/Components/Steps/Conditions/GroundwaterDepth/GroundwaterDepth';
import ProblematicGround from '@ys/Components/Steps/Conditions/ProblematicGround/ProblematicGround';
import SoilTypes from '@ys/Components/Steps/Conditions/SoilTypes/SoilTypes';
import Depth from '@ys/Components/Steps/Depth/Depth';
import Details from '@ys/Components/Steps/Details/Details';
import Dimensions from '@ys/Components/Steps/Dimensions/Dimensions';
import Landing from '@ys/Components/Steps/Landing/Landing';
import Location from '@ys/Components/Steps/Location/Location';
import NoSolutions from '@ys/Components/Steps/NoSolutions/NoSolutions';
import Solutions from '@ys/Components/Steps/Solutions/Solutions';
import SheetedSolution from '@ys/Components/Steps/Support/SheetedSolution/SheetedSolution';
import System from '@ys/Components/Steps/Support/System/System';
import AdjacentWater from '@ys/Components/Steps/Surcharges/AdjacentWater/AdjacentWater';
import LargeSurcharges from '@ys/Components/Steps/Surcharges/LargeSurcharges/LargeSurcharges';
import SlopingGround from '@ys/Components/Steps/Surcharges/SlopingGround/SlopingGround';
import Type from '@ys/Components/Steps/Type/Type';
import { ConditionStepID, DimensionsStepID, SoilGroundConditionsStepID, StepID, SupportStepID, SurchargeStepID, Tab, } from '@ys/Context/types';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import EndSafePanels from '../Steps/EndSafePanels/EndSafePanels';
import EndSafeSides from '../Steps/EndSafeSides/EndSafeSides';
import SoilProfiles from '../Steps/SoilProfiles/SoilProfiles';
import SoilSource from '../Steps/SoilProfiles/SoilSource/SoilSource';
import Summary from '../Steps/Solutions/YSP/Summary/Summary';
import YSPSolutions from '../Steps/Solutions/YSP/YSPSolutions';
import SurchargeDetails from '../Steps/Surcharges/SurchargeDetails/SurchargeDetails';
import TermsAndConditions from '../Steps/TermsAndConditions/TermsAndConditions';
import { dummyData } from './dummy';
const getYSPStep = (state) => {
    // Because of the different steps on both YS and YS+ using the same enum, there's a difference in the enum values //
    // Need to have some kind of middle man function to reconcile this before sending it to the backend //
    /*
      Landing = 0,
      Details = 1,
      Location = 2,
      Depth = 3,
      Dimensions = 4,
      EndSafe = 5,
      SoilProfiles = 6,
      SoilInfo = 7,
      Groundwater = 8,
      Surcharges = 9,
      Solutions = 10,
      SelectedSolution = 11,
     */
    switch (state.step) {
        default:
        case StepID.Landing:
        case StepID.Details:
        case StepID.Location:
        case StepID.Depth:
            // These steps are fine and already match up //
            return state.step;
        case StepID.TermsAndConditions:
            return StepID.Landing; // Landing step returns Terms & Conditions content //
        case StepID.Dimensions:
            switch (state.dimensionsStep) {
                default:
                case DimensionsStepID.Size:
                    return state.step;
                case DimensionsStepID.Type:
                case DimensionsStepID.EndSafePanels:
                case DimensionsStepID.EndSafeSides:
                    return 5;
            }
        case StepID.SoilsGroundConditions:
            switch (state.soilGroundConditionsStep) {
                default:
                case SoilGroundConditionsStepID.SoilProfiles:
                    return 6;
                case SoilGroundConditionsStepID.SoilInformationSource:
                    return 7;
                case SoilGroundConditionsStepID.Groundwater:
                case SoilGroundConditionsStepID.GroundwaterDepth:
                    return 8;
            }
        case StepID.Surcharges:
            return 9;
        case StepID.Solutions:
            return 10;
        case StepID.SelectedSolution:
            return 11;
    }
};
const StepRouter = (props) => {
    const [initialLoad, setInitialLoad] = useState(true);
    const { state, dispatch } = props;
    useEffect(() => {
        setInitialLoad(false);
    }, []);
    useEffect(() => {
        async function fetchStep() {
            dispatch({ type: 'get-step' });
            const params = {
                culture: state.cultureCode,
                step: state.tab === Tab.YourSolutionPlus ? getYSPStep(state) : state.step,
            };
            const response = await ApiService.request({
                controller: state.tab === Tab.YourSolution ? 'ISM' : 'YSP',
                slug: 'step',
                method: 'GET',
                params,
            });
            // Check any additional data that needs to be loaded for this step //
            if (state.tab === Tab.YourSolutionPlus) {
                if (state.step === StepID.SoilsGroundConditions &&
                    state.soilGroundConditionsStep === SoilGroundConditionsStepID.SoilProfiles &&
                    state.soilProfiles.length === 0) {
                    const soilRequest = await ApiService.request({
                        controller: 'YSP',
                        slug: 'soilall',
                        method: 'GET',
                    });
                    if (soilRequest.status.success) {
                        dispatch({ type: 'set-soil-profiles', payload: soilRequest.result });
                    }
                }
                if (state.step === StepID.Surcharges &&
                    state.surchargeStep === SurchargeStepID.LargeSurcharge &&
                    state.surcharges.length === 0) {
                    // Fetch the surcharges //
                    if (state.surcharges.length === 0) {
                        const surchargeRequest = await ApiService.request({
                            controller: 'YSP',
                            slug: 'surcharges',
                            method: 'GET',
                        });
                        if (surchargeRequest.status.success) {
                            dispatch({
                                type: 'set-surcharges',
                                payload: surchargeRequest.result,
                            });
                        }
                    }
                }
            }
            dispatch({ type: 'get-step-success', payload: response });
        }
        setTimeout(() => {
            // Always re-fetch step data
            fetchStep();
        }, initialLoad ? 0 : 350);
        async function submit() {
            if (state.tab === Tab.YourSolution) {
                try {
                    dispatch({ type: 'submit' });
                    const params = {
                        ...state.form,
                        cultureCode: state.cultureCode,
                    };
                    const response = await ApiService.request({
                        controller: 'ISM',
                        slug: 'submit',
                        method: 'POST',
                        params,
                    });
                    dispatch({ type: 'submit-success', payload: response });
                }
                catch (e) {
                    dispatch({
                        type: 'submit-success',
                        payload: {
                            questionnaireID: -1,
                            firstReason: e.message,
                            responseMessage: e.message,
                            successful: false,
                            technicalMessage: e.message,
                            solutions: [],
                            emailAddress: '',
                            telNo: '',
                        },
                    });
                }
            }
            else {
                dispatch({ type: 'submit' });
                const params = state.globalStep === 1
                    ? dummyData
                    : {
                        ...state.form,
                        cultureCode: state.cultureCode,
                        fetchingNewSolutions: state.fetchingNewSolutions,
                        returnNoResults: false, // Flip to test the 'none of our boxes' screen on the results page //
                    };
                const response = await ApiService.request({
                    controller: 'YSP',
                    slug: 'getsolutions',
                    method: 'POST',
                    params,
                });
                if (response.status.success) {
                    // Wait a little bit just to show the animation, to not be jarring //
                    setTimeout(() => {
                        dispatch({ type: 'submit-ysp-success', payload: response.result });
                    }, 2500);
                }
            }
        }
        async function selectSolution() {
            dispatch({ type: 'submit' });
            const params = {
                ...state.form,
                cultureCode: state.cultureCode,
                fetchingNewSolutions: false,
            };
            const response = await ApiService.request({
                controller: 'YSP',
                slug: 'submit',
                method: 'POST',
                params,
            });
            if (response.status.success) {
                // Wait a little bit just to show the animation, to not be jarring //
                setTimeout(() => {
                    dispatch({ type: 'submit-solution-success', payload: response.result });
                }, 2500);
            }
        }
        // Submit our state data on the final step, this will automatically grab all of the solutions
        if (state.step === StepID.Solutions && state.formDirtyAfterSolutionsFetch) {
            submit();
        }
        // Used on YSP to submit the questionnaire with the selected solution to gain graph data etc. //
        if (state.step === StepID.SelectedSolution && state.hasChangedSelectedSolution) {
            selectSolution();
        }
    }, [
        state.step,
        state.tab,
        state.dimensionsStep,
        state.soilGroundConditionsStep,
        state.surchargeStep,
    ]);
    const getComponent = () => {
        // return <Loading />;
        if (state.loading) {
            return React.createElement(StepLoading, null);
        }
        switch (state.step) {
            default:
            case StepID.Landing:
                return React.createElement(Landing, null);
            case StepID.Details:
                return React.createElement(Details, null);
            case StepID.Location:
                return React.createElement(Location, null);
            case StepID.Depth:
                return React.createElement(Depth, null);
            case StepID.Dimensions:
                switch (state.dimensionsStep) {
                    default:
                    case DimensionsStepID.Size:
                        return React.createElement(Dimensions, null);
                    case DimensionsStepID.Type:
                        return React.createElement(Type, null);
                    case DimensionsStepID.EndSafePanels:
                        return React.createElement(EndSafePanels, null);
                    case DimensionsStepID.EndSafeSides:
                        return React.createElement(EndSafeSides, null);
                }
            case StepID.Support:
                switch (state.supportStep) {
                    default:
                    case SupportStepID.System: {
                        return React.createElement(System, null);
                    }
                    case SupportStepID.SheetedSolution: {
                        return React.createElement(SheetedSolution, null);
                    }
                }
            case StepID.Type:
                return React.createElement(Type, null);
            case StepID.Conditions:
                switch (state.conditionStep) {
                    default:
                    case ConditionStepID.ProblematicGround:
                        return React.createElement(ProblematicGround, null);
                    case ConditionStepID.SoilType:
                        return React.createElement(SoilTypes, null);
                    case ConditionStepID.GroundWater:
                        return React.createElement(Groundwater, null);
                    case ConditionStepID.GroundWaterDepth:
                        return React.createElement(GroundwaterDepth, null);
                }
            case StepID.Surcharges:
                switch (state.surchargeStep) {
                    default:
                    case SurchargeStepID.LargeSurcharge:
                        return React.createElement(LargeSurcharges, null);
                    case SurchargeStepID.SlopingGround:
                        return React.createElement(SlopingGround, null);
                    case SurchargeStepID.AdjacentWater:
                        return React.createElement(AdjacentWater, null);
                    case SurchargeStepID.ZoneOfInfluence:
                        return React.createElement(SurchargeDetails, null);
                }
            case StepID.Solutions: {
                if (state.loadingResults) {
                    return React.createElement(Loading, null);
                }
                if (state.tab === Tab.YourSolution) {
                    if (state.results !== undefined && state.results?.successful === false) {
                        return React.createElement(NoSolutions, null);
                    }
                    return React.createElement(Solutions, null);
                }
                else {
                    if (state.yspResults !== undefined && !state.yspResults?.success) {
                        return React.createElement("h1", null, "API needs implementing for this");
                    }
                    return React.createElement(YSPSolutions, null);
                }
            }
            case StepID.SelectedSolution: {
                if (state.loadingResults || !state.selectedSolution) {
                    return React.createElement(Loading, null);
                }
                return React.createElement(Summary, null);
            }
            // YourSolution+ //
            case StepID.TermsAndConditions:
                return React.createElement(TermsAndConditions, null);
            case StepID.SoilsGroundConditions:
                switch (state.soilGroundConditionsStep) {
                    default:
                    case SoilGroundConditionsStepID.SoilProfiles:
                        return React.createElement(SoilProfiles, null);
                    case SoilGroundConditionsStepID.SoilInformationSource:
                        return React.createElement(SoilSource, null);
                    case SoilGroundConditionsStepID.Groundwater:
                        return React.createElement(Groundwater, null);
                    case SoilGroundConditionsStepID.GroundwaterDepth:
                        return React.createElement(GroundwaterDepth, null);
                }
        }
    };
    return (React.createElement(AnimatePresence, { exitBeforeEnter: true },
        React.createElement("div", { style: { height: '100%' }, 
            // Need to have a unique key for every step to help Framer track new components - excuse the horrible long key but it's needed! //
            key: state.loading
                ? 'YourSolution_LoadingSpinner'
                : `YourSolution_Step_${state.step}_${state.conditionStep}_${state.supportStep}_${state.surchargeStep}_${state.dimensionsStep}_${state.soilGroundConditionsStep}_${state.loading}_${state.loadingResults}` }, getComponent())));
};
export default StepRouter;
