import brand from '@helpers/brand';
import styled from 'styled-components';
const SwapToYSP = styled.div `
  border-top: 1px solid ${brand.primary.light};
  position: relative;
  max-width: 720px;
  margin-top: 25px;
  padding: 25px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;
const OrLabel = styled.div `
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background-color: ${brand.white};
`;
const Buttons = styled.div `
  display: flex;
  gap: 15px;
  margin-top: 20px;
`;
export const NoSolutionsStyles = {
    SwapToYSP,
    OrLabel,
    Buttons,
};
