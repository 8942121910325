import React, { useEffect } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { ReactComponent as Watercourse } from '@img/ysp/icon-watercourse.svg';
import Radios from '@ys/Components/Steps/Controls/Radios/Radios';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
const AdjacentWater = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    useEffect(() => {
        //
        dispatch({
            type: 'set-footer',
            payload: {
                type: 'surcharges',
                beforeNext: () => dispatch({ type: 'validate-adjacent-water' }),
            },
        });
    }, []);
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            React.createElement("h2", null, state.labels['heading_adjacent']),
            state.labels['subheading_adjacent'] !== undefined &&
                state.labels['subheading_adjacent'].length > 0 && (React.createElement(SharedStyles.CenterText, null, state.labels['subheading_adjacent'])),
            React.createElement(SharedStyles.Icon, null,
                React.createElement(Watercourse, null)),
            React.createElement(FormStyles.FormRow, null,
                React.createElement(Radios, { error: state.errors['adjacentWater'], value: state.form.adjacentWater, setValue: (value) => setFormField('adjacentWater', value) })))));
};
export default AdjacentWater;
