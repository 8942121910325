import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import styled from 'styled-components';
import { DropdownStyles } from '../../Controls/Dropdown/Dropdown.styles';
import { SoilVisualiserStyles } from '../SoilVisualiser/SoilVisualiser.styles';
const Container = styled.div `
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: max-content;

  @media ${until(Device.TabletLarge)} {
    padding-bottom: 25px;
  }

  ${() => DropdownStyles.Container} {
    background-color: ${brand.ysp.input};
  }

  ${SoilVisualiserStyles.Container} {
    grid-column: 1 / -1;
    @media ${from(Device.TabletLarge)} {
      grid-column: 4 / 10;
    }
  }
`;
const Centered = styled.div `
  display: flex;
  flex-direction: column;

  > span {
    ${fonts.DaxPro.Medium};
    margin-bottom: 8px;
  }

  grid-column: 1 / -1;

  @media ${from(Device.Tablet)} {
    grid-column: 4 / span 6;
  }
`;
export const SoilSourceStyles = {
    Container,
    Centered,
};
