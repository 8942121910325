import brand from '@helpers/brand';
import { Device, from, until } from '@helpers/media';
import styled from 'styled-components';
const TechnicalLibraryLogin = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 15px;

  @media ${until(Device.TabletLarge)} {
    svg {
      margin-bottom: 15px;
    }
  }

  @media ${from(Device.TabletLarge)} {
    margin-bottom: 15px;
    border-bottom: 2px solid ${brand.grey.grey89};
    padding-bottom: 15px;
  }
`;
const FullWidth = styled.div `
  grid-column: 1 / -1;
`;
const Disclaimer = styled.div `
  p {
    margin-bottom: 0px;
  }

  @media ${until(Device.TabletLarge)} {
    p {
      font-size: 13px;
      line-height: 16px;
    }
  }

  @media ${from(Device.TabletLarge)} {
    p {
      line-height: 26px;
    }
  }
`;
export const DetailsStyles = {
    TechnicalLibraryLogin,
    FullWidth,
    Disclaimer,
};
