import React, { useEffect } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import InfoIcon from '@ys/Components/InfoIcon/InfoIcon';
import { SharedStyles } from '@ys/Components/Shared.styles';
import Radios from '@ys/Components/Steps/Controls/Radios/Radios';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import Embankments from '@img/ysp/surcharges/embankments.png';
import Building from '@img/ysp/surcharges/building.png';
import Crane from '@img/ysp/surcharges/crane.png';
import Railway from '@img/ysp/surcharges/railway.png';
import { Tab } from '@stories/Widgets/YourSolution/YS/Context/types';
const LargeSurcharges = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    const hasTooltip = state.labels['tooltip_surcharges'] !== undefined &&
        state.labels['tooltip_surcharges'].length > 0;
    useEffect(() => {
        //
        dispatch({
            type: 'set-footer',
            payload: {
                type: 'surcharges',
                beforeNext: () => dispatch({ type: 'validate-large-surcharges' }),
            },
        });
    }, []);
    const handleSetModal = () => {
        dispatch({
            type: 'set-modal',
            payload: {
                fullWidth: true,
                title: '',
                description: state.labels['tooltip_surcharges'],
                imageUrl: '/sitefiles/images/ism/zone-of-influence-new.png',
                size: 'normal',
            },
        });
    };
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            state.tab === Tab.YourSolutionPlus ? (React.createElement(SharedStyles.TitleWithInfoIcon, null,
                React.createElement("h2", null, state.labels['heading']),
                state.labels['tooltip'] && (React.createElement(InfoIcon, { onClick: () => dispatch({
                        type: 'set-modal',
                        payload: {
                            description: state.labels['tooltip'],
                            fullWidth: true,
                            size: 'normal',
                        },
                    }) })))) : (React.createElement(React.Fragment, null,
                React.createElement("h2", null, state.labels['heading']),
                hasTooltip && React.createElement(InfoIcon, { onClick: () => handleSetModal() }))),
            React.createElement(SharedStyles.Materials, null,
                React.createElement(SharedStyles.Material, null,
                    React.createElement(SharedStyles.MaterialMedia, { large: true },
                        React.createElement("img", { src: Embankments, alt: "Embankments, spoil heaps, stored material" })),
                    React.createElement(SharedStyles.MaterialLabel, null, "Embankments, spoil heaps, stored material")),
                React.createElement(SharedStyles.Material, null,
                    React.createElement(SharedStyles.MaterialMedia, { large: true },
                        React.createElement("img", { src: Building, alt: "Buildings or structures" })),
                    React.createElement(SharedStyles.MaterialLabel, null, "Buildings or structures")),
                React.createElement(SharedStyles.Material, null,
                    React.createElement(SharedStyles.MaterialMedia, { large: true },
                        React.createElement("img", { src: Crane, alt: "Plant exceeding 30t (Including cranes)" })),
                    React.createElement(SharedStyles.MaterialLabel, null, "Plant exceeding 30t (Including cranes)")),
                React.createElement(SharedStyles.Material, null,
                    React.createElement(SharedStyles.MaterialMedia, { large: true },
                        React.createElement("img", { src: Railway, alt: "Railways, motorways and trunk roads" })),
                    React.createElement(SharedStyles.MaterialLabel, null, "Railways, motorways and trunk roads"))),
            React.createElement(FormStyles.FormRow, null,
                React.createElement(Radios, { error: state.errors['largeSurcharges'], value: state.form.largeSurcharges, setValue: (value) => setFormField('largeSurcharges', value) })))));
};
export default LargeSurcharges;
