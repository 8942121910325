import React from 'react';
import { ModalStyles as S } from './Modal.styles';
import { ReactComponent as Close } from '@img/ysp/icon-cross.svg';
import { AnimatePresence } from 'framer-motion';
import { withMotion } from '@hoc/withMotion';
import YSButton from '../Shared/YSButton/YSButton';
const ConfirmModal = ({ text, noText, yesText, onNo, onYes, onClose, visible, }) => {
    return (React.createElement(AnimatePresence, null, visible && (React.createElement(S.BoxModal, { key: 'ConfirmModal', small: true, active: true },
        React.createElement(S.BoxModalInner, null,
            React.createElement(S.BoxModalClose, { onClick: () => onClose() },
                React.createElement(Close, null)),
            React.createElement(S.ConfirmText, null, text),
            React.createElement(S.Buttons, null,
                React.createElement(YSButton, { colour: "green", onClick: () => onYes() }, yesText),
                React.createElement(YSButton, { colour: "orange", onClick: () => onNo() }, noText)))))));
};
export default withMotion(ConfirmModal);
