import { ReactComponent as Close } from '@img/ysp/icon-cross.svg';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import React from 'react';
import EngineerCheck from './EngineerCheck/EngineerCheck';
import { ModalStyles as S } from './Modal.styles';
const Modal = ({ modalScrollable, modal }) => {
    const { dispatch } = useYSPContext();
    return (React.createElement(S.BoxModal, { small: modal.size === 'small', active: true },
        React.createElement(S.BoxModalInner, { fullWidth: modal.fullWidth, ref: modalScrollable },
            React.createElement(S.BoxModalClose, { onClick: () => dispatch({ type: 'set-modal', payload: undefined }) },
                React.createElement(Close, null)),
            modal.imageUrl && (React.createElement(S.BoxModalMedia, { fullWidth: modal.fullWidth },
                React.createElement("img", { src: modal.imageUrl, alt: modal.title, style: { objectFit: 'cover' } }))),
            modal.engineerCheckLabels ? (React.createElement(EngineerCheck, { labels: {
                    'engineer.modal.heading': modal.engineerCheckLabels.heading,
                    'engineer.modal.content': modal.engineerCheckLabels.content,
                    'engineer.modal.success': modal.engineerCheckLabels.success,
                } })) : (React.createElement(S.BoxModalCopy, null,
                modal.title && modal.title.length && React.createElement("h2", null, modal.title),
                modal.description && (React.createElement("div", { style: { display: 'contents' }, dangerouslySetInnerHTML: { __html: modal.description } })))))));
};
export default Modal;
