import React from 'react';
import { YSButtonStyles as S } from './YSButton.styles';
import { ReactComponent as Chevron } from '@img/icons/chevron-right.svg';
import { ReactComponent as Plus } from '@img/icons/plus-thick.svg';
import { ReactComponent as CheckCircle } from '@img/ysp/icon-check-circle-fill.svg';
import { ReactComponent as Phone } from '@img/ysp/icon-phone.svg';
import { ReactComponent as Email } from '@img/ysp/icon-mail.svg';
const YSButton = ({ children, icon, hideOnDesktop, hideOnMobile, colour = 'green', iconPosition = 'right', size = 'normal', fill = 'solid', center, ...rest }) => {
    const getIcon = () => {
        switch (icon) {
            case 'arrow':
                return React.createElement(Chevron, null);
            case 'plus':
                return React.createElement(Plus, null);
            case 'check':
                return React.createElement(CheckCircle, null);
            case 'email':
                return React.createElement(Email, null);
            case 'phone':
                return React.createElement(Phone, null);
            default:
                return React.createElement(React.Fragment, null);
        }
    };
    return (React.createElement(S.Container, { type: "button", "data-colour": colour, "data-icon": icon, "data-icon-position": iconPosition, "data-fill": fill, "data-size": size, "data-hide": hideOnDesktop ? 'desktop' : hideOnMobile ? 'mobile' : undefined, "data-center": center, ...rest },
        children,
        " ",
        icon && React.createElement(S.Icon, null, getIcon())));
};
export default YSButton;
export const YSLinkButton = ({ href, target, ...rest }) => {
    return (React.createElement(S.LinkWrapper, { href: href, target: target },
        React.createElement(YSButton, { ...rest })));
};
