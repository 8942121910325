import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import styled, { css } from 'styled-components';
const Container = styled.div `
  display: flex;
  gap: 25px;
  align-items: center;
`;
const Label = styled.div `
  font-size: 18px;
  ${fonts.DaxPro.Medium};
`;
const ToggleWrapper = styled.div `
  background-color: ${brand.grey.grey89};
  border-radius: 15px;
  width: 75px;
  height: 31px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  ${({ active }) => active &&
    css `
      background-color: ${brand.divisionTags.sandhurst};
    `}
`;
const ToggleBall = styled.div `
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: ${brand.white};
  position: absolute;
  top: 4px;
  transition: left 0.25s ease-in-out, background-color 0.2s ease-in-out;

  ${({ active }) => active
    ? css `
          left: calc(100% - 28px);
        `
    : css `
          left: 5px;
        `}
`;
export const ToggleStyles = {
    Container,
    Label,
    ToggleWrapper,
    ToggleBall,
};
