import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
import { WYSIWYGStyles } from '@ys/Components/Shared.styles';
import { Device, from } from '@helpers/media';
const MotionBase = styled(m.section).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
        },
    },
    transition: {
        duration: 0.35,
    },
})) ``;
// Unified interface to add a grid layout to the steps //
const Grid = styled(MotionBase) `
  width: 100%;
  margin: auto;
  height: 100%;

  max-width: 92vw;
  @media ${from(Device.TabletLarge)} {
    max-width: 75vw;
  }

  ${({ centered }) => centered &&
    css `
      place-items: center center;
    `}

  display: grid;
  grid-template-columns: repeat(12, 1fr);

  ${WYSIWYGStyles};
`;
const FullWidth = styled.div `
  grid-column: 1 / -1;

  @media ${from(Device.TabletLarge)} {
    padding-bottom: 35px;
  }
`;
const Default = styled.div `
  grid-column: 2 / 12;

  @media ${from(Device.TabletLarge)} {
    grid-column: 3 / 11;
    padding-bottom: 35px;
  }
`;
const Thin = styled.div `
  grid-column: 1 / -1;

  @media ${from(Device.TabletLarge)} {
    grid-column: 4 / 10;
    padding-bottom: 35px;
  }
`;
const Wide = styled.div `
  grid-column: 2 / 12;
  padding-bottom: 35px;
`;
export const StepStyles = {
    Grid,
};
export const Containers = {
    FullWidth,
    Default,
    Thin,
    Wide,
};
