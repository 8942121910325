import { ReactComponent as Edit } from '@img/ysp/edit.svg';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import React, { useMemo } from 'react';
import NumberInput from '../../../../Shared/NumberInput/NumberInput';
import Dropdown from '../../../Controls/Dropdown/Dropdown';
import { SurchargeType } from '../SurchargeDetails';
import { SurchargesTableStyles as S } from './SurchargesTable.styles';
const SurchargesTable = ({ surcharges, update }) => {
    const { state: { labels }, } = useYSPContext();
    const typeOptions = useMemo(() => {
        return Object.keys(SurchargeType)
            .filter((n) => isNaN(Number(n)))
            .map((t, i) => ({
            id: i,
            text: t,
        }));
    }, []);
    const isNull = (k, t) => {
        switch (t) {
            case SurchargeType.Blanket:
                switch (k) {
                    default:
                    case 'surchargeMagnitude':
                        return false;
                    case 'surchargeDepth':
                    case 'surchargeOffset':
                    case 'surchargeWidth':
                    case 'surchargeLength':
                    case 'surchargeMagnitudeB':
                        return true;
                }
            case SurchargeType.Strip:
                switch (k) {
                    default:
                    case 'surchargeMagnitude':
                    case 'surchargeDepth':
                    case 'surchargeOffset':
                    case 'surchargeWidth':
                        return false;
                    case 'surchargeLength':
                    case 'surchargeMagnitudeB':
                        return true;
                }
            case SurchargeType.Area:
                switch (k) {
                    default:
                    case 'surchargeMagnitude':
                    case 'surchargeDepth':
                    case 'surchargeOffset':
                    case 'surchargeWidth':
                    case 'surchargeLength':
                        return false;
                    case 'surchargeMagnitudeB':
                        return true;
                }
            case SurchargeType.Line:
            case SurchargeType.Point:
                switch (k) {
                    default:
                    case 'surchargeMagnitude':
                    case 'surchargeDepth':
                    case 'surchargeOffset':
                        return false;
                    case 'surchargeWidth':
                    case 'surchargeLength':
                    case 'surchargeMagnitudeB':
                        return true;
                }
            case SurchargeType.Trap:
            default:
                return false;
        }
    };
    return (React.createElement(S.Container, null,
        React.createElement(S.TopSection, null,
            React.createElement(S.Title, { dangerouslySetInnerHTML: { __html: labels['surchargeTable.heading'] || 'Surcharges' } }),
            React.createElement(S.DescriptionWrapper, null,
                React.createElement(Edit, null),
                React.createElement(S.Description, { dangerouslySetInnerHTML: {
                        __html: labels['surchargeProfile.prompt'] ||
                            `<p>Use the below table to fill in the types of Surcharges that are present in the zone of influence, including revelant dimensions and location relative to the dig</p>\r\n`,
                    } }))),
        React.createElement(S.TableContainer, null,
            React.createElement(S.WideColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.VariableHeader, { span: 4 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.surchargelabel'] || 'Surcharge',
                            } }))),
                surcharges.map((s, i) => (React.createElement(S.FieldRow, { key: `Surcharge_${s.id}_${i}` },
                    React.createElement(S.FieldInput, { align: 'left', type: "text", bg: i % 2 === 0 ? 'grey' : 'white', value: s.surchargeDescription, onChange: (e) => update(i, 'surchargeDescription', e.currentTarget.value) }))))),
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.VariableHeader, { span: 4 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.surchargetypelabel'] || 'Surcharge Type',
                            } }))),
                surcharges.map((s, i) => (React.createElement(S.FieldRow, { key: `Surcharge_${s.id}_${i}` },
                    React.createElement(S.DropdownWrapper, { bg: i % 2 === 0 ? 'grey' : 'white' },
                        React.createElement(Dropdown, { i: 6 - i, options: typeOptions, onChange: (v) => update(i, 'surchargeType', v), value: s.surchargeType ?? SurchargeType.Area })))))),
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.VariableHeader, { span: 2 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.magnitudelabel'] || 'Magnitude',
                            } })),
                    React.createElement(S.VariableHeader, { span: 1 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.magnitudeunits'] || '(kN/m<sup>2</sup>)',
                            } })),
                    React.createElement(S.VariableHeader, { lightBackground: true, span: 1 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.magnitudecalclabel'] || 'q<sub>sur</sub>',
                            } }))),
                surcharges.map((s, i) => (React.createElement(S.FieldRow, { key: `Surcharge_${s.id}_${i}` },
                    React.createElement(NumberInput, { layout: "surcharges-table", alignment: "center", min: 0, bg: i % 2 === 0 ? 'grey' : 'white', value: s.surchargeMagnitude ?? 0, onChange: (e) => update(i, 'surchargeMagnitude', e) }))))),
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.VariableHeader, { span: 2 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.depthlabel'] || 'Surcharge Depth',
                            } })),
                    React.createElement(S.VariableHeader, { span: 1 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.depthunits'] || '(m)',
                            } })),
                    React.createElement(S.VariableHeader, { lightBackground: true, span: 1 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.depthcalclabel'] || 'X',
                            } }))),
                surcharges.map((s, i) => (React.createElement(S.FieldRow, { isNull: isNull('surchargeDepth', s.surchargeType), key: `Surcharge_${s.id}_${i}` },
                    React.createElement(NumberInput, { layout: "surcharges-table", alignment: "center", min: 0, bg: i % 2 === 0 ? 'grey' : 'white', value: s.surchargeDepth ?? 0, onChange: (e) => update(i, 'surchargeDepth', e) }))))),
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.VariableHeader, { span: 2 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.offsetlabel'] || 'Surcharge Offset',
                            } })),
                    React.createElement(S.VariableHeader, { span: 1 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.offsetunits'] || '(m)',
                            } })),
                    React.createElement(S.VariableHeader, { lightBackground: true, span: 1 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.offsetcalclabel'] || 'A',
                            } }))),
                surcharges.map((s, i) => (React.createElement(S.FieldRow, { isNull: isNull('surchargeOffset', s.surchargeType), key: `Surcharge_${s.id}_${i}` },
                    React.createElement(NumberInput, { layout: "surcharges-table", alignment: "center", min: 0, bg: i % 2 === 0 ? 'grey' : 'white', value: s.surchargeOffset ?? 0, onChange: (e) => update(i, 'surchargeOffset', e) }))))),
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.VariableHeader, { span: 2 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.widthlabel'] || 'Surcharge Width',
                            } })),
                    React.createElement(S.VariableHeader, { span: 1 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.widthunits'] || '(m)',
                            } })),
                    React.createElement(S.VariableHeader, { lightBackground: true, span: 1 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.widthcalclabel'] || 'B',
                            } }))),
                surcharges.map((s, i) => (React.createElement(S.FieldRow, { isNull: isNull('surchargeWidth', s.surchargeType), key: `Surcharge_${s.id}_${i}` },
                    React.createElement(NumberInput, { layout: "surcharges-table", alignment: "center", min: 0, bg: i % 2 === 0 ? 'grey' : 'white', value: s.surchargeWidth ?? 0, onChange: (e) => update(i, 'surchargeWidth', e) }))))),
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.VariableHeader, { span: 2 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.lengthlabel'] || 'Surcharge Length',
                            } })),
                    React.createElement(S.VariableHeader, { span: 1 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.lengthunits'] || '(m)',
                            } })),
                    React.createElement(S.VariableHeader, { lightBackground: true, span: 1 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.lengthcalclabel'] || 'L<sub>sur</sub>',
                            } }))),
                surcharges.map((s, i) => (React.createElement(S.FieldRow, { isNull: isNull('surchargeLength', s.surchargeType), key: `Surcharge_${s.id}_${i}` },
                    React.createElement(NumberInput, { layout: "surcharges-table", alignment: "center", min: 0, bg: i % 2 === 0 ? 'grey' : 'white', value: s.surchargeLength ?? 0, onChange: (e) => update(i, 'surchargeLength', e) }))))),
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.VariableHeader, { span: 2 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.magnitude2label'] || 'Surcharge Magnitude 2',
                            } })),
                    React.createElement(S.VariableHeader, { span: 1 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.magnitude2units'] || '(kN/m<sup>2</sup>)',
                            } })),
                    React.createElement(S.VariableHeader, { lightBackground: true, span: 1 },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.surchage.table.magnitude2calclabel'] || 'q<sub>2,sur</sub>',
                            } }))),
                surcharges.map((s, i) => (React.createElement(S.FieldRow, { isNull: isNull('surchargeMagnitudeB', s.surchargeType), key: `Surcharge_${s.id}_${i}` },
                    React.createElement(NumberInput, { layout: "surcharges-table", alignment: "center", min: 0, bg: i % 2 === 0 ? 'grey' : 'white', value: s.surchargeMagnitudeB ?? 0, onChange: (e) => update(i, 'surchargeMagnitudeB', e) }))))))));
};
export default SurchargesTable;
