import { Device, from } from '@helpers/media';
import styled, { css } from 'styled-components';
const Container = styled.div `
  ${({ singleQuestion }) => !singleQuestion &&
    css `
      display: flex;
      flex-direction: column;
      max-width: 900px;
      width: 100%;
      justify-content: center;

      @media ${from(Device.Tablet)} {
        flex-direction: row;
        flex-wrap: wrap;
        width: auto;
      }
    `};
`;
export const BoxesStyles = {
    Container,
};
