import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import styled from 'styled-components';
const Container = styled.div `
  border-radius: 5px;
  border: 2px solid ${brand.grey.grey35};
  display: grid;
  grid-template-columns: 1fr 25px;
  overflow: hidden;
`;
const Input = styled.input `
  background-color: ${brand.white};
  transition: background-color 0.2s ease-in-out;
  width: 100%;
  && {
    border: none;
  }
  padding: 5px 7px;
  text-align: center;

  &:disabled {
    background-color: ${brand.grey.grey55};
    opacity: 0.7;
    cursor: help;
  }
`;
const EndSection = styled.div `
  background-color: ${brand.grey.grey89};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 2px solid ${brand.grey.grey55};
  font-size: 15px;
  ${fonts.DaxPro.Regular};
  user-select: none;
`;
export const InlineNumericalInputStyles = {
    Container,
    EndSection,
    Input,
};
