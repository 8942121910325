import React, { useEffect, useState } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { ReactComponent as User } from '@img/ysp/icon-avatar.svg';
import Textbox from '@ys/Components/Steps/Controls/Textbox/Textbox';
import Recaptcha from '@stories/Components/Forms/Recaptcha/Recaptcha';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import { DetailsStyles as S } from './Details.styles';
import { Containers, StepStyles } from '@ys/Components/Shared/Step.styles';
import { YSLinkButton } from '../../Shared/YSButton/YSButton';
import InfoIcon from '../../InfoIcon/InfoIcon';
const Details = () => {
    const { state, dispatch, setFormField: setField } = useYSPContext();
    const [nextActive, setNextActive] = useState(true);
    const [recaptcha, setRecaptcha] = useState('');
    useEffect(() => {
        //
        dispatch({
            type: 'set-footer',
            payload: {
                beforeNext: handleNext,
            },
        });
    }, []);
    useEffect(() => {
        dispatch({
            type: 'set-footer',
            payload: {
                nextDisabled: nextActive,
            },
        });
    }, [nextActive]);
    const checkContinue = () => {
        if (state.authenticatedUser === undefined) {
            if (state.form.name != undefined &&
                state.form.name.length > 0 &&
                state.form.email != undefined &&
                state.form.email.length > 0 &&
                state.form.confirmEmail != undefined &&
                state.form.confirmEmail.length > 0 &&
                state.form.telephone != undefined &&
                state.form.telephone.length > 0 &&
                state.form.companyName != undefined &&
                state.form.companyName.length > 0 &&
                state.form.schemeName != undefined &&
                state.form.schemeName.length > 0 &&
                state.form.siteName != undefined &&
                state.form.siteName.length > 0 &&
                state.form.recaptchaToken != undefined &&
                state.form.recaptchaToken.length > 0) {
                setNextActive(false);
            }
        }
        else {
            if (state.form.name != undefined &&
                state.form.name.length > 0 &&
                state.form.email != undefined &&
                state.form.email.length > 0 &&
                state.form.telephone != undefined &&
                state.form.telephone.length > 0 &&
                state.form.companyName != undefined &&
                state.form.companyName.length > 0 &&
                state.form.schemeName != undefined &&
                state.form.schemeName.length > 0 &&
                state.form.siteName != undefined &&
                state.form.siteName.length > 0 &&
                state.form.recaptchaToken != undefined &&
                state.form.recaptchaToken.length > 0) {
                setNextActive(false);
            }
        }
    };
    const handleRecaptcha = (token) => {
        setField('recaptchaToken', token);
        if (token) {
            setRecaptcha(token);
        }
        checkContinue();
    };
    const handleNext = () => {
        dispatch({ type: 'validate-details' });
    };
    return (React.createElement(StepStyles.Grid, null,
        React.createElement(Containers.Default, null,
            React.createElement(SharedStyles.TitleWithInfoIcon, null,
                React.createElement("h2", null, state.labels['heading']),
                state.labels['tooltip'] && (React.createElement(InfoIcon, { onClick: () => dispatch({
                        type: 'set-modal',
                        payload: {
                            description: state.labels['tooltip'],
                            fullWidth: true,
                            size: 'normal',
                        },
                    }) }))),
            React.createElement(FormStyles.FormSplit, null,
                state.authenticatedUser === undefined && (React.createElement(S.FullWidth, null,
                    React.createElement(S.TechnicalLibraryLogin, null,
                        React.createElement(SharedStyles.Avatar, null,
                            React.createElement(User, null)),
                        React.createElement(YSLinkButton, { href: `${state.technicalLibraryUrl}?ReturnUrl=${encodeURI(state.yourSolutionUrl)}` }, "Log in to Technical Library")))),
                React.createElement(FormStyles.FormRow, null,
                    React.createElement(Textbox, { value: state.form.name, label: "Name*", error: state.labels[state.errors['name'] || ''], onChange: (e) => {
                            setField('name', e.currentTarget.value);
                            checkContinue();
                        } })),
                React.createElement(FormStyles.FormRow, null,
                    React.createElement(Textbox, { value: state.form.companyName, label: "Company Name*", error: state.labels[state.errors['companyName'] || ''], onChange: (e) => {
                            setField('companyName', e.currentTarget.value);
                            checkContinue();
                        } })),
                React.createElement(FormStyles.FormRow, null,
                    React.createElement(Textbox, { value: state.form.email, label: "Email Address*", error: state.labels[state.errors['email'] || ''], onChange: (e) => {
                            setField('email', e.currentTarget.value);
                            checkContinue();
                        } })),
                state.authenticatedUser === undefined && (React.createElement(FormStyles.FormRow, null,
                    React.createElement(Textbox, { value: state.form.confirmEmail, label: "Confirm Email Address*", error: state.labels[state.errors['confirmEmail'] || ''], onChange: (e) => {
                            setField('confirmEmail', e.currentTarget.value);
                            checkContinue();
                        } }))),
                React.createElement(FormStyles.FormRow, null,
                    React.createElement(Textbox, { value: state.form.schemeName, label: "Scheme Name*", error: state.labels[state.errors['schemeName'] || ''], onChange: (e) => {
                            setField('schemeName', e.currentTarget.value);
                            checkContinue();
                        } })),
                React.createElement(FormStyles.FormRow, null,
                    React.createElement(Textbox, { value: state.form.siteName, label: "Site Name*", error: state.labels[state.errors['siteName'] || ''], onChange: (e) => {
                            setField('siteName', e.currentTarget.value);
                            checkContinue();
                        } })),
                React.createElement(FormStyles.FormRow, null,
                    React.createElement(Textbox, { value: state.form.telephone, label: "Telephone Number*", error: state.labels[state.errors['telephone'] || ''], onChange: (e) => {
                            setField('telephone', e.currentTarget.value);
                            checkContinue();
                        } })),
                React.createElement(S.FullWidth, null,
                    React.createElement(FormStyles.FormRow, { style: { marginTop: 15 } },
                        state.errors['recaptchaToken'] && (React.createElement(FormStyles.FormError, null, state.labels[state.errors['recaptchaToken']])),
                        React.createElement("div", { className: `${state.errors['recaptchaToken'] ? 'invalid' : ''}` },
                            React.createElement(Recaptcha, { shouldLoad: true, siteKey: '6Ld_N5wUAAAAANALiCT8yvJYZ8NRnNKut0MEvxdW', value: recaptcha, onChange: handleRecaptcha })))),
                React.createElement(S.FullWidth, null, state.labels['disclaimer'] !== undefined && state.labels['disclaimer'].length > 0 && (React.createElement(FormStyles.FormRow, { wide: true },
                    React.createElement(FormStyles.FormControl, null,
                        React.createElement(S.Disclaimer, { dangerouslySetInnerHTML: { __html: state.labels['disclaimer'] } })))))))));
};
export default Details;
