import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, until } from '@helpers/media';
import { lighten } from 'polished';
import styled, { css } from 'styled-components';
import { DropdownStyles } from '../../../Controls/Dropdown/Dropdown.styles';
import { NumberInputStyles } from '../../../../Shared/NumberInput/NumberInput.styles';
const Container = styled.div `
  margin-top: 25px;

  @media ${until(Device.TabletLarge)} {
    padding-bottom: 25px;
  }
`;
const TopSection = styled.div `
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
`;
const Title = styled.div `
  ${fonts.DaxPro.Bold};
  font-size: 23px;
`;
const DescriptionWrapper = styled.div `
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  gap: 10px;

  p {
    margin-bottom: 0;
  }

  svg {
    width: 100%;
  }
`;
const Description = styled.div ``;
const TableContainer = styled.div `
  display: grid;
  grid-template-columns: repeat(10, 1fr); // Surcharge spans 3 //

  @media ${until(Device.TabletLarge)} {
    padding-bottom: 25px;
  }

  @media ${until(Device.TabletLarge)} {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: ${brand.grey.grey89};
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: ${brand.primary.light};
    }
  }
`;
const ColumnWrapper = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-column: span 1;
`;
const WideColumnWrapper = styled(ColumnWrapper) `
  grid-column: span 3;
  font-size: 18px;

  @media ${until(Device.TabletLarge)} {
    min-width: 200px;
  }
`;
const HeaderWrapper = styled.div `
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  height: 100%;
  min-height: 107px;
`;
const Header = styled.div `
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${brand.white};
  background-color: ${brand.primary.alt};
  font-size: 14px;
  color: ${brand.white};
  text-align: center;
  ${fonts.DaxPro.Medium};

  ${({ lightBackground }) => lightBackground &&
    css `
      background-color: ${brand.ysp.input};
      color: ${brand.black};
    `};

  sub {
    font-size: 11px;
    position: relative;
    bottom: -3px;
  }
`;
const HeaderInner = styled.div ``;
const VariableHeader = styled(Header) `
  grid-row: span ${({ span }) => span};
`;
const FieldRow = styled.div `
  width: 100%;
  border: 1px solid ${brand.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  min-height: 30px;
  height: 100%;

  ${({ isNull }) => isNull &&
    css `
      cursor: not-allowed;
      user-select: none;
      pointer-events: none;
      background-color: ${lighten(0.2, brand.grey.grey55)};
      position: relative;

      ${() => NumberInputStyles.Input} {
        display: none;
      }

      &::after {
        content: 'NULL';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `};
`;
const FieldBase = css `
  padding: 6px 10px;
  border: none;
  width: 100%;
  height: 100%;
  position: relative;
  transition: outline-color 0.1s ease-in-out;
  outline: 2px solid transparent;
  ${fonts.DaxPro.Medium};

  &::selection {
    color: ${brand.white};
    background-color: ${brand.divisionTags.sandhurst};
  }

  &:focus {
    outline-color: ${brand.divisionTags.sandhurst};
  }
`;
const DropdownWrapper = styled.div `
  ${FieldBase}
  padding: unset;
  height: 100%;

  ${() => DropdownStyles.Container} {
    padding: unset;
    border: none;
    border-radius: none;
    background-color: unset;

    ${() => DropdownStyles.TopSection} {
      padding: unset;
    }

    ${() => DropdownStyles.SelectedOption} {
      padding: 0px 6px;
      display: flex;
      ${fonts.DaxPro.Medium};
    }
  }

  ${({ bg }) => {
    switch (bg) {
        default:
        case 'green':
            return css `
          background-color: ${brand.ysp.input};
        `;
        case 'white':
            return css `
          background-color: ${brand.white};
        `;
        case 'grey':
            return css `
          background-color: ${brand.grey.grey89};
        `;
    }
}}
`;
const FieldInput = styled.input `
  ${FieldBase}

  ${({ align }) => {
    switch (align) {
        default:
        case 'left':
            return css `
          text-align: start;
        `;
        case 'center':
            return css `
          text-align: center;
        `;
        case 'right':
            return css `
          text-align: end;
        `;
    }
}}
  
  ${({ bg }) => {
    switch (bg) {
        default:
        case 'green':
            return css `
          background-color: ${brand.ysp.input};
        `;
        case 'white':
            return css `
          background-color: ${brand.white};
        `;
        case 'grey':
            return css `
          background-color: ${brand.grey.grey89};
        `;
    }
}}
`;
export const SurchargesTableStyles = {
    Container,
    TopSection,
    Title,
    DescriptionWrapper,
    Description,
    TableContainer,
    ColumnWrapper,
    WideColumnWrapper,
    HeaderWrapper,
    Header,
    HeaderInner,
    VariableHeader,
    FieldRow,
    FieldInput,
    DropdownWrapper,
};
