import { withMotionMax as withMotion } from '@hoc/withMotionMax';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { Containers, StepStyles } from '@ys/Components/Shared/Step.styles';
import YSButton from '@ys/Components/Shared/YSButton/YSButton';
import Dropdown from '@ys/Components/Steps/Controls/Dropdown/Dropdown';
import { MAX_SIZE } from '@ys/Components/Steps/Dimensions/Dimensions';
import { DimensionsStyles } from '@ys/Components/Steps/Dimensions/Dimensions.styles';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import { SurchargeDetailsStyles as S } from './SurchargeDetails.styles';
import SurchargesTable from './SurchargesTable/SurchargesTable';
import Building from '@img/ysp/surcharges/building.png';
import Crane from '@img/ysp/surcharges/crane.png';
import Embankments from '@img/ysp/surcharges/embankments.png';
import Railway from '@img/ysp/surcharges/railway.png';
import Road from '@img/ysp/surcharges/road.svg';
import { ReactComponent as Cross } from '@img/ysp/icon-cross.svg';
import { ReactComponent as BuildingAnimated } from '@img/ysp/surcharges/building.svg';
import { ReactComponent as CraneAnimated } from '@img/ysp/surcharges/crane.svg';
import { ReactComponent as MaterialAnimated } from '@img/ysp/surcharges/material.svg';
import InfoIcon from '../../../InfoIcon/InfoIcon';
import { SharedStyles } from '../../../Shared.styles';
export var SurchargeIcon;
(function (SurchargeIcon) {
    SurchargeIcon[SurchargeIcon["None"] = 0] = "None";
    SurchargeIcon[SurchargeIcon["Building"] = 1] = "Building";
    SurchargeIcon[SurchargeIcon["Plant"] = 2] = "Plant";
    SurchargeIcon[SurchargeIcon["Slope"] = 3] = "Slope";
    SurchargeIcon[SurchargeIcon["Rail"] = 4] = "Rail";
    SurchargeIcon[SurchargeIcon["Road"] = 5] = "Road";
})(SurchargeIcon || (SurchargeIcon = {}));
export var SurchargeSource;
(function (SurchargeSource) {
    SurchargeSource[SurchargeSource["General"] = 0] = "General";
    SurchargeSource[SurchargeSource["FixedPlant"] = 1] = "FixedPlant";
    SurchargeSource[SurchargeSource["MovingPlant"] = 2] = "MovingPlant";
    SurchargeSource[SurchargeSource["Road"] = 3] = "Road";
    SurchargeSource[SurchargeSource["Rail"] = 4] = "Rail";
    SurchargeSource[SurchargeSource["SmallBuilding"] = 5] = "SmallBuilding";
    SurchargeSource[SurchargeSource["LargeBuilding"] = 6] = "LargeBuilding";
    SurchargeSource[SurchargeSource["Slope"] = 7] = "Slope";
    SurchargeSource[SurchargeSource["Other"] = 8] = "Other";
})(SurchargeSource || (SurchargeSource = {}));
export var SurchargeType;
(function (SurchargeType) {
    SurchargeType[SurchargeType["Blanket"] = 0] = "Blanket";
    SurchargeType[SurchargeType["Strip"] = 1] = "Strip";
    SurchargeType[SurchargeType["Area"] = 2] = "Area";
    SurchargeType[SurchargeType["Trap"] = 3] = "Trap";
    SurchargeType[SurchargeType["Line"] = 4] = "Line";
    SurchargeType[SurchargeType["Point"] = 5] = "Point";
})(SurchargeType || (SurchargeType = {}));
const getIcon = (icon) => {
    switch (icon) {
        default:
        case SurchargeIcon.None:
            return '';
        case SurchargeIcon.Building:
            return Building;
        case SurchargeIcon.Plant:
            return Crane;
        case SurchargeIcon.Rail:
            return Railway;
        case SurchargeIcon.Slope:
            return Embankments;
        case SurchargeIcon.Road:
            return Road;
    }
};
const Surcharge = ({ i, value, surcharges, onChange, removeSurcharge, dropdownPlaceholder, ...rest }) => {
    const iconSrc = useMemo(() => getIcon(value.surchargeIcon), [value.surchargeIcon]);
    return (React.createElement(S.SurchargeWrapper, { ...rest },
        React.createElement(S.IconContainer, null,
            React.createElement(AnimatePresence, null,
                React.createElement(S.MotionWrapper, { icon: value.surchargeIcon, key: value.surchargeIcon },
                    value.surchargeIcon === SurchargeIcon.Plant && React.createElement(CraneAnimated, null),
                    value.surchargeIcon === SurchargeIcon.Building && React.createElement(BuildingAnimated, null),
                    value.surchargeIcon === SurchargeIcon.Slope && React.createElement(MaterialAnimated, null),
                    (value.surchargeIcon === SurchargeIcon.None ||
                        value.surchargeIcon === SurchargeIcon.Road ||
                        value.surchargeIcon === SurchargeIcon.Rail) && (React.createElement(React.Fragment, null, iconSrc && React.createElement("img", { src: iconSrc })))))),
        React.createElement(S.SurchargeName, null,
            "Surcharge ",
            i + 1),
        React.createElement(Dropdown, { i: 6 - i + 10, onChange: (id) => onChange(id), value: value.id, options: surcharges, placeholder: dropdownPlaceholder }),
        i > 0 && (React.createElement(S.DeleteWrapper, { onClick: () => confirm('Are you sure you want to delete this surcharge?') && removeSurcharge(i) },
            React.createElement(Cross, null)))));
};
const SurchargeDetails = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    const determinePreselectedSurcharges = () => {
        const preselectedSurcharges = state.surcharges.filter((x) => x.surchargePreselected);
        if (preselectedSurcharges.length > 0 && !state.hasAddedDefaultSurcharge) {
            // Some are preselected, return those //
            dispatch({ type: 'set-has-added-default-surcharge', payload: true });
            return preselectedSurcharges;
        }
        if (state.form.surchargeProfiles) {
            return state.form.surchargeProfiles;
        }
        return [
            {
                id: -1,
                surchargeDescription: 'Please select a surcharge...',
                surchargeIcon: SurchargeIcon.None,
            },
        ];
    };
    const [surcharges, setSurcharges] = useState(determinePreselectedSurcharges());
    useEffect(() => {
        dispatch({
            type: 'set-footer',
            payload: {
                nextDisabled: surcharges.length === 0 ||
                    surcharges.some((x) => x.id === -1) ||
                    surcharges.some((x) => !Number(x.surchargeMagnitude)),
            },
        });
    }, [surcharges, surcharges.map((x) => x.surchargeMagnitude).toString()]);
    useEffect(() => {
        dispatch({
            type: 'set-footer',
            payload: {
                nextDisabled: surcharges.length === 0 ||
                    surcharges.some((x) => x.id === -1) ||
                    surcharges.some((x) => !Number(x.surchargeMagnitude)),
            },
        });
    }, [surcharges, surcharges.map((x) => x.surchargeMagnitude).toString()]);
    useEffect(() => {
        setFormField('surchargeProfiles', surcharges);
    }, [surcharges]);
    const addSurcharge = () => {
        const copy = [...surcharges];
        copy.push({
            id: -1,
            surchargeDescription: 'Please select a surcharge...',
            surchargeIcon: SurchargeIcon.None,
        });
        setSurcharges(copy);
    };
    const [actualHeight, actualWidth] = useMemo(() => {
        const ratio = state.form.width / state.form.length;
        const actualHeight = ratio > 1 ? (1 / ratio) * MAX_SIZE : MAX_SIZE;
        const actualWidth = ratio < 1 ? ratio * MAX_SIZE : MAX_SIZE;
        return [actualHeight, actualWidth];
    }, []);
    const surchargeOptions = useMemo(() => {
        if (state.surcharges.length === 0) {
            return [];
        }
        return state.surcharges.map((s) => ({
            id: s.id,
            text: s.surchargeDescription,
        }));
    }, [state.surcharges]);
    if (state.surcharges.length === 0) {
        return React.createElement(React.Fragment, null);
    }
    const modifyLayer = (position, type, value) => {
        const surcharge = surcharges[position];
        if (surcharge) {
            // Moans about the 'id' not being the right type, but it's being omitted via Lodash //
            let clone = { ...surcharge };
            clone[type] = value;
            if (type === 'surchargeType') {
                const retypedClone = clone;
                // Depending on the type we need to reset some values back to undefined //
                switch (value) {
                    default:
                    case SurchargeType.Trap:
                        break;
                    case SurchargeType.Blanket:
                        retypedClone.surchargeDepth = undefined;
                        retypedClone.surchargeOffset = undefined;
                        retypedClone.surchargeWidth = undefined;
                        retypedClone.surchargeLength = undefined;
                        retypedClone.surchargeMagnitudeB = undefined;
                        break;
                    case SurchargeType.Strip:
                        retypedClone.surchargeLength = undefined;
                        retypedClone.surchargeMagnitudeB = undefined;
                        break;
                    case SurchargeType.Area:
                        retypedClone.surchargeMagnitudeB = undefined;
                        break;
                    case SurchargeType.Line:
                    case SurchargeType.Point:
                        retypedClone.surchargeWidth = undefined;
                        retypedClone.surchargeLength = undefined;
                        retypedClone.surchargeMagnitudeB = undefined;
                        break;
                }
                const matchingSurchargeType = state.surcharges.find((x) => x.surchargeType === value);
                if (matchingSurchargeType && matchingSurchargeType?.surchargeMagnitude) {
                    // If a default magnitude has been set, add it here //
                    retypedClone.surchargeMagnitude = matchingSurchargeType.surchargeMagnitude;
                }
                clone = retypedClone;
            }
            const surchargesClone = [...surcharges];
            surchargesClone.splice(position, 1, clone);
            setSurcharges(surchargesClone);
        }
    };
    const removeSurcharge = (p) => {
        const clone = [...surcharges];
        clone.splice(p, 1);
        setSurcharges(clone);
    };
    return (React.createElement(StepStyles.Grid, null,
        React.createElement(Containers.Default, null,
            React.createElement(SharedStyles.TitleWithInfoIcon, null,
                React.createElement("h2", null, state.labels['surchargeProfile.heading']),
                state.labels['surchargeProfile.tooltip'] && (React.createElement(InfoIcon, { onClick: () => {
                        dispatch({
                            type: 'set-modal',
                            payload: {
                                description: state.labels['surchargeProfile.tooltip'],
                                fullWidth: true,
                                size: 'normal',
                            },
                        });
                    } }))),
            React.createElement(S.Container, null,
                React.createElement(S.TopStep, null,
                    React.createElement(S.DimensionsWrapper, null,
                        React.createElement(DimensionsStyles.Outer, { style: { width: `${actualHeight}px`, height: `${actualWidth}px` } },
                            React.createElement(DimensionsStyles.Inner, null,
                                React.createElement(DimensionsStyles.Arrow, { direction: "width", style: { height: `${actualWidth}px` } }, !isNaN(state.form.width) && (React.createElement("span", null,
                                    state.form.width.toFixed(2),
                                    "m"))),
                                React.createElement(DimensionsStyles.Arrow, { direction: "length", style: { width: `${actualHeight}px` } }, !isNaN(state.form.length) && (React.createElement("span", null,
                                    state.form.length.toFixed(2),
                                    "m")))))),
                    React.createElement(S.SurchargesWrapper, null,
                        surcharges.map((s, i) => (React.createElement(Surcharge, { "data-last": i === surcharges.length - 1, key: `Surcharges_${i}_${surchargeOptions.length}`, i: i, surcharges: surchargeOptions, onChange: (id) => {
                                const copy = [...surcharges];
                                const valIndex = state.surcharges.findIndex((x) => x.id === id);
                                copy.splice(i, 1, state.surcharges[valIndex]);
                                setSurcharges(copy);
                            }, removeSurcharge: removeSurcharge, dropdownPlaceholder: state.labels['ysp.surchage.table.please-select-surcharge'], value: s }))),
                        React.createElement(S.ButtonWrapper, null,
                            React.createElement(YSButton, { icon: "plus", disabled: surcharges.length === 6, onClick: () => addSurcharge() }, "Add surcharge")))),
                React.createElement(SurchargesTable, { surcharges: surcharges.filter((x) => x.id !== -1), update: modifyLayer })))));
};
export default withMotion(SurchargeDetails);
