import { imageUrl } from '@helpers/cloudinary';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { AnimatePresence } from 'framer-motion';
import React, { useMemo, useRef } from 'react';
import { SoilVisualiserStyles as S } from './SoilVisualiser.styles';
const SoilVisualiser = () => {
    const { state } = useYSPContext();
    const containerRef = useRef(null);
    const excavationRef = useRef(null);
    const soilLayersToDisplay = useMemo(() => {
        if (state.form.soilLayers) {
            const layers = state.form.soilLayers;
            return layers.filter((layer, i) => {
                // We always want to show the top layer //
                if (i === 0) {
                    return true;
                }
                // If a new layer has just been added it will have the same BGL as the previous layer. //
                // Ignore this layer if it matches the previous one //
                if (i > 0 && layers[i - 1].bgl === layer.bgl) {
                    return false;
                }
                return true;
            });
        }
        return [];
    }, [state.form.soilLayers]);
    const excavationHeight = useMemo(() => {
        if (soilLayersToDisplay.length < 1 || !state.form.depth) {
            return 90;
        }
        const lastPopulatedLayer = soilLayersToDisplay[soilLayersToDisplay.length - 1].bgl;
        if (lastPopulatedLayer >= state.form.depth) {
            const percentage = (state.form.depth / lastPopulatedLayer) * 100;
            return percentage - 10;
        }
        return 90;
    }, [soilLayersToDisplay]);
    const soilProfileOptions = useMemo(() => state.soilProfiles.reduce((acc, cur) => {
        acc.push({
            id: cur.id,
            icon: imageUrl(cur.image, `c_fill,w_30,h_30`),
            name: cur.description,
        });
        return acc;
    }, []), [state.soilProfiles]);
    if (state.soilProfiles.length === 0) {
        return React.createElement(React.Fragment, null);
    }
    const getHeightForLayer = (l, i) => {
        if (containerRef.current && excavationRef.current && state.form.depth) {
            if (i < soilLayersToDisplay.length - 1) {
                const height = soilLayersToDisplay[i + 1].bgl - l;
                // What % of the excavation is this //
                const layerPercentage = height / state.form.depth;
                const excavationBoundingBox = excavationRef.current.getBoundingClientRect();
                const layerHeight = excavationBoundingBox.height * layerPercentage;
                return layerHeight;
            }
        }
        return -1;
    };
    return (React.createElement(S.Container, { ref: containerRef },
        React.createElement(S.SoilVisualiser, null,
            React.createElement(S.ExcavationOverlay, { ref: excavationRef, height: excavationHeight },
                React.createElement(S.Arrow, null),
                React.createElement("span", null,
                    state.form.depth,
                    "m")),
            React.createElement(AnimatePresence, null, soilLayersToDisplay
                .filter((x) => x.soilTypeId >= 0)
                .map((layer, i) => (React.createElement(S.SoilLayer, { isLast: i === soilLayersToDisplay.length - 1, key: `SoilLayer_${layer.id}`, background: soilProfileOptions.find((x) => x.id === layer.soilTypeId)?.icon || '', height: soilLayersToDisplay.length === 1
                    ? containerRef.current?.clientHeight
                    : getHeightForLayer(layer.bgl, i) })))))));
};
export default SoilVisualiser;
