import brand from '@helpers/brand';
import { Device, from, until } from '@helpers/media';
import BackgroundImageSVG from '@img/ysp/ysp-bg.svg';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
import { StepID } from './Context/types';
const Container = styled.section `
  --ysp-steps-height: 15px;
  --ysp-footer-height: 60px;
  --ysp-footer-height-mobile: 50px;

  font-size: 16px;

  ${({ step }) => {
    switch (step) {
        default:
            return css `
          --ysp-header-height: 170px;
          --ysp-header-close: 0px;
        `;
        case StepID.TermsAndConditions:
            return css `
          --ysp-header-height: 115px;
          --ysp-header-close: 0px;
        `;
        case StepID.Landing:
            return css `
          --ysp-header-height: 115px;
          --ysp-header-close: 55px;
        `;
    }
}}

  @media ${from(Device.TabletLarge)} {
    --ysp-header-height: 170px;
    --ysp-header-close: 0px;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${brand.white};
  z-index: 8002;
`;
const Header = styled.div `
  min-height: calc(var(--ysp-header-height) + var(--ysp-header-close));
  max-height: calc(var(--ysp-header-height) + var(--ysp-header-close));
`;
const MainHeaderContentWrapper = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
        },
    },
    transition: {
        duration: 0.5,
    },
})) `
  height: var(--ysp-header-height);
  display: flex;
  flex-direction: column;
  gap: 15px;

  img {
    user-select: none;
    margin: 0px auto;
    max-width: 225px;

    @media ${from(Device.TabletLarge)} {
      max-width: 350px;
    }
  }
`;
const ModalInner = styled(m.div) `
  height: calc(
    100dvh - var(--ysp-header-height) - var(--ysp-header-close) - var(--ysp-footer-height-mobile)
  );
  @media ${from(Device.TabletLarge)} {
    height: calc(100dvh - var(--ysp-header-height) - var(--ysp-header-close));
  }

  padding: 10px;
  position: relative;

  @media ${from(Device.Tablet)} {
    padding: 20px;
  }
`;
const ModalHeader = styled.div `
  position: relative;
  padding: 0 30px 0 0;
  display: flex;
  flex-direction: column;

  @media ${from(Device.Tablet)} {
    padding: 0 30px;
  }

  img {
    width: 100%;
    max-width: 307px;
    margin: 0 auto 0 0;
    display: block;
    flex: 0 0 auto;

    @media ${from(Device.Tablet)} {
      margin: 0 auto;
    }
  }
`;
const Content = styled.div `
  height: calc(
    100dvh - var(--ysp-footer-height-mobile) - var(--ysp-header-height) - var(--ysp-header-close)
  );

  @media ${from(Device.TabletLarge)} {
    height: calc(
      100dvh - var(--ysp-footer-height) - var(--ysp-header-height) - var(--ysp-header-close) - 40px
    );
    padding: 20px 0px;
  }
  overflow-y: auto;
  z-index: ${({ soilModalOpen }) => (soilModalOpen ? 200 : 101)};
  position: relative;

  ${({ locked }) => locked &&
    css `
      overflow-y: hidden;
    `}

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${brand.white};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${brand.primary.light};
    border-radius: 10px;
  }

  [data-ys-root='true'] & {
    &::after {
      content: '';
      position: fixed;
      bottom: var(--ysp-footer-height);
      left: 0;
      width: 100%;
      height: 25px;
      background: rgb(255, 255, 255);
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 1;

      @media ${from(Device.TabletLarge)} {
        height: 40px;
        bottom: var(--ysp-footer-height-mobile);
      }
    }

    &::before {
      content: '';
      position: fixed;
      top: calc(var(--ysp-header-height) + var(--ysp-header-close) - 15px);
      left: 0;
      height: 25px;
      width: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 2;

      @media ${from(Device.TabletLarge)} {
        height: 40px;
      }
    }
  }
`;
const BackgroundImage = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            y: 50,
            x: 50,
            opacity: 0,
        },
        visible: {
            y: 0,
            x: 0,
            opacity: 1,
        },
    },
    transition: {
        duration: 1,
    },
})) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImageSVG});
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
`;
const MobileHeader = styled.div `
  display: flex;
  justify-content: space-between;
  max-height: 97px;
  position: relative;

  @media ${until(Device.TabletLarge)} {
    padding: 0px 15px;
  }

  svg:first-of-type {
    margin: 0;
  }

  @media ${from(Device.TabletLarge)} {
    justify-content: center;
  }
`;
const Close = styled.div `
  width: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 100%;
  }

  @media ${from(Device.TabletLarge)} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }
`;
const Image = styled.img `
  height: 100%;
  object-fit: contain;
`;
export const YourSolutionStyles = {
    Container,
    ModalInner,
    ModalHeader,
    Content,
    BackgroundImage,
    Header,
    MainHeaderContentWrapper,
    MobileHeader,
    Close,
    Image,
};
