import React from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { BackToMainSiteStyles as S } from './BackToMainSite.styles';
import { ReactComponent as Chevron } from '@img/icons/chevron-right.svg';
import { isSSR } from '@helpers/ssr';
const BackToMainSite = ({ closeModal }) => {
    const { state } = useYSPContext();
    if (!state.labels) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(S.Container, null,
        React.createElement(S.Content, null,
            React.createElement(S.Return, { onClick: () => (!isSSR() && closeModal ? closeModal() : window.history.back()) },
                React.createElement(S.Arrow, null,
                    React.createElement(Chevron, null)),
                state.labels['return-to-main-site'] || 'Return to Main Site'))));
};
export default BackToMainSite;
