import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import { m } from 'framer-motion';
import styled from 'styled-components';
const Container = styled.div `
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 102;
  display: none;

  @media ${from(Device.TabletLarge)} {
    display: block;
    bottom: 50px;
    left: 100px;
  }
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
`;
const Return = styled(m.div).attrs(() => ({
    whileHover: 'hover',
})) `
  display: flex;
  align-items: center;
  color: ${brand.primary.alt};
  gap: 10px;
  ${fonts.DaxPro.Bold};
  font-size: 18px;
  cursor: pointer;

  svg {
    width: 10px;
    height: 100%;
    display: block;
    transform: rotate(180deg);
  }
`;
const Arrow = styled(m.div).attrs(() => ({
    variants: {
        hover: {
            x: -10,
        },
    },
    transition: {
        duration: 0.5,
    },
})) ``;
export const BackToMainSiteStyles = {
    Container,
    Content,
    Return,
    Arrow,
};
