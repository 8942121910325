import styled, { css } from 'styled-components';
import Grass from '@img/ysp/icon-grass.svg';
import { Device, from } from '@helpers/media';
import brand from '@helpers/brand';
const Container = styled.div `
  background-image: url(${Grass});
  background-repeat: no-repeat;
  background-size: cover;
  width: 300px;
  height: 230px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @media ${from(Device.Tablet)} {
    height: 250px;
    padding-bottom: 0px;
  }
`;
const Outer = styled.div `
  background-color: #fdc135;
  border: 1px solid black;
  width: 100%;
  height: 100%;
  padding: 5px;
  position: relative;
  margin-right: 20px;
  transition: width 300ms ease, height 300ms ease;
`;
const Inner = styled.div `
  background-color: #c7c7c7;
  border: 1px solid black;
  width: 100%;
  height: 100%;
`;
const Arrow = styled.div `
  position: absolute;
  background-color: ${brand.grey.grey20};

  span {
    position: relative;
    color: ${brand.black};
  }

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${brand.grey.grey20};
    position: absolute;
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${brand.grey.grey20};
    position: absolute;
  }

  ${({ direction }) => direction === 'width'
    ? css `
          top: 50%;
          right: -15px;
          transform: translateY(-50%);
          width: 1px;
          transition: height 375ms ease;

          &:before {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) rotate(180deg);
          }

          &:after {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }

          span {
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
          }
        `
    : css `
          bottom: -15px;
          left: 50%;
          transform: translateX(-50%);
          height: 1px;
          transition: width 375ms ease;

          &:before {
            right: -2px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
          }

          &:after {
            left: -2px;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
          }

          span {
            left: 50%;
            transform: translateX(-50%);
            top: 10px;
          }
        `}
`;
export const DimensionsStyles = {
    Container,
    Outer,
    Inner,
    Arrow,
};
