import { domAnimation, LazyMotion } from 'framer-motion';
import React from 'react';
export function withMotion(WrappedComponent) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    const ComponentWithMotion = (props) => {
        return (React.createElement(LazyMotion, { features: domAnimation, strict: true },
            React.createElement(WrappedComponent, { ...props })));
    };
    ComponentWithMotion.displayName = `withMotion(${displayName})`;
    return ComponentWithMotion;
}
