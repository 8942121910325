import { withMotionMax } from '@hoc/withMotionMax';
import { AnimateSharedLayout } from 'framer-motion';
import React from 'react';
import { RadiosStyles as S } from './Radios.styles';
const defaultOptions = [
    {
        label: 'Yes',
        value: true,
    },
    {
        label: 'No',
        value: false,
    },
];
const Radios = ({ error, value, options = defaultOptions, setValue, }) => {
    return (React.createElement(AnimateSharedLayout, null,
        React.createElement(S.Container, null, options.map((x) => {
            return (React.createElement(S.Item, { key: x.label, active: value !== undefined && x.value === value, invalid: error !== undefined, onClick: () => setValue(x.value) },
                React.createElement(S.Value, null),
                React.createElement(S.Label, null, x.label),
                value !== undefined && x.value === value && (React.createElement(S.BottomEffect, { layoutId: "BottomEffect" }))));
        }))));
};
export default withMotionMax(Radios);
