import brand from '@helpers/brand';
import { Device, from } from '@helpers/media';
import styled from 'styled-components';
import Point from '@img/ysp/icon-point.svg';
const Container = styled.div `
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 45px auto;
`;
const Label = styled.div `
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translateX(-50%);
  color: ${brand.primary.light};
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  padding: 7px 0px;
  top: -3px;
  user-select: none;

  @media ${from(Device.Tablet)} {
    top: 3px;
  }

  svg {
    display: block;
    fill: ${brand.primary.light};
    width: 7px;
    height: 16px;
    margin-right: 4px;
  }
`;
const Indicator = styled.div `
  width: 1px;
  background-color: ${brand.primary.light};
  margin-right: 4px;
  position: relative;
  height: 100%;

  &:before,
  &:after {
    content: '';
    width: 7px;
    height: 7px;
    background-image: url(${Point});
    position: absolute;
  }

  &:before {
    content: '';
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }

  &:after {
    content: '';
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;
export const DepthStyles = {
    Container,
    Label,
    Indicator,
};
