import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
import { InfoIconStyles } from './InfoIcon/InfoIcon.styles';
const Box = styled.figure `
  flex: 0 0 auto;
  padding: 8px 8px 8px 40px;
  position: relative;

  @media ${from(Device.Tablet)} {
    padding: 8px 8px 60px 8px;
    width: 33%;
  }

  ${({ invalid }) => invalid &&
    css `
      ${() => BoxInner} {
        border: 1px solid ${brand.red.base};
      }
    `}

  ${({ active }) => active &&
    css `
      ${() => BoxInner} {
        border: 1px solid ${brand.primary.light};
        border-bottom: 7px solid ${brand.primary.light};
      }

      ${() => BoxState}:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background-color: ${brand.primary.light};

        @media ${from(Device.Tablet)} {
          width: 20px;
          height: 20px;
        }
      }
    `}
`;
const BoxInner = styled.div `
  border: 1px solid #d5d5d5;
  padding: 20px 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;

  @media ${from(Device.Tablet)} {
    flex-direction: column;
    padding: 12px;
  }
`;
const BoxMedia = styled.div `
  position: relative;
  flex: 0 0 auto;
  max-width: 40%;

  @media ${from(Device.Tablet)} {
    margin-bottom: 16px;
    max-width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover';
  }
`;
const BoxLabel = styled.label `
  text-align: center;
  font-weight: normal;
  font-size: 17px;
  display: block;
  flex: 1 1 auto;

  @media ${from(Device.Tablet)} {
    flex: 1 0 auto;
    margin-bottom: 50px;
  }
`;
const BoxState = styled.div `
  width: 24px;
  height: 24px;
  border: 1px solid ${brand.grey.grey20};
  border-radius: 50%;
  position: relative;
  flex: 0 0 auto;

  @media ${from(Device.Tablet)} {
    margin: 0 auto 20px auto;
    width: 30px;
    height: 30px;
  }
`;
const ButtonStyles = css `
  outline: none;
  border: none;
  padding: 8px 28px 8px 28px;
  background-color: ${brand.primary.light};
  display: inline-block;
  font-size: 20px;
  color: ${brand.white};
  ${fonts.DaxPro.Regular};
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.25s ease-in-out;
  z-index: 10;

  &:hover {
    color: ${brand.white};
    background-color: ${brand.primary.light};
  }

  @media ${from(Device.TabletLarge)} {
    font-size: 22px;
  }

  &:disabled {
    @media ${until(Device.TabletLarge)} {
      opacity: 0.4;
    }
    @media ${from(Device.TabletLarge)} {
      background-color: ${brand.grey.grey40};
    }
    cursor: not-allowed;
  }
`;
const Button = styled.button.attrs(() => ({
    type: 'button',
})) `
  ${ButtonStyles}

  ${({ restart }) => restart &&
    css `
      padding: 8px 50px 8px 28px;

      svg {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 19px;
        height: 19px;
        fill: ${brand.white};
        stroke: ${brand.white};
      }
    `};

  ${({ prev }) => prev &&
    css `
      padding: 8px 28px 8px 50px;

      svg {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        width: 25px;
        height: 20px;
      }
    `}

  ${({ next }) => next &&
    css `
      padding: 8px 50px 8px 28px;

      svg {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        height: 20px;
      }
    `}
`;
export const WYSIWYGStyles = css `
  // Need to do h3 because thats what HTML comes out of api..
  h2,
  h3 {
    font-size: 25px;
    text-align: center;
    color: ${brand.grey.grey20};
    ${fonts.DaxPro.Bold};
    margin: 0 0 20px 0;
    line-height: 1.1;
    margin-bottom: 20px;
  }

  ol,
  ul {
    list-style: inside;
  }

  a {
    color: ${brand.primary.light};
    text-decoration: underline;
  }

  strong {
    ${fonts.DaxPro.Bold};
  }

  p {
    font-size: 17px;
    margin-bottom: 0px;
    color: #333333;
  }
`;
const Question = styled(m.section).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
        },
    },
    transition: {
        duration: 0.35,
    },
})) `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;

  @media ${from(Device.TabletLarge)} {
    margin-right: 15px;
  }

  ${WYSIWYGStyles};
`;
const Avatar = styled.div `
  svg {
    fill: ${brand.primary.light};
    width: 60px;
    height: 60px;
    display: block;
    align-self: center;

    @media ${from(Device.TabletLarge)} {
      width: 45px;
      height: 45px;
    }
  }
`;
const BoxInfo = styled.div `
  position: absolute;
  left: 15px;
  bottom: 50%;
  transform: translate(-50%, 50%);

  @media ${from(Device.Tablet)} {
    left: 50%;
    bottom: 30px;
  }
`;
const Materials = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media ${from(Device.TabletLarge)} {
    border-top: 1px solid ${brand.grey.grey44};
    padding-top: 25px;
    margin-top: 20px;
  }

  ${({ desktop }) => desktop &&
    css `
      @media ${until(Device.Tablet)} {
        display: none;
      }
    `}

  ${({ mobile }) => mobile &&
    css `
      display: flex;
      @media ${from(Device.Tablet)} {
        display: none;
      }
    `}


  @media ${from(Device.Tablet)} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
const MaterialContainer = styled.div `
  ${() => Material} {
    width: 100%;
  }
`;
const Material = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 12px;

  @media ${from(Device.Tablet)} {
    width: 45%;
  }
`;
const MaterialMedia = styled.div `
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 17px;
  flex: 0 0 32px;

  ${({ large }) => large &&
    css `
      width: 80px;
      height: auto;
      flex: 0 0 auto;
    `}
`;
const MaterialLabel = styled.div `
  font-size: 15px;
`;
const MaterialTitle = styled.div `
  font-size: 15px;
  color: ${brand.black};
  font-weight: 700;
  padding-bottom: 10px;
`;
const Icon = styled.div `
  width: 300px;
  margin-bottom: 25px;

  svg {
    width: 100%;
  }
`;
const CenterText = styled.p `
  text-align: center;
`;
const TitleWithInfoIcon = styled.div `
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
  align-items: center;

  @media ${from(Device.TabletLarge)} {
    margin-bottom: 25px;
    ${() => InfoIconStyles.Container} {
      margin-bottom: 15px;
    }
  }

  h2 {
    margin-bottom: 0 !important;
  }
`;
export const SharedStyles = {
    Box,
    BoxInner,
    BoxMedia,
    BoxLabel,
    BoxState,
    Button,
    Question,
    Avatar,
    BoxInfo,
    Materials,
    Material,
    MaterialMedia,
    MaterialLabel,
    MaterialContainer,
    MaterialTitle,
    Icon,
    CenterText,
    TitleWithInfoIcon,
};
