import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import styled, { css } from 'styled-components';
import { Containers, StepStyles } from '../../Shared/Step.styles';
import { YSButtonStyles } from './../../Shared/YSButton/YSButton.styles';
import { InlineNumericalInputStyles } from './InlineNumericalInput/InlineNumericalInput.styles';
import { SoilTypeDropdownStyles } from './SoilTypeDropdown/SoilTypeDropdown.styles';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  @media ${until(Device.TabletLarge)} {
    padding-bottom: 25px;
  }
`;
const TopStep = styled.div `
  display: grid;
  gap: 20px;

  @media ${from(Device.TabletLarge)} {
    grid-template-columns: repeat(12, 1fr);
  }
`;
const LayerOptions = styled.div `
  grid-column: 6 / -1;
  display: flex;
  flex-direction: column;
`;
const LayerWrapper = styled.div `
  display: grid;
  grid-template-columns: 55px 110px 1fr 13px;
  gap: 15px;
  align-items: center;
  margin-bottom: 15px;

  &[data-last='true'] {
    ${SoilTypeDropdownStyles.Container}, ${InlineNumericalInputStyles.Input}:not(:disabled) {
      background-color: ${brand.ysp.input};
    }
  }
`;
const LayerName = styled.div `
  ${fonts.DaxPro.Medium};
  font-size: 17px;
`;
const ButtonWrapper = styled.div `
  padding-top: 25px;
  margin-top: auto;
  display: flex;
  gap: 16px;
  flex-direction: column;

  @media ${from(Device.TabletLarge)} {
    align-items: center;
    flex-direction: row;
  }

  ${YSButtonStyles.Container} {
    white-space: nowrap;
  }
`;
const DeleteWrapper = styled.div `
  cursor: pointer;

  @media ${until(Device.TabletLarge)} {
    grid-column: 4;
    grid-row: 1;
  }
`;
const ResetAllWrapper = styled.div `
  display: flex;
  gap: 7px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: max-content;

  span {
    font-size: 17px;
    ${fonts.DaxPro.Medium};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
const GridWrapper = styled(StepStyles.Grid) `
  @media ${until(Device.TabletLarge)} {
    ${() => Containers.Default} {
      grid-column: 1 / -1;
    }
  }

  @media ${from(Device.TabletLarge)} {
    ${({ offsetPadding }) => offsetPadding &&
    css `
        padding-right: 8px;
        max-width: calc(75vw + 8px);
      `}
  }
`;
export const SoilProfilesStyles = {
    Container,
    TopStep,
    LayerOptions,
    LayerWrapper,
    LayerName,
    ButtonWrapper,
    DeleteWrapper,
    ResetAllWrapper,
    GridWrapper,
};
