import React, { useEffect } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { GroundwaterDepthStyles as S } from './GroundwaterDepth.styles';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import InlineNumericalInput from '../../SoilProfiles/InlineNumericalInput/InlineNumericalInput';
import { Tab } from '@stories/Widgets/YourSolution/YS/Context/types';
import InfoIcon from '@ys/Components/InfoIcon/InfoIcon';
import { ReactComponent as Ground } from '@img/ysp/groundwater-ground.svg';
import { ReactComponent as Water } from '@img/ysp/groundwater-water.svg';
import { withMotion } from '@hoc/withMotion';
const GroundwaterDepth = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    useEffect(() => {
        //
        dispatch({
            type: 'set-footer',
            payload: {
                type: 'conditions',
                beforeNext: () => dispatch({ type: 'validate-groundwater-depth' }),
            },
        });
    }, []);
    const handleDepthChange = (value) => {
        let floatValue = parseFloat(value);
        if (floatValue > 99999) {
            floatValue = 99999;
        }
        if (value === '' || floatValue < 0) {
            setFormField('groundwaterDepth', undefined);
        }
        else {
            setFormField('groundwaterDepth', floatValue);
        }
    };
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            state.tab === Tab.YourSolutionPlus ? (React.createElement(SharedStyles.TitleWithInfoIcon, null,
                React.createElement("h2", null, state.labels['groundwaterDepth.heading']),
                state.labels['groundwaterDepth.tooltip'] && (React.createElement(InfoIcon, { onClick: () => dispatch({
                        type: 'set-modal',
                        payload: {
                            description: state.labels['groundwaterDepth.tooltip'],
                            fullWidth: true,
                            size: 'normal',
                        },
                    }) })))) : (React.createElement("h2", null, state.labels['heading_groundwaterDepth'])),
            React.createElement(S.Container, null,
                React.createElement(S.Ground, null,
                    React.createElement(Ground, null)),
                React.createElement(S.Water, { height: Math.min(state.form.groundwaterDepth || 0, 6.7) },
                    React.createElement(Water, null)),
                React.createElement(S.ExcavationOverlay, { height: state.form.depth || 1.5 },
                    React.createElement(S.Arrow, null),
                    React.createElement("span", null,
                        state.form.depth,
                        "m"))),
            React.createElement(FormStyles.FormControl, { center: true, invalid: state.errors['groundwaterDepth'] !== undefined },
                React.createElement("label", { htmlFor: "txtDepth" }, "Depth"),
                React.createElement(FormStyles.FormError, null, "Please enter a depth."),
                React.createElement(InlineNumericalInput, { unit: "m", onChange: (v) => handleDepthChange(v.toString()), value: state.form.groundwaterDepth === undefined ? 0 : state.form.groundwaterDepth, min: 0, absoluteBounds: true })))));
};
export default withMotion(GroundwaterDepth);
