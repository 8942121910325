import React, { useEffect } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { ReactComponent as Map } from '@img/icons/map.svg';
import Textbox from '@ys/Components/Steps/Controls/Textbox/Textbox';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import InfoIcon from '../../InfoIcon/InfoIcon';
const Location = () => {
    const { state, dispatch, setFormField: setField } = useYSPContext();
    useEffect(() => {
        dispatch({
            type: 'set-footer',
            payload: {
                beforeNext: handleNext,
            },
        });
    }, []);
    const handleNext = () => {
        dispatch({ type: 'validate-postcode' });
    };
    const setPostCode = (value) => {
        setField('postCode', value);
    };
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            React.createElement(SharedStyles.TitleWithInfoIcon, null,
                React.createElement("h2", null, state.labels['heading']),
                state.labels['tooltip'] && (React.createElement(InfoIcon, { onClick: () => dispatch({
                        type: 'set-modal',
                        payload: {
                            description: state.labels['tooltip'],
                            fullWidth: true,
                            size: 'normal',
                        },
                    }) }))),
            React.createElement(SharedStyles.Avatar, null,
                React.createElement(Map, null)),
            React.createElement(Textbox, { restrictWidth: true, label: "Postcode", value: state.form.postCode, error: state.labels[state.errors['postCode'] || ''], onChange: (e) => setPostCode(e.currentTarget.value) }))));
};
export default Location;
