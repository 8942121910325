import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
const Container = styled.div `
  border-radius: 5px;
  border: 2px solid ${brand.grey.grey35};
  background-color: ${brand.white};
  position: relative;
  height: 100%;
  padding: 3px 0px;
  transition: background-color 0.2s ease-in-out;

  ${({ short }) => short &&
    css `
      max-width: 350px;
    `}

  ${({ i }) => i !== undefined
    ? css `
          z-index: ${i};
        `
    : css `
          z-index: 11;
        `}
`;
const TopSection = styled.div `
  ${fonts.DaxPro.Regular};
  display: grid;
  grid-template-columns: 1fr 25px;
  cursor: pointer;
  padding: 6px 10px;
  height: 100%;
  user-select: none;

  span {
    margin: auto 0;
  }

  svg {
    margin: auto;
    color: ${brand.primary.alt};
    width: 15px;
  }
`;
const DropdownWrapper = styled(m.div).attrs(({ active }) => ({
    initial: 'hidden',
    animate: active ? 'visible' : 'hidden',
    variants: {
        hidden: {
            height: 0,
            opacity: 0,
            transition: {
                opacity: {
                    duration: 0.1,
                    delay: 0.2,
                },
            },
        },
        visible: {
            height: 'auto',
            opacity: 1,
            transition: {
                opacity: {
                    duration: 0.1,
                },
            },
        },
    },
})) `
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  background-color: ${brand.white};
`;
const BorderWrapper = styled.div `
  overflow-y: auto;
  height: 100%;
  border: 2px solid ${brand.grey.grey35};
  border-top: none;
  max-height: 450px;

  margin-left: -2px;
  margin-top: -3px;
  width: calc(100% + 4px);

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${brand.primary.light};
    border-radius: 10px;
  }
`;
const Dropdown = styled.div `
  display: flex;
  flex-direction: column;
  padding-top: 6px;
`;
const DropdownOption = styled.div `
  align-items: center;
  padding: 8px 10px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  span {
    svg {
      width: 15px;
      margin-right: 8px;
    }
  }

  &:hover {
    background-color: ${brand.grey.grey89};
  }
`;
const SelectedOption = styled.div `
  align-items: center;
`;
export const DropdownStyles = {
    Container,
    TopSection,
    DropdownWrapper,
    BorderWrapper,
    Dropdown,
    DropdownOption,
    SelectedOption,
};
