import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
const Container = styled.div `
  width: min(400px, 90vw);
  position: relative;
  margin-bottom: 35px;
  overflow: hidden;

  svg {
    display: block;
    width: 100%;
  }
`;
const Ground = styled.div ``;
const Water = styled(m.div).attrs(({ hasAnimatedIn }) => ({
    initial: 'hidden',
    animate: hasAnimatedIn ? 'ripple' : 'visible',
    variants: {
        hidden: {
            y: '100%',
        },
        visible: {
            y: ['100%', '75%', '45%', '25%', '15%', '10%', '5%', '0%'],
        },
        ripple: {
            y: ['0%', '5%', '2%', '3%', '4%', '2%', '-1%', '-2%', '1%', '0%'],
            transition: {
                duration: 10,
                repeat: Infinity,
                type: 'tween',
            },
        },
    },
    transition: {
        duration: 4,
        type: 'tween',
    },
})) `
  position: absolute;
  left: 0;

  ${({ height }) => height * 20 > 100
    ? css `
          top: calc(100% - 50px);
        `
    : css `
          top: calc(${height * 20}px - 15px);
        `}

  width: 100%;
`;
const ExcavationOverlay = styled.div `
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 75px;
  ${({ height }) => height !== undefined &&
    css `
      height: min(${height * 20}px, 100px);
    `};
  background-color: ${brand.white};
  border: 2px solid ${brand.black};
  border-top: none;
  padding: 5px 7px;
  z-index: 1;

  display: flex;
  align-items: center;
  gap: 5px;

  span {
    color: ${brand.primary.alt};
    ${fonts.DaxPro.Medium};
    font-size: 13px;
  }
`;
const Arrow = styled.div `
  position: relative;
  height: calc(100% - 5px);
  width: 1px;
  background-color: ${brand.primary.alt};

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 35%;
    transform: translateX(-50%);
    background-color: ${brand.primary.alt};
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    width: 7px;
    height: 7px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 35%;
    transform: translateX(-50%);
    background-color: ${brand.primary.alt};
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    width: 7px;
    height: 7px;
  }
`;
export const GroundwaterStyles = {
    Container,
    Ground,
    Water,
    ExcavationOverlay,
    Arrow,
};
