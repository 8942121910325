import React, { useEffect } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SoilType } from '@ys/Context/types';
import Radios from '@ys/Components/Steps/Controls/Radios/Radios';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import Road from '@img/ysp/materials/road.png';
import DenseSand from '@img/ysp/materials/dense-sand.png';
import Rock from '@img/ysp/materials/rock.png';
import LooseGravel from '@img/ysp/materials/loose-gravel.png';
import CohesiveFill from '@img/ysp/materials/cohesive-fill.png';
import StiffClay from '@img/ysp/materials/stiff-clay.png';
const SoilTypes = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    useEffect(() => {
        //
        dispatch({
            type: 'set-footer',
            payload: {
                type: 'conditions',
                beforeNext: () => dispatch({ type: 'validate-soil-type' }),
            },
        });
    }, []);
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            React.createElement("h2", null, state.labels['heading_predominant']),
            React.createElement(FormStyles.FormRow, { xCentered: true },
                React.createElement(Radios, { value: state.form.soilType, error: state.errors['soilType'], setValue: (value) => setFormField('soilType', value), options: [
                        {
                            label: 'Granular',
                            value: SoilType.Granular,
                        },
                        {
                            label: 'Cohesive',
                            value: SoilType.Cohesive,
                        },
                    ] })),
            React.createElement(SharedStyles.Materials, { desktop: true },
                React.createElement(SharedStyles.MaterialContainer, null,
                    React.createElement(SharedStyles.Material, null,
                        React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${Road}')` } }),
                        React.createElement(SharedStyles.MaterialLabel, null, "Well Compacted MADE GROUND/ ROAD CONSTRUCTION")),
                    React.createElement(SharedStyles.Material, null,
                        React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${DenseSand}')` } }),
                        React.createElement(SharedStyles.MaterialLabel, null, "Medium Dense, Dense or Very Dense SAND")),
                    React.createElement(SharedStyles.Material, null,
                        React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${Rock}')` } }),
                        React.createElement(SharedStyles.MaterialLabel, null, "ROCK")),
                    React.createElement(SharedStyles.Material, null,
                        React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${LooseGravel}')` } }),
                        React.createElement(SharedStyles.MaterialLabel, null, "Loose, Medium Dense, Dense and Very Dense GRAVEL"))),
                React.createElement(SharedStyles.MaterialContainer, null,
                    React.createElement(SharedStyles.Material, null,
                        React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${CohesiveFill}')` } }),
                        React.createElement(SharedStyles.MaterialLabel, null, "Cohesive Fill")),
                    React.createElement(SharedStyles.Material, null,
                        React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${StiffClay}')` } }),
                        React.createElement(SharedStyles.MaterialLabel, null, "Soft-Firm, Firm, Stiff or Very Stiff CLAY")))),
            React.createElement(SharedStyles.Materials, { mobile: true },
                React.createElement(SharedStyles.MaterialContainer, null,
                    React.createElement(SharedStyles.MaterialTitle, null, "Granular Soils:"),
                    React.createElement(SharedStyles.Material, null,
                        React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${Road}')` } }),
                        React.createElement(SharedStyles.MaterialLabel, null, "Well Compacted MADE GROUND/ ROAD CONSTRUCTION")),
                    React.createElement(SharedStyles.Material, null,
                        React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${DenseSand}')` } }),
                        React.createElement(SharedStyles.MaterialLabel, null, "Medium Dense, Dense or Very Dense SAND")),
                    React.createElement(SharedStyles.Material, null,
                        React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${Rock}')` } }),
                        React.createElement(SharedStyles.MaterialLabel, null, "ROCK")),
                    React.createElement(SharedStyles.Material, null,
                        React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${LooseGravel}')` } }),
                        React.createElement(SharedStyles.MaterialLabel, null, "Loose, Medium Dense, Dense and Very Dense GRAVEL"))),
                React.createElement(SharedStyles.MaterialContainer, null,
                    React.createElement(SharedStyles.MaterialTitle, null, "Cohesive Soils:"),
                    React.createElement(SharedStyles.Material, null,
                        React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${CohesiveFill}')` } }),
                        React.createElement(SharedStyles.MaterialLabel, null, "Cohesive Fill")),
                    React.createElement(SharedStyles.Material, null,
                        React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${StiffClay}')` } }),
                        React.createElement(SharedStyles.MaterialLabel, null, "Soft-Firm, Firm, Stiff or Very Stiff CLAY")))))));
};
export default SoilTypes;
