import React, { useEffect } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SupportType } from '@stories/Widgets/YourSolution/YS/Context/types';
import Boxes from '@ys/Components/Steps/Controls/Boxes/Boxes';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { FormStyles } from '../../../Shared/Form.styles';
const System = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    useEffect(() => {
        //
        dispatch({
            type: 'set-footer',
            payload: {
                type: 'support',
                beforeNext: () => dispatch({ type: 'validate-support' }),
            },
        });
    }, []);
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            React.createElement("h2", null, state.labels['heading']),
            React.createElement(Boxes, { error: state.errors['supportType'], boxes: [
                    {
                        id: SupportType.Box,
                        title: 'Boxes',
                        description: state.labels['tooltip_box'],
                        imageUrl: '/sitefiles/images/ism/products/MB-BH-1-Backhoe(BaseOnly).png',
                    },
                    {
                        id: SupportType.Frame,
                        title: 'Sheets & Frames',
                        description: state.labels['tooltip_frame'],
                        imageUrl: '/sitefiles/images/ism/products/B-2F-2Frame.png',
                    },
                ], value: state.form.supportType, setValue: (value) => setFormField('supportType', value, ['type', 'subType']) }))));
};
export default System;
