import React from 'react';
import { CheckboxStyles as S } from './Checkbox.styles';
import { ReactComponent as Check } from '@img/ysp/icon-check.svg';
import { AnimatePresence } from 'framer-motion';
import { withMotion } from '@hoc/withMotion';
const Checkbox = ({ label, value, onChange, disabled = false, }) => {
    return (React.createElement(S.Container, { onClick: () => onChange(), disabled: disabled },
        React.createElement(S.Box, null,
            React.createElement(AnimatePresence, null, value && (React.createElement(S.Check, null,
                React.createElement(Check, null))))),
        label));
};
export default withMotion(Checkbox);
