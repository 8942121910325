import brand from '@helpers/brand';
import { m } from 'framer-motion';
import styled from 'styled-components';
const Container = styled.div ``;
const InfoIcon = styled(m.div).attrs(() => ({
    whileTap: 'tap',
    whileHover: 'hover',
    variants: {
        tap: {
            scale: 0.95,
        },
        hover: {
            scale: 1.05,
        },
    },
    transition: {
        duration: 0.25,
    },
})) `
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: ${brand.grey.grey35};

  svg {
    width: 100%;
    height: 100%;
  }
`;
export const InfoIconStyles = {
    Container,
    InfoIcon,
};
