import brand from '@helpers/brand';
import { Device, from } from '@helpers/media';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
import { WYSIWYGStyles } from '../Shared.styles';
import { fonts } from '@helpers/fonts';
const BoxModal = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            opacity: 0,
            transition: {
                delay: 0.15,
            },
        },
        visible: {
            opacity: 1,
        },
    },
    transition: {
        duration: 0.35,
    },
})) `
  position: fixed;
  background-color: rgba(255, 255, 255, 0.84);
  top: 18px;
  left: 0;
  width: 100%;
  height: calc(100% - 50px - 18px);
  padding: 16px;

  z-index: 103;
  flex-direction: column;
  align-items: center;
  display: none;

  @media ${from(Device.Tablet)} {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: unset;
  }

  ${({ active }) => active &&
    css `
      display: flex;
    `}

  ${({ small }) => small &&
    css `
      ${() => BoxModalInner} {
        max-width: 460px;
      }
    `};
`;
const BoxModalInner = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            y: 100,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                delay: 0.15,
            },
        },
    },
    transition: {
        duration: 0.35,
    },
})) `
  max-width: 760px;
  width: 100%;
  background-color: ${brand.white};
  padding: 20px;
  border-bottom: 7px solid ${brand.primary.light};
  margin: auto;
  display: flex;
  box-shadow: 0px 3px 6px 2px rgb(0 0 0 / 25%);
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  max-height: 100%;
  overflow-y: auto;

  ${({ fullWidth }) => fullWidth &&
    css `
      flex-direction: column !important;
    `}
`;
const BoxModalClose = styled.div `
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
  }
`;
const BoxModalMedia = styled.div `
  max-width: 260px;
  flex: 0 0 auto;

  @media ${from(Device.Tablet)} {
    margin: 0 auto;
  }

  ${({ fullWidth }) => fullWidth &&
    css `
      width: 100%;
      max-width: 700px;
    `}
`;
const BoxModalCopy = styled.div `
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${WYSIWYGStyles}

  padding: 25px 0px;
  color: #666666;
  line-height: 20px;
`;
const ResetButton = styled.div ``;
const ConfirmText = styled.div `
  ${fonts.DaxPro.Medium};
  font-size: 20px;
  margin: 20px 0px 30px;
  text-align: center;
`;
const Buttons = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  button:last-of-type {
    background-color: ${brand.divisionTags.sandhurst};
  }
`;
export const ModalStyles = {
    BoxModal,
    BoxModalInner,
    BoxModalClose,
    BoxModalMedia,
    BoxModalCopy,
    ResetButton,
    Buttons,
    ConfirmText,
};
