import React from 'react';
import { StepFooterStyles as S } from './StepFooter.styles';
import { ReactComponent as Chevron } from '@img/icons/chevron-right.svg';
import { ReactComponent as Restart } from '@img/icons/restart.svg';
import { ReactComponent as Home } from '@img/ysp/home.svg';
import { StepID, Tab } from '@ys/Context/types';
import { AnimatePresence } from 'framer-motion';
const StepFooter = ({ state, dispatch }) => {
    const nextStep = () => {
        if (state.stepFooter.beforeNext !== undefined) {
            state.stepFooter.beforeNext();
        }
        dispatch({ type: 'next-step' });
    };
    const previousStep = () => {
        dispatch({ type: 'previous-step' });
    };
    const restart = () => dispatch({ type: 'restart' });
    const isLoadingSolutions = (state.step === StepID.Solutions || state.step === StepID.SelectedSolution) &&
        (state.loading || state.loadingResults);
    const isNotOnYSSolutions = state.tab === Tab.YourSolution ? state.step !== StepID.Solutions : true;
    const hasYSPSolutions = state.tab === Tab.YourSolutionPlus && state.step === StepID.Solutions
        ? state.yspResults?.solutions !== undefined
        : true;
    return (React.createElement(S.Container, { zoomModalOpen: state.zoomModalOpen, isLandingPage: state.step === StepID.Landing },
        React.createElement(AnimatePresence, null,
            state.labels['back'] && state.step > StepID.Landing && (React.createElement(S.ButtonMotionWrapper, { key: 'BackButton' },
                React.createElement(S.Button, { type: "button", prev: true, footerPrevious: true, onClick: previousStep, disabled: state.modal !== undefined },
                    state.labels['back'],
                    React.createElement(Chevron, null)))),
            state.labels['next'] &&
                isNotOnYSSolutions &&
                state.step !== StepID.TermsAndConditions &&
                state.step !== StepID.SelectedSolution &&
                !isLoadingSolutions &&
                hasYSPSolutions && (React.createElement(S.ButtonMotionWrapper, { key: 'NextButton' },
                React.createElement(S.Button, { type: "button", disabled: state.stepFooter.nextDisabled || state.modal !== undefined, next: true, footerNext: true, onClick: nextStep },
                    state.labels['next'],
                    React.createElement(Chevron, null)))),
            state.labels['next'] && state.mobile && state.step === StepID.TermsAndConditions && (React.createElement(S.ButtonMotionWrapper, { key: 'NextButton' },
                React.createElement(S.Button, { type: "button", disabled: state.stepFooter.nextDisabled, next: true, footerNext: true, onClick: nextStep },
                    state.labels['accept'] || 'Accept',
                    React.createElement(Chevron, null)))),
            state.tab === Tab.YourSolution && state.step === StepID.Solutions && (React.createElement(S.ButtonMotionWrapper, { key: 'NextButton' },
                React.createElement(S.Button, { type: "button", footerNext: true, restart: true, onClick: restart, disabled: state.modal !== undefined },
                    "Restart",
                    React.createElement(Restart, null)))),
            !state.loadingResults &&
                state.tab === Tab.YourSolutionPlus &&
                state.selectedSolution &&
                state.step === StepID.SelectedSolution && (React.createElement(S.ButtonMotionWrapper, { key: 'NextButton' },
                React.createElement("a", { href: state.technicalLibraryUrl },
                    React.createElement(S.Button, { type: "button", footerNext: true, restart: true },
                        "Main Site",
                        React.createElement(Home, null))))))));
};
export default StepFooter;
