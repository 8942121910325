import React from 'react';
import uniqueId from 'lodash/uniqueId';
import { FormStyles } from '../../../Shared/Form.styles';
import { AnimatePresence } from 'framer-motion';
const Textbox = ({ value, label, onChange, readOnly = false, restrictWidth = false, error, fullWidth, placeholder, }) => {
    const id = uniqueId('textbox_');
    return (React.createElement(FormStyles.FormControl, { fullWidth: fullWidth, restrictWidth: restrictWidth, invalid: error !== undefined },
        React.createElement("label", { htmlFor: id }, label),
        React.createElement("input", { placeholder: placeholder, id: id, value: value, onChange: onChange, onKeyPress: (e) => e.key === 'Enter' && e.preventDefault(), readOnly: readOnly }),
        React.createElement(AnimatePresence, null, error && (React.createElement(FormStyles.FormErrorHidden, null,
            React.createElement(FormStyles.FormError, { htmlFor: id }, error))))));
};
export default Textbox;
