import React from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import InfoIcon from '@ys/Components/InfoIcon/InfoIcon';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import { LandingStyles as S } from './Landing.styles';
import { Tab } from '@ys/Context/types';
import { AnimatePresence } from 'framer-motion';
import { Containers, StepStyles } from '@ys/Components/Shared/Step.styles';
import YSButton from '../../Shared/YSButton/YSButton';
const Landing = () => {
    const { state, dispatch } = useYSPContext();
    const renderTooltip = () => {
        const tooltipText = state.labels['tooltip'];
        if (tooltipText === '' || tooltipText === undefined) {
            return null;
        }
        return (React.createElement(InfoIcon, { onClick: () => dispatch({
                type: 'set-modal',
                payload: {
                    description: tooltipText,
                    fullWidth: true,
                    size: 'normal',
                },
            }) }));
    };
    return (React.createElement(StepStyles.Grid, { centered: true },
        React.createElement(Containers.FullWidth, null,
            React.createElement(S.Intro, null,
                React.createElement(S.IntroTitleWrapper, null,
                    React.createElement("h1", null,
                        React.createElement(S.CenteredDiv, null,
                            React.createElement(S.WelcomeText, null, state.labels['title'] || 'Welcome to '),
                            React.createElement(S.TitleAndPlusWrapper, null,
                                React.createElement(S.Title, null,
                                    React.createElement("span", null, " Your"),
                                    React.createElement("span", null, "Solution")),
                                React.createElement(AnimatePresence, null, state.tab === Tab.YourSolutionPlus && React.createElement(S.Plus, null, "+")))))),
                React.createElement(FormStyles.Form, null,
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: state.labels['content'] } }),
                    renderTooltip()),
                React.createElement(YSButton, { onClick: () => dispatch({ type: 'next-step' }), icon: "arrow", hideOnMobile: true }, state.labels['next'])))));
};
export default Landing;
