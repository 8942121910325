import { imageUrl } from '@helpers/cloudinary';
import { withMotion } from '@hoc/withMotion';
import { ReactComponent as Cross } from '@img/ysp/icon-cross.svg';
import { ReactComponent as Reset } from '@img/ysp/reset-thick.svg';
import Checkbox from '@stories/Components/Forms/Checkbox/Checkbox';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import InfoIcon from '@ys/Components/InfoIcon/InfoIcon';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { Containers } from '@ys/Components/Shared/Step.styles';
import omit from 'lodash/omit';
import uniqueId from 'lodash/uniqueId';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ConfirmModal from '../../Modal/ConfirmModal';
import YSButton from '../../Shared/YSButton/YSButton';
import InlineNumericalInput from './InlineNumericalInput/InlineNumericalInput';
import SoilInfoTable from './SoilInfoTable/SoilInfoTable';
import { SoilProfilesStyles as S } from './SoilProfiles.styles';
import SoilTypeDropdown from './SoilTypeDropdown/SoilTypeDropdown';
import SoilVisualiser from './SoilVisualiser/SoilVisualiser';
const Layer = ({ position, layer, update, soilProfileOptions, previousDepth, nextDepth, lock, setTargetedLayer, ...rest }) => {
    return (React.createElement(S.LayerWrapper, { ...rest },
        React.createElement(S.LayerName, null,
            "Layer ",
            position + 1),
        React.createElement(InlineNumericalInput, { lock: lock, unit: "m", value: layer.bgl || undefined, disabled: position === 0, onChange: (depth) => {
                update(position, 'bgl', depth);
            }, min: previousDepth ? (isNaN(previousDepth) ? undefined : previousDepth) : undefined, max: nextDepth ? (isNaN(nextDepth) ? undefined : nextDepth) : undefined }),
        React.createElement(SoilTypeDropdown, { i: position, onChange: (value) => {
                const updatedValue = update(position, 'soilLayer', value);
                update(position, 'soilTypeId', value.id, updatedValue);
            }, value: layer.soilLayer, options: soilProfileOptions }),
        position > 0 && (React.createElement(S.DeleteWrapper, { onClick: () => setTargetedLayer(position) },
            React.createElement(Cross, null)))));
};
const SoilProfiles = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    const contentRef = useRef(null);
    const [targetedLayer, setTargetedLayer] = useState(-1);
    const [modalMode, setModalMode] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const isOverflown = useMemo(() => {
        if (contentRef.current) {
            return contentRef.current.scrollHeight > contentRef.current.clientHeight;
        }
        return false;
    }, [contentRef.current?.clientHeight, contentRef.current?.scrollHeight]);
    const modalLabels = useMemo(() => {
        switch (modalMode) {
            default:
            case 'reset-layer':
                return {
                    text: 'Are you sure you want to reset this layer to its default values?',
                    yes: 'Yes, reset the layer',
                    no: 'No, keep my values',
                };
            case 'delete-layer':
                return {
                    text: 'Are you sure you want to delete this layer? This cannot be undone.',
                    yes: 'Yes, delete the layer',
                    no: 'No, keep the layer',
                };
            case 'reset-all':
                return {
                    text: 'Are you sure you want to reset the soil parameters to the default values?',
                    yes: 'Yes, reset to default',
                    no: 'No, keep my parameters',
                };
        }
    }, [modalMode]);
    useEffect(() => {
        dispatch({
            type: 'set-footer',
            payload: {
                nextDisabled: state.form.soilLayers?.length === 0 || !state.form.soilDisclaimer,
            },
        });
    }, [state.form.soilLayers, state.form.soilDisclaimer]);
    const [layers, setLayers] = useState(state.form.soilLayers !== undefined && state.form.soilLayers.length !== 0
        ? state.form.soilLayers
        : [
            {
                id: uniqueId(),
                bgl: 0,
                soilTypeId: -1,
            },
        ]);
    useEffect(() => {
        const mergedLayers = layers.reduce((acc, cur) => {
            const layer = state.soilProfiles.find((x) => x.id === cur.soilTypeId);
            if (layer) {
                const merged = {
                    ...cur,
                };
                acc.push(merged);
            }
            return acc;
        }, []);
        setFormField('soilLayers', mergedLayers);
    }, [layers]);
    const resetAll = () => {
        // Moans about the 'id' not being the right type, but it's being omitted via Lodash //
        const clone = [...layers];
        clone.map((l, i) => {
            const soilInfo = state.soilProfiles.find((x) => x.id === l.soilTypeId);
            if (soilInfo) {
                clone[i] = { ...l, ...omit(soilInfo, 'id', 'bgl') };
            }
        });
        setLayers(clone);
        setFormField('soilDisclaimer', false);
    };
    const resetLayer = (position) => {
        const clone = [...layers];
        // Moans about the 'id' not being the right type, but it's being omitted via Lodash //
        let layer = clone[position];
        const soilInfo = state.soilProfiles.find((x) => x.id === layer.soilTypeId);
        if (soilInfo) {
            layer = { ...layer, ...omit(soilInfo, 'id', 'bgl') };
            clone.splice(position, 1, layer);
        }
        setLayers(clone);
    };
    const addLayer = () => {
        if (layers.length < 6) {
            const clone = [...layers];
            clone.push({
                id: uniqueId(),
                bgl: layers[layers.length - 1].bgl,
                soilTypeId: -1,
            });
            setLayers(clone);
        }
    };
    const modifyLayer = (position, type, value, updatedValue) => {
        const layer = updatedValue ? updatedValue : layers[position];
        if (layer) {
            // Moans about the 'id' not being the right type, but it's being omitted via Lodash //
            let clone = { ...layer };
            clone[type] = value;
            if (type === 'soilTypeId') {
                // Also update the table data with the corresponding soil information //
                const matchingSoil = state.soilProfiles.find((x) => x.id === value);
                if (matchingSoil) {
                    clone = { ...clone, ...omit(matchingSoil, 'id', 'bgl') };
                }
            }
            const layersClone = [...layers];
            layersClone.splice(position, 1, clone);
            setLayers(layersClone);
            return clone;
        }
        return layer;
    };
    const soilProfileOptions = useMemo(() => {
        return state.soilProfiles.reduce((acc, cur) => {
            acc.push({
                id: cur.id,
                icon: imageUrl(cur.image, `c_fill,w_30,h_30`),
                name: cur.description,
            });
            return acc;
        }, []);
    }, [state.soilProfiles]);
    return (React.createElement(S.GridWrapper, { ref: contentRef, offsetPadding: state.scrollLocked && isOverflown },
        React.createElement(Containers.Default, null,
            React.createElement(SharedStyles.TitleWithInfoIcon, null,
                React.createElement("h2", null, state.labels['heading']),
                state.labels['tooltip'] && (React.createElement(InfoIcon, { onClick: () => dispatch({
                        type: 'set-modal',
                        payload: {
                            description: state.labels['tooltip'],
                            fullWidth: true,
                            size: 'normal',
                        },
                    }) }))),
            React.createElement(S.Container, null,
                React.createElement(S.TopStep, null,
                    React.createElement(SoilVisualiser, null),
                    soilProfileOptions.length > 0 && (React.createElement(S.LayerOptions, null,
                        layers.map((layer, i) => (React.createElement(Layer, { "data-last": i === layers.length - 1, key: `SoilProfile_${i}_${soilProfileOptions.length}`, layer: layer, soilProfileOptions: soilProfileOptions, update: modifyLayer, position: i, previousDepth: i > 0 ? layers[i - 1].bgl : undefined, nextDepth: i < layers.length - 1 ? layers[i + 1].bgl : undefined, lock: (b) => !state.mobile && dispatch({ type: 'set-locked', payload: b }), setTargetedLayer: (pos) => {
                                if (modalMode !== 'delete-layer') {
                                    setModalMode('delete-layer');
                                }
                                setTargetedLayer(pos);
                                setModalOpen(true);
                            } }))),
                        React.createElement(S.ButtonWrapper, null,
                            React.createElement(YSButton, { icon: "plus", disabled: layers.length === 6 || layers.some((x) => x.soilTypeId === -1), onClick: () => addLayer() }, "Add new layer"),
                            React.createElement(Checkbox, { id: "disclaimer", name: "disclaimer", type: "checkbox", value: state.form.soilDisclaimer, onChange: (e) => {
                                    setFormField('soilDisclaimer', e.target.checked);
                                }, label: state.labels['disclaimer'] ||
                                    'The above soil profile sufficiently represents the full depth of the excavation' }))))),
                React.createElement(SoilInfoTable, { layers: layers.filter((x) => x.soilTypeId > -1), update: modifyLayer, setTargetedLayer: (pos) => {
                        if (modalMode !== 'reset-layer') {
                            setModalMode('reset-layer');
                        }
                        setTargetedLayer(pos);
                        setModalOpen(true);
                    } }),
                layers.length > 1 && (React.createElement(S.ResetAllWrapper, { onClick: () => {
                        setModalMode('reset-all');
                        setModalOpen(true);
                    } },
                    React.createElement(Reset, null),
                    React.createElement("span", null, "Reset to default values"))))),
        React.createElement(ConfirmModal, { visible: modalOpen, text: modalLabels.text, yesText: modalLabels.yes, noText: modalLabels.no, onYes: () => {
                if (modalMode === 'delete-layer' && targetedLayer > -1) {
                    const clone = [...layers];
                    clone.splice(targetedLayer, 1);
                    setLayers(clone);
                }
                else if (modalMode === 'reset-layer') {
                    resetLayer(targetedLayer);
                }
                else if (modalMode === 'reset-all') {
                    resetAll();
                }
                setModalOpen(false);
            }, onNo: () => setModalOpen(false), onClose: () => setModalOpen(false) })));
};
export default withMotion(SoilProfiles);
