import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import React, { useEffect } from 'react';
import InfoIcon from '@ys/Components/InfoIcon/InfoIcon';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { Containers, StepStyles } from '@ys/Components/Shared/Step.styles';
import SoilVisualiser from '@ys/Components/Steps/SoilProfiles/SoilVisualiser/SoilVisualiser';
import { SoilSourceStyles as S } from './SoilSource.styles';
import Dropdown from '@ys/Components/Steps/Controls/Dropdown/Dropdown';
import { SoilInfoSource } from '@stories/Widgets/YourSolution/YS/Context/types';
import Textbox from '../../Controls/Textbox/Textbox';
const SoilSource = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    useEffect(() => {
        dispatch({
            type: 'set-footer',
            payload: {
                nextDisabled: state.form.soilInfoSource === undefined ||
                    state.form.soilInfoSourceRef === undefined ||
                    state.form.soilInfoSourceCompany === undefined,
            },
        });
    }, [state.form.soilInfoSource, state.form.soilInfoSourceRef, state.form.soilInfoSourceCompany]);
    return (React.createElement(StepStyles.Grid, null,
        React.createElement(Containers.Default, null,
            React.createElement(SharedStyles.TitleWithInfoIcon, null,
                React.createElement("h2", null, state.labels['heading']),
                state.labels['tooltip'] && (React.createElement(InfoIcon, { onClick: () => dispatch({
                        type: 'set-modal',
                        payload: {
                            description: state.labels['tooltip'],
                            fullWidth: true,
                            size: 'normal',
                        },
                    }) }))),
            React.createElement(S.Container, null,
                React.createElement(SoilVisualiser, null)),
            React.createElement("br", null),
            React.createElement(S.Container, null,
                React.createElement(S.Centered, null,
                    React.createElement("span", null, state.labels['soilsource.label']),
                    React.createElement(Dropdown, { onChange: (option) => setFormField('soilInfoSource', option), value: state.form.soilInfoSource !== undefined ? state.form.soilInfoSource : -1, options: [
                            {
                                id: SoilInfoSource.Verbal,
                                text: state.labels['soilsource.verbal'],
                            },
                            {
                                id: SoilInfoSource.BoreholeLog,
                                text: state.labels['soilsource.boreholelog'],
                            },
                        ] }),
                    state.form.soilInfoSource !== undefined && (React.createElement(React.Fragment, null,
                        React.createElement("br", null),
                        React.createElement(Textbox, { fullWidth: true, placeholder: state.labels['typeHere'], label: state.form.soilInfoSource === SoilInfoSource.Verbal
                                ? state.labels['verbalName'] ?? 'Their name'
                                : state.labels['boreLogRef'] ?? 'Document reference', value: state.form.soilInfoSourceRef, error: state.labels[state.errors['soilInfoSourceRef'] || ''], onChange: (e) => setFormField('soilInfoSourceRef', e.currentTarget.value) }),
                        React.createElement(Textbox, { fullWidth: true, placeholder: state.labels['typeHere'], label: state.labels['soilInfoSourceCompany'] ?? 'Company', value: state.form.soilInfoSourceCompany, error: state.labels[state.errors['soilInfoSourceCompany'] || ''], onChange: (e) => setFormField('soilInfoSourceCompany', e.currentTarget.value) }))))))));
};
export default SoilSource;
