import React, { createContext, useContext, useReducer } from 'react';
import reducer from './reducer';
import { getInitialState } from './types';
const YSContext = createContext({});
export const YSContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, getInitialState(props));
    return React.createElement(YSContext.Provider, { value: { state, dispatch } }, props.children);
};
export const useYSPContext = () => {
    const contextValue = useContext(YSContext);
    contextValue.setFormField = (field, value, invalidatedFields) => {
        contextValue.dispatch({
            type: 'set-form-field',
            payload: {
                field,
                value,
                invalidatedFields,
            },
        });
    };
    return contextValue;
};
