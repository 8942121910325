import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { ButtonReset } from '@helpers/global';
import { from, Device, until } from '@helpers/media';
import { darken, lighten } from 'polished';
import styled from 'styled-components';
const Container = styled.button `
  ${ButtonReset}
  display: flex;
  gap: 15px;
  align-items: center;
  user-select: none;

  padding: 10px 15px;
  &[data-size='wide'] {
    padding: 10px 25px;
  }

  border-radius: 8px;
  width: max-content;
  max-width: 100%;

  ${fonts.DaxPro.Medium};
  font-size: 20px;

  transition: all 0.1s ease-in-out;
  &[data-hide='desktop'] {
    @media ${from(Device.TabletLarge)} {
      display: none;
    }
  }

  &[data-hide='mobile'] {
    @media ${until(Device.TabletLarge)} {
      display: none;
    }
  }

  &[data-colour='green'] {
    --bg: ${brand.primary.light};
    --bgHover: ${lighten(0.02, brand.primary.light)};
    --bgFocus: ${darken(0.01, brand.primary.light)};
    --fg: ${brand.white};
  }

  &[data-colour='orange'] {
    --bg: ${brand.accent.orange};
    --bgHover: ${lighten(0.02, brand.accent.orange)};
    --bgFocus: ${darken(0.01, brand.accent.orange)};
    --fg: ${brand.white};
  }

  &:disabled {
    --bg: ${brand.grey.grey40};
    --bgHover: ${lighten(0.02, brand.grey.grey40)};
    --bgFocus: ${darken(0.01, brand.grey.grey40)};
    cursor: not-allowed;
  }

  // !important for legacy overrides //
  border: 2px solid transparent;
  background-color: var(--bg) !important;
  color: var(--fg) !important;

  &:hover {
    background-color: var(--bgHover) !important;
  }

  &:active {
    background-color: var(--bgFocus) !important;
  }

  &[data-fill='outline'] {
    border-color: var(--bg);
    background-color: transparent !important;
    color: var(--bg) !important;
    ${fonts.DaxPro.Bold};

    &:hover {
      background-color: transparent !important;
    }

    &:focus {
      background-color: transparent !important;
    }
  }

  &[data-center='true'] {
    margin: auto;
  }
`;
const Icon = styled.div `
  display: flex;
  align-items: center;

  [data-icon-position='left'] & {
    order: -1;
  }

  [data-icon='none'] & {
    display: none;
  }

  svg {
    width: 12px;
    color: var(--fg);
    [data-fill='outline'] & {
      color: var(--bg);
    }

    [data-icon='plus'] & {
      width: 15px;
    }

    [data-icon='check'] & {
      width: 19px;
    }

    [data-icon='phone'] &,
    [data-icon='email'] & {
      width: 20px;
    }
  }
`;
const LinkWrapper = styled.a `
  text-decoration: none !important;
`;
export const YSButtonStyles = {
    Container,
    Icon,
    LinkWrapper,
};
