import React from 'react';
import { NumberInputStyles as S } from './NumberInput.styles';
const NumberInput = ({ value, defaultValue, onChange, step = 0.1, min = 0, id, layout = 'default', bg = 'default', alignment = 'center', onFocus, onBlur, onClick, }) => {
    const [hasFocus, setHasFocus] = React.useState(false);
    const padNumber = (n) => n ? (n.toString().includes('.') ? n.toString() : `${n}.0`) : hasFocus ? undefined : '0.0';
    const handleKeyPress = (event) => {
        if (event.charCode == 13) {
            event.preventDefault();
        }
    };
    return (React.createElement(S.Input, { "data-layout": layout, "data-bg": bg, "data-alignment": alignment, type: "number", id: id, step: step, min: min, "data-has-focus": hasFocus, defaultValue: defaultValue, value: hasFocus ? value : padNumber(value), onChange: (e) => {
            onChange(e.currentTarget.value, e);
        }, onClick: () => onClick && onClick(), onFocus: () => {
            setHasFocus(true);
            onFocus && onFocus();
        }, onBlur: (e) => {
            setHasFocus(false);
            if (value && !value.toString().includes('.')) {
                onChange(`${value}.0`, e);
            }
            else if (value === undefined) {
                onChange('0.0', e);
            }
            onBlur && onBlur();
        }, onKeyPress: (e) => handleKeyPress(e) }));
};
export default NumberInput;
