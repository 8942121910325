import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import styled from 'styled-components';
import { DropdownStyles } from '../../Controls/Dropdown/Dropdown.styles';
const Container = styled.div ``;
const Solutions = styled.div `
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
const NoSolutions = styled.div `
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 25px 45px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 17px -6px rgba(0, 0, 0, 0.75);
  max-width: 750px;
  margin: auto;
  text-align: center;
  background-color: ${brand.white};
`;
const NoSolutionsTitle = styled.div `
  font-size: 25px;
  ${fonts.DaxPro.Bold};
`;
const NoSolutionsSubtitle = styled.div `
  font-size: 20px;
  ${fonts.DaxPro.Regular};

  span {
    color: ${brand.primary.alt};
  }
`;
const NoSolutionsGetInTouch = styled.div `
  font-size: 20px;
  ${fonts.DaxPro.Medium};
  margin-top: 15px;
`;
const NoSolutionsDetails = styled.div `
  font-size: 20px;
  ${fonts.DaxPro.Regular};
  display: flex;
  flex-direction: column;
  gap: 25px;

  a {
    color: ${brand.primary.base};
  }
`;
const DropdownWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 1300px;
  margin: auto;
  margin-bottom: 25px;

  ${DropdownStyles.Container} {
    background-color: ${brand.ysp.input};
  }
`;
export const YSPSolutionsStyles = {
    Container,
    Solutions,
    NoSolutions,
    NoSolutionsTitle,
    NoSolutionsSubtitle,
    NoSolutionsGetInTouch,
    NoSolutionsDetails,
    DropdownWrapper,
};
