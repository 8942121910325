import React from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import InfoIcon from '@ys/Components/InfoIcon/InfoIcon';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { BoxesStyles as S } from './Boxes.styles';
const Boxes = ({ boxes, error, value, setValue }) => {
    const { dispatch } = useYSPContext();
    const handleSetModalActive = (value) => {
        const activeBox = boxes.find((x) => x.id === value);
        if (activeBox !== undefined) {
            dispatch({
                type: 'set-modal',
                payload: {
                    title: activeBox.title,
                    description: activeBox.description,
                    imageUrl: activeBox.imageUrl,
                    fullWidth: false,
                    size: 'normal',
                },
            });
        }
    };
    return (React.createElement(S.Container, null, boxes.map((x) => {
        return (React.createElement(SharedStyles.Box, { key: x.id, active: value !== undefined && value === x.id, invalid: error !== undefined },
            React.createElement(SharedStyles.BoxInner, { onClick: () => setValue(x.id) },
                React.createElement(SharedStyles.BoxMedia, null,
                    React.createElement("img", { src: x.imageUrl, alt: x.title, style: { objectFit: 'cover' } })),
                React.createElement(SharedStyles.BoxLabel, null, x.title),
                React.createElement(SharedStyles.BoxState, null)),
            x.description && x.description.length > 0 && (React.createElement(SharedStyles.BoxInfo, null,
                React.createElement(InfoIcon, { onClick: () => handleSetModalActive(x.id) })))));
    })));
};
export default Boxes;
