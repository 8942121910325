import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import { m } from 'framer-motion';
import styled from 'styled-components';
const Container = styled.div `
  border-radius: 5px;
  border: 2px solid ${brand.grey.grey35};
  background-color: ${brand.white};
  position: relative;
  height: 100%;
  transition: background-color 0.2s ease-in-out;

  @media ${until(Device.TabletLarge)} {
    grid-column: 1 / -1;
  }
`;
const TopSection = styled.div `
  ${fonts.DaxPro.Regular};
  display: grid;
  grid-template-columns: 1fr 25px;
  cursor: pointer;
  padding: 0px 10px;
  height: 100%;
  user-select: none;
  max-height: 42px;

  @media ${from(Device.TabletLarge)} {
    max-height: 30px;
  }

  @media ${until(Device.TabletLarge)} {
    min-height: 30px;
  }

  span {
    margin: auto 0;
  }

  svg {
    margin: auto;
    color: ${brand.primary.alt};
    width: 15px;
  }
`;
const DropdownWrapper = styled(m.div).attrs(({ active }) => ({
    initial: 'hidden',
    animate: active ? 'visible' : 'hidden',
    variants: {
        hidden: {
            height: 0,
            opacity: 0,
            transition: {
                opacity: {
                    duration: 0.1,
                    delay: 0.2,
                },
            },
        },
        visible: {
            height: 'auto',
            opacity: 1,
            transition: {
                opacity: {
                    duration: 0.1,
                },
            },
        },
    },
})) `
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  z-index: ${({ i }) => 20 - i};
  background-color: ${brand.white};

  @media ${from(Device.TabletLarge)} {
    z-index: ${({ i }) => 6 - i};
  }
`;
const BorderWrapper = styled.div `
  overflow-y: auto;
  height: 100%;
  border: 2px solid ${brand.grey.grey35};
  border-top: none;
  max-height: 450px;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${brand.primary.light};
    border-radius: 10px;
  }
`;
const Dropdown = styled.div `
  display: flex;
  flex-direction: column;
`;
const DropdownOption = styled.div `
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 10px;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  user-select: none;

  &:hover {
    background-color: ${brand.grey.grey89};
  }
`;
const SoilImage = styled.div `
  aspect-ratio: 1 / 1;
  border-radius: 3px;
  background-image: url('${({ background }) => background}');
`;
const SelectedOption = styled.div `
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 5px;
  align-items: center;
  padding: 3px 0px;
  margin-left: -5px;
  white-space: nowrap;

  span {
    text-overflow: ellipsis;
    overflow: hiddne;
  }

  @media ${until(Device.TabletLarge)} {
    min-height: 30px;
  }

  ${() => SoilImage} {
    aspect-ratio: unset;
    width: 24px;
    height: 100%;
    background-size: contain;
    border: 2px solid ${brand.black};
  }
`;
export const SoilTypeDropdownStyles = {
    Container,
    TopSection,
    DropdownWrapper,
    Dropdown,
    DropdownOption,
    SoilImage,
    BorderWrapper,
    SelectedOption,
};
