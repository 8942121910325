import React, { useEffect } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SolutionType, SupportType, Tab } from '@ys/Context/types';
import Boxes from '@ys/Components/Steps/Controls/Boxes/Boxes';
import InfoIcon from '@ys/Components/InfoIcon/InfoIcon';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
const Type = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    useEffect(() => {
        //
        dispatch({
            type: 'set-footer',
            payload: {
                beforeNext: () => dispatch({ type: 'validate-type' }),
            },
        });
    }, []);
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            state.form.supportType === SupportType.Box && (React.createElement(React.Fragment, null,
                React.createElement("h2", null, state.labels['heading_boxType']),
                React.createElement(Boxes, { error: state.errors['type'], boxes: [
                        {
                            id: SolutionType.TrenchBox,
                            title: 'Trench Box',
                            description: state.labels['tooltip_trench'],
                            imageUrl: '/sitefiles/images/ism/products/TB-BH-1-Backhoe(BaseOnly).png',
                        },
                        {
                            id: SolutionType.ManholeBox,
                            title: 'Manhole Box',
                            description: state.labels['tooltip_manholeBox'],
                            imageUrl: '/sitefiles/images/ism/products/MB-BH-1-Backhoe(BaseOnly).png',
                        },
                        {
                            id: SolutionType.Lightweight,
                            title: 'Lightweight box',
                            description: state.labels['tooltip_lightDuty'],
                            imageUrl: '/sitefiles/images/ism/products/LW-MAP.png',
                        },
                    ], value: state.form.type, setValue: (value) => setFormField('type', value) }))),
            state.form.supportType === SupportType.Frame && (React.createElement(React.Fragment, null,
                React.createElement("h2", null, state.labels['heading_frameType']),
                React.createElement(Boxes, { error: state.errors['type'], boxes: [
                        {
                            id: SolutionType.SheetsAndManholeBrace,
                            title: 'Manhole Brace',
                            description: state.labels['tooltip_manholeBrace'],
                            imageUrl: '/sitefiles/images/ism/products/B-1F-1Frame.png',
                        },
                        {
                            id: SolutionType.SheetsAndWalers,
                            title: 'Walers',
                            description: state.labels['tooltip_walers'],
                            imageUrl: '/sitefiles/images/ism/products/W-1F-1Frame.png',
                        },
                    ], value: state.form.type, setValue: (value) => setFormField('type', value) }))),
            state.tab === Tab.YourSolutionPlus &&
                React.createElement(React.Fragment, null,
                    React.createElement("h2", null, state.labels['heading_frameType']),
                    state.labels['tooltip_frameType'] && (React.createElement(InfoIcon, { onClick: () => {
                            dispatch({
                                type: 'set-modal',
                                payload: {
                                    description: state.labels['tooltip_frameType'],
                                    fullWidth: true,
                                    size: 'normal',
                                },
                            });
                        } })),
                    React.createElement(Boxes, { error: state.errors['type'], boxes: [
                            {
                                id: SolutionType.TrenchBox,
                                title: 'Trench Box',
                                description: state.labels['tooltip_trench'],
                                imageUrl: '/sitefiles/images/ism/products/TB-BH-1-Backhoe(BaseOnly).png',
                            },
                            {
                                id: SolutionType.ManholeBox,
                                title: 'Manhole Box',
                                description: state.labels['tooltip_manholeBox'],
                                imageUrl: '/sitefiles/images/ism/products/MB-BH-1-Backhoe(BaseOnly).png',
                            },
                        ], value: state.form.type, setValue: (value) => setFormField('type', value) })))));
};
export default Type;
