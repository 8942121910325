import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
const Container = styled.div `
  align-self: center;
  cursor: pointer;
  user-select: none;
  position: relative;
  z-index: 103;

  @media ${until(Device.TabletLarge)} {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
    transition: opacity 0.25s ease-in-out;

    ${({ zoomModalOpen }) => zoomModalOpen &&
    css `
        opacity: 0;
        pointer-events: none;
      `}
  }

  ${({ active }) => active &&
    css `
      ${() => Items} {
        display: block;
      }
    `}
`;
const BlurLine = styled.div `
  box-shadow: 0px 5px 4px -3px rgba(0, 0, 0, 0.75);
  height: 7px;
  width: 100%;

  @media ${from(Device.TabletLarge)} {
    display: none;
  }
`;
const SelectedStep = styled.div `
  font-size: 15px;
  padding-top: 25px;
  ${fonts.DaxPro.Regular};
  display: flex;
  align-items: center;

  @media ${from(Device.TabletLarge)} {
    padding-top: 10px;
    font-size: 18px;
  }

  svg {
    width: 22px;
    height: 15px;
    color: ${brand.primary.light};
    margin-left: 8px;
    transform: rotate(90deg);
    transition: transform 0.15s ease-in-out;

    ${({ isOpen }) => isOpen &&
    css `
        transform: rotate(-90deg);
      `}
  }
`;
const Items = styled(m.div).attrs(({ active }) => ({
    initial: 'hidden',
    animate: active ? 'visible' : 'hidden',
    variants: {
        hidden: {
            height: 0,
            overflow: 'hidden',
        },
        visible: {
            height: 'auto',
        },
    },
    transition: {
        type: 'tween',
        duration: 0.35,
    },
})) `
  display: none;
  max-width: 415px;
  width: calc(100vw - 36px);
  max-height: 50vh;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  background-color: ${brand.white};
`;
const ItemsInner = styled.div `
  border: 1px solid #c7c7c7;
`;
const Step = styled.div `
  padding: 12px 10px;
  opacity: 0.5;
  transition: opacity 0.15s ease-in-out, background-color 0.1s ease-in-out;
  background-color: ${brand.grey.grey96};

  ${({ active }) => active &&
    css `
      opacity: 1;
      text-decoration: underline;
    `};

  ${({ completed }) => completed &&
    css `
      opacity: 1;
    `};

  &:hover {
    opacity: 1;
    background-color: ${brand.grey.grey89};
  }
`;
export const StepPickerStyles = {
    Container,
    SelectedStep,
    Items,
    Step,
    ItemsInner,
    BlurLine,
};
