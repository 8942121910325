import brand from '@helpers/brand';
import { Device, from, until } from '@helpers/media';
import styled, { css } from 'styled-components';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { m } from 'framer-motion';
const Container = styled.div `
  position: fixed;
  height: var(--ysp-footer-height-mobile);
  background-color: ${brand.primary.light};
  display: flex;
  justify-content: space-between;
  pointer-events: none;

  left: 0px;
  bottom: 0px;
  z-index: 101;
  width: 100%;

  @media ${until(Device.TabletLarge)} {
    transition: opacity 0.25s ease-in-out;

    ${({ isLandingPage }) => isLandingPage &&
    css `
        justify-content: flex-end;
      `};

    ${({ zoomModalOpen }) => zoomModalOpen &&
    css `
        opacity: 0;
        pointer-events: none;
      `}
  }

  @media ${from(Device.TabletLarge)} {
    background-color: transparent;
    width: calc(100% - 120px);
    height: var(--ysp-footer-height);
    left: 60px;
    bottom: 60px;
  }
`;
const ButtonMotionWrapper = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            y: 55,
            opacity: 0,
        },
        visible: {
            opacity: 1,
            y: 0,
        },
    },
    transition: {
        duration: 0.75,
    },
})) `
  pointer-events: all;
  height: 100%;

  @media ${until(Device.TabletLarge)} {
    display: flex;
    width: 100%;

    a {
      margin-left: auto;
      padding-right: 5px;
    }
  }

  @media ${from(Device.TabletLarge)} {
    width: max-content;
  }
`;
export const Button = styled(SharedStyles.Button) `
  width: max-content;

  ${({ footerPrevious, footerNext }) => (footerPrevious || footerNext) &&
    css `
      top: 50%;
      transform: translateY(-50%);
    `};

  ${({ footerPrevious }) => footerPrevious &&
    css `
      &:disabled {
        background-color: unset;
      }

      background-color: unset;
      @media ${from(Device.TabletLarge)} {
        color: ${brand.primary.alt};
      }
      left: 20px;
    `};

  ${({ footerNext }) => footerNext &&
    css `
      right: 20px;
    `}

  @media ${until(Device.TabletLarge)} {
    height: 100%;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0px;

    ${({ footerNext }) => footerNext &&
    css `
        padding: 0px 25px 0px 10px;
        text-align: right;
        justify-content: flex-end;

        svg {
          right: 0;
        }
      `};

    ${({ footerPrevious }) => footerPrevious &&
    css `
        text-align: left;
        padding: 0px 10px 0px 25px;
        svg {
          left: 0;
        }
      `};

    svg {
      top: calc(50% + 1px); // Idk why but it seems to be about 1px off and it annoys me //
      height: 16px;
    }
  }

  ${({ inverse }) => inverse &&
    css `
      background-color: ${brand.white} !important;
      border-color: ${brand.primary.alt} !important;
      color: ${brand.primary.alt} !important;
    `};
`;
const ButtonSmall = styled(Button) `
  padding: 4px 15px;
  font-size: 20px;
`;
const MobileButton = styled.div ``;
export const StepFooterStyles = {
    Container,
    Button,
    ButtonMotionWrapper,
    ButtonSmall,
    MobileButton,
};
