import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import styled, { css } from 'styled-components';
import { m } from 'framer-motion';
const Form = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 750px;
  margin: 0 auto;
  width: 100%;
  line-height: 20px;

  @media ${from(Device.Tablet)} {
    position: relative;
    z-index: 1;
  }
`;
const FormRow = styled.div `
  width: 100%;
  max-width: 304px;
  margin: 5px 0px;

  ${({ wide }) => wide &&
    css `
      max-width: 420px;
    `}

  ${({ split }) => split &&
    css `
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    `};

  ${({ xCentered }) => xCentered &&
    css `
      display: flex;
      flex-direction: row;
      justify-content: center;
    `};
`;
const FormControl = styled.div `
  padding: 5px 0px;
  margin: 0 auto;
  position: relative;

  ${({ fullWidth }) => fullWidth &&
    css `
      margin: unset;
    `}

  ${({ restrictWidth }) => restrictWidth &&
    css `
      max-width: 300px;
      width: 100%;
    `}

  ${({ center }) => center &&
    css `
      align-items: center;
    `}

  label {
    display: block;
    font-weight: bold;
    ${fonts.DaxPro.Bold};
    color: ${brand.grey.grey20};
    max-width: 100%;
    margin-bottom: 5px;
  }

  ${() => FormError} {
    display: none;
  }

  input {
    display: inline-block;
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    border: 1px solid ${brand.black};
    padding-bottom: 10px;
    position: relative;

    &[type='number'] {
      max-width: 100px;
      text-align: center;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  iframe {
    border-radius: 4px;
  }

  ${({ invalid }) => invalid &&
    css `
      input {
        border: 1px solid ${brand.red.base};
      }

      ${() => FormError} {
        display: block;
      }
    `}
`;
const FormErrorHidden = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'exit',
    variants: {
        hidden: {
            y: 10,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
        },
    },
})) `
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;

  ${() => FormError} {
    line-height: 13px;
    font-size: 12px;
  }
`;
const FormError = styled.label `
  display: block;
  font-weight: bold;
  ${fonts.DaxPro.Bold};
  color: ${brand.red.base} !important;
  font-size: 14px;
`;
const FormSub = styled.span `
  display: inline-block;
  vertical-align: bottom;
  margin-left: 3px;
`;
const FormSplit = styled(Form) `
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  @media ${from(Device.TabletLarge)} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  ${() => FormRow} {
    max-width: unset;
  }
`;
export const FormStyles = {
    Form,
    FormRow,
    FormControl,
    FormError,
    FormSub,
    FormSplit,
    FormErrorHidden,
};
