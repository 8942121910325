import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import styled from 'styled-components';
const Input = styled.input `
  display: inline-block;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid ${brand.black};
  padding-bottom: 10px;
  position: relative;
  transition: all 0.25s;

  &[type='number'] {
    max-width: 100px;
    text-align: center;
  }

  &[data-layout='soil-table'] {
    padding: 6px;
    max-width: unset;
    border: none;
    border-radius: 0;
    width: 100%;
    position: relative;
    transition: outline-color 0.1s ease-in-out;
    outline: 2px solid transparent;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin-left: 5px;
    }

    &::selection {
      color: ${brand.white};
      background-color: ${brand.divisionTags.sandhurst};
    }

    &:focus {
      outline-color: ${brand.divisionTags.sandhurst};
    }
  }

  &[data-layout='surcharges-table'] {
    padding: 6px 10px;
    border: none;
    border-radius: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition: outline-color 0.1s ease-in-out;
    outline: 2px solid transparent;
    ${fonts.DaxPro.Medium};

    &::selection {
      color: ${brand.white};
      background-color: ${brand.divisionTags.sandhurst};
    }

    &:focus {
      outline-color: ${brand.divisionTags.sandhurst};
    }
  }

  &[data-layout='default'] {
    &:focus,
    &:active {
      outline: none;
    }
  }

  &[data-bg='green'] {
    background-color: ${brand.ysp.input};
  }

  &[data-bg='white'] {
    background-color: ${brand.white};
  }

  &[data-bg='grey'] {
    background-color: ${brand.grey.grey89};
  }

  &[data-alignment='left'] {
    text-align: left;
  }

  &[data-alignment='center'] {
    text-align: center;
  }

  &[data-alignment='right'] {
    text-align: right;
  }
`;
export const NumberInputStyles = {
    Input,
};
