import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Checkbox from '@ys/Components/Steps/Controls/Checkbox/Checkbox';
import Toggle from '@ys/Components/Steps/Controls/Toggle/Toggle';
import { SoilInfoTableStyles as S } from './SoilInfoTable.styles';
import { ReactComponent as Close } from '@img/icons/close-white.svg';
import { ReactComponent as Reset } from '@img/ysp/reset-thick.svg';
import YSButton from '../../../Shared/YSButton/YSButton';
import NumberInput from '../../../Shared/NumberInput/NumberInput';
export const padNumber = (number) => number ? (number.toString().includes('.') ? number.toString() : `${number}.0`) : '0.0';
const SoilInfoTable = ({ layers, update, setTargetedLayer, }) => {
    const { state: { labels, form }, dispatch, setFormField, } = useYSPContext();
    const [editSoilParameters, setEditSoilParameters] = useState(form.acceptedSoilModification ? form.acceptedSoilModification : false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [hasAccepted, setHasAccepted] = useState(form.acceptedSoilModification ? form.acceptedSoilModification : false);
    useEffect(() => {
        if (editSoilParameters && !hasAccepted) {
            setShowEditModal(true);
        }
        else if (!editSoilParameters) {
            setHasAccepted(false);
        }
    }, [editSoilParameters]);
    useEffect(() => {
        setFormField('acceptedSoilModification', editSoilParameters && hasAccepted);
    }, [editSoilParameters, hasAccepted]);
    useEffect(() => {
        // If they've opened the modal box, but not accepted and closed, reset the toggle //
        if (!showEditModal && !hasAccepted) {
            setEditSoilParameters(false);
        }
        dispatch({ type: 'set-soil-modal', payload: showEditModal });
    }, [showEditModal]);
    const determineClick = () => {
        if (!hasAccepted) {
            setShowEditModal(true);
        }
    };
    const changeHandler = (e, callback) => {
        hasAccepted && callback(e);
    };
    const toggleLocked = (b) => {
        dispatch({ type: 'set-locked', payload: b });
    };
    return (React.createElement(S.Wrapper, null,
        React.createElement(S.TopSection, null,
            React.createElement(S.Title, { dangerouslySetInnerHTML: { __html: labels['heading_soil'] || 'Soil Parameters' } }),
            React.createElement(S.Disclaimer, { dangerouslySetInnerHTML: {
                    __html: labels['soiltable.heading'] ||
                        `<p>If the soil doesn&#39;t match what our default parameters state, you can change these to get a more accurate result for which Solution to use. Click on the toggle below to enable Profile editing</p>\r\n`,
                } }),
            React.createElement(Toggle, { value: hasAccepted && !showEditModal, label: 'Edit Soil Parameters', onChange: (v) => v && setEditSoilParameters(v) }),
            React.createElement(AnimatePresence, null,
                React.createElement(S.Modal, { active: showEditModal, key: 'SoilProfileModal' },
                    React.createElement(S.ModalInner, null,
                        React.createElement(S.Close, { onClick: () => {
                                setHasAccepted(false);
                                setShowEditModal(false);
                            } },
                            React.createElement(Close, null)),
                        React.createElement(S.ModalTitle, null, "Edit Soil Parameters"),
                        React.createElement(S.ModalText, { dangerouslySetInnerHTML: {
                                __html: labels['soilmodal.content'] ||
                                    'Lorem ipsum, blah blah, words and some more words<br/>'.repeat(50),
                            } }),
                        React.createElement(Checkbox, { label: "I confirm I have read the Terms & Conditions", value: hasAccepted, onChange: () => setHasAccepted(!hasAccepted) }),
                        React.createElement(YSButton, { colour: "orange", disabled: !hasAccepted, onClick: () => setShowEditModal(false) }, "Accept"))))),
        React.createElement(S.Container, null,
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.SmallHeader, { bold: true },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.layerlabel'] || 'Layer Start Depth',
                            } })),
                    React.createElement(S.SmallHeader, null,
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.layerunits'] || 'mBGL',
                            } }))),
                layers.map((layer, i) => (React.createElement(S.FieldRow, { key: `${layer.id}_${i}` },
                    React.createElement(NumberInput, { bg: 'green', alignment: "center", step: 0.1, min: 0, value: layer.bgl, onChange: (e) => update(i, 'bgl', e), layout: "soil-table", onFocus: () => toggleLocked(true), onBlur: () => toggleLocked(false) }))))),
            React.createElement(S.WideColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.LargeHeader, { bold: true },
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.descriptionlabel'] || 'Description',
                            } }))),
                layers.map((layer, i) => (React.createElement(S.FieldRow, { key: `${layer.id}_${i}` },
                    React.createElement(S.FieldInput, { bg: 'green', type: "text", align: 'left', value: layer.description || '', onChange: (e) => update(i, 'description', e.currentTarget.value) }))))),
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.SmallHeader, null,
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.soildensitylabel'] || 'Soil Density γ',
                            } })),
                    React.createElement(S.SmallHeader, null,
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.soildensityunits'] || 'kN/m<sup>3</sup>',
                            } }))),
                layers.map((layer, i) => (React.createElement(S.FieldRow, { key: `${layer.id}_${i}` },
                    React.createElement(NumberInput, { bg: i % 2 === 0 ? 'white' : 'grey', alignment: "right", step: 0.1, min: 0, layout: "soil-table", value: layer.soilDensity, onChange: (e, event) => changeHandler(event, (e) => update(i, 'soilDensity', e.currentTarget.value)), onFocus: () => toggleLocked(true), onBlur: () => toggleLocked(false), onClick: determineClick }))))),
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.SmallHeader, null,
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.saturateddensitylabel'] ||
                                    'Saturated Density, γ<sub>sat</sub>',
                            } })),
                    React.createElement(S.SmallHeader, null,
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.saturateddensityunits'] || 'kN/m<sup>3</sup>',
                            } }))),
                layers.map((layer, i) => (React.createElement(S.FieldRow, { key: `${layer.id}_${i}` },
                    React.createElement(NumberInput, { bg: i % 2 === 0 ? 'white' : 'grey', alignment: "right", step: 0.1, min: 0, layout: "soil-table", value: layer.saturatedDensity, onChange: (e, event) => changeHandler(event, (e) => update(i, 'saturatedDensity', e.currentTarget.value)), onFocus: () => toggleLocked(true), onBlur: () => toggleLocked(false), onClick: determineClick }))))),
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.SmallHeader, null,
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.anglefrictionlabel'] || 'Angle of friction, φ',
                            } })),
                    React.createElement(S.SmallHeader, null,
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.anglefrictionunits'] || '°',
                            } }))),
                layers.map((layer, i) => (React.createElement(S.FieldRow, { key: `${layer.id}_${i}` },
                    React.createElement(NumberInput, { bg: i % 2 === 0 ? 'white' : 'grey', alignment: "right", step: 0.1, min: 0, layout: "soil-table", value: layer.angleOfFriction, onChange: (e, event) => changeHandler(event, (e) => update(i, 'angleOfFriction', e.currentTarget.value)), onFocus: () => toggleLocked(true), onBlur: () => toggleLocked(false), onClick: determineClick }))))),
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.SmallHeader, null,
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.wallfrictionlabel'] || 'Wall Friction Factor',
                            } })),
                    React.createElement(S.SmallHeader, null,
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.wallfrictionunits'] || 'tan𝛿 / tanφ',
                            } }))),
                layers.map((layer, i) => (React.createElement(S.FieldRow, { key: `${layer.id}_${i}` },
                    React.createElement(NumberInput, { bg: i % 2 === 0 ? 'white' : 'grey', alignment: "right", step: 0.1, min: 0, layout: "soil-table", value: layer.wallFrictionFactor, onChange: (e, event) => changeHandler(event, (e) => update(i, 'wallFrictionFactor', e.currentTarget.value)), onFocus: () => toggleLocked(true), onBlur: () => toggleLocked(false), onClick: determineClick }))))),
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.SmallHeader, null,
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.soilcohesionlabel'] ||
                                    'Soil cohesion, c or c<sub>u</sub>',
                            } })),
                    React.createElement(S.SmallHeader, null,
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.soilcohesionunits'] || 'kN/m<sup>2</sup>',
                            } }))),
                layers.map((layer, i) => (React.createElement(S.FieldRow, { key: `${layer.id}_${i}` },
                    React.createElement(NumberInput, { bg: i % 2 === 0 ? 'white' : 'grey', alignment: "right", step: 0.1, min: 0, layout: "soil-table", value: layer.soilCohesion, onChange: (e, event) => changeHandler(event, (e) => update(i, 'soilCohesion', e.currentTarget.value)), onFocus: () => toggleLocked(true), onBlur: () => toggleLocked(false), onClick: determineClick }))))),
            React.createElement(S.ColumnWrapper, null,
                React.createElement(S.HeaderWrapper, null,
                    React.createElement(S.SmallHeader, null,
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.walladhesionlabel'] || 'Wall adhesion factor',
                            } })),
                    React.createElement(S.SmallHeader, null,
                        React.createElement(S.HeaderInner, { dangerouslySetInnerHTML: {
                                __html: labels['ysp.soilprofile.table.walladhesionunits'] ||
                                    'a<sub>w</sub>/c<sub>w</sub>',
                            } }))),
                layers.map((layer, i) => (React.createElement(S.LastRowWrapper, { key: `${layer.id}_${i}` },
                    React.createElement(S.FieldRow, null,
                        React.createElement(NumberInput, { bg: i % 2 === 0 ? 'white' : 'grey', alignment: "right", step: 0.1, min: 0, layout: "soil-table", value: layer.wallAdhesion, onChange: (e, event) => changeHandler(event, (e) => update(i, 'wallAdhesion', e.currentTarget.value)), onFocus: () => toggleLocked(true), onBlur: () => toggleLocked(false), onClick: determineClick })),
                    React.createElement(S.Reset, { key: `Reset_${i}`, title: 'Reset this row to default values?', onClick: () => {
                            setTargetedLayer(i);
                        } },
                        React.createElement(Reset, null)))))))));
};
export default SoilInfoTable;
