import React, { useEffect } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import Radios from '@ys/Components/Steps/Controls/Radios/Radios';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import LooseGround from '@img/ysp/materials/loose-ground.png';
import SoftClay from '@img/ysp/materials/soft-clay.png';
import LooseSand from '@img/ysp/materials/loose-sand.png';
import Peat from '@img/ysp/materials/peat.png';
import Silt from '@img/ysp/materials/silt.png';
import LooseGravel from '@img/ysp/materials/loose-gravel.png';
const ProblematicGround = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    useEffect(() => {
        //
        dispatch({
            type: 'set-footer',
            payload: {
                type: 'conditions',
                beforeNext: () => dispatch({ type: 'validate-problematic-ground' }),
            },
        });
    }, []);
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            React.createElement("h2", null, state.labels['heading_problematic']),
            React.createElement(FormStyles.FormRow, { xCentered: true },
                React.createElement(Radios, { value: state.form.problematicGround, error: state.errors['problematicGround'], setValue: (value) => setFormField('problematicGround', value) })),
            React.createElement(SharedStyles.Materials, null,
                React.createElement(SharedStyles.Material, null,
                    React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${LooseGround}')` } }),
                    React.createElement(SharedStyles.MaterialLabel, null, "Very Loose or Loose MADE GROUND")),
                React.createElement(SharedStyles.Material, null,
                    React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${SoftClay}')` } }),
                    React.createElement(SharedStyles.MaterialLabel, null, "Very Soft or Soft CLAY")),
                React.createElement(SharedStyles.Material, null,
                    React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${LooseSand}')` } }),
                    React.createElement(SharedStyles.MaterialLabel, null, "Very Loose or Loose SAND")),
                React.createElement(SharedStyles.Material, null,
                    React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${Peat}')` } }),
                    React.createElement(SharedStyles.MaterialLabel, null, "PEAT")),
                React.createElement(SharedStyles.Material, null,
                    React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${Silt}')` } }),
                    React.createElement(SharedStyles.MaterialLabel, null, "SILT")),
                React.createElement(SharedStyles.Material, null,
                    React.createElement(SharedStyles.MaterialMedia, { style: { backgroundImage: `url('${LooseGravel}')` } }),
                    React.createElement(SharedStyles.MaterialLabel, null, "Very Loose GRAVEL"))))));
};
export default ProblematicGround;
