import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
import { WYSIWYGStyles } from '../../../Shared.styles';
const Container = styled.div `
  display: grid;
  grid-template-columns: repeat(9, 1fr); // Description spans 2 so add an extra one //

  @media ${until(Device.TabletLarge)} {
    overflow-x: auto;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: ${brand.grey.grey89};
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: ${brand.primary.light};
    }

    ${() => ColumnWrapper} {
      min-width: 120px;
    }

    ${() => WideColumnWrapper} {
      min-width: 200px;
    }
  }
`;
const ColumnWrapper = styled.div `
  display: flex;
  flex-direction: column;
  height: max-content;
  max-height: 100%;
  grid-column: span 1;
`;
const WideColumnWrapper = styled(ColumnWrapper) `
  grid-column: span 2;
`;
const HeaderWrapper = styled.div `
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  min-height: 107px;

  ${({ last }) => last &&
    css `
      max-height: 78px;
    `};
`;
const Header = styled.div `
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${brand.white};
  background-color: ${brand.primary.alt};
  font-size: 14px;
  color: ${brand.white};
  text-align: center;

  sub {
    font-size: 11px;
    position: relative;
    bottom: -3px;
  }

  ${({ bold }) => bold &&
    css `
      ${fonts.DaxPro.Medium};
    `};
`;
const HeaderInner = styled.div ``;
const SmallHeader = styled(Header) `
  grid-row: span 1;
`;
const LargeHeader = styled(Header) `
  grid-row: span 2;
`;
const FieldRow = styled.div `
  width: 100%;
  border: 1px solid ${brand.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FieldInput = styled.input `
  padding: 6px;
  border: none;
  width: 100%;
  position: relative;
  transition: outline-color 0.1s ease-in-out;
  outline: 2px solid transparent;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin-left: 5px;
  }

  &::selection {
    color: ${brand.white};
    background-color: ${brand.divisionTags.sandhurst};
  }

  &:focus {
    outline-color: ${brand.divisionTags.sandhurst};
  }

  ${({ align }) => {
    switch (align) {
        default:
        case 'left':
            return css `
          text-align: start;
        `;
        case 'center':
            return css `
          text-align: center;
        `;
        case 'right':
            return css `
          text-align: end;
        `;
    }
}}

  ${({ bg }) => {
    switch (bg) {
        default:
        case 'green':
            return css `
          background-color: ${brand.ysp.input};
        `;
        case 'white':
            return css `
          background-color: ${brand.white};
        `;
        case 'grey':
            return css `
          background-color: ${brand.grey.grey89};
        `;
    }
}}
`;
const TopSection = styled.div `
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 25px;
  margin-top: 35px;
`;
const Title = styled.div `
  ${fonts.DaxPro.Bold};
  font-size: 23px;
`;
const Disclaimer = styled.div `
  font-size: 20px;
  line-height: 22px;

  p:last-child {
    margin-bottom: 0;
  }
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const Modal = styled(m.div).attrs(({ active }) => ({
    initial: 'hidden',
    animate: active ? 'visible' : 'hidden',
    exit: 'hidden',
    variants: {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
        },
    },
})) `
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ active }) => !active &&
    css `
      pointer-events: none;
    `}
`;
const ModalInner = styled(m.div).attrs(() => ({
    variants: {
        hidden: {
            y: 50,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
        },
    },
    transition: {
        duration: 0.5,
    },
})) `
  ${WYSIWYGStyles}
  position: relative;
  margin-top: 20px; // For the close button
  background-color: ${brand.white};
  border-top: 4px solid ${brand.divisionTags.sandhurst};
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  max-height: 400px;
  gap: 15px;
  width: 100%;
`;
const ModalTitle = styled.div `
  ${fonts.DaxPro.Medium};
  font-size: 18px;
`;
const ModalText = styled.div `
  ${fonts.DaxPro.Light};
  font-size: 16px;
  line-height: 20px;
  overflow-y: auto;
  padding-right: 5px;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${brand.white};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${brand.primary.light};
    border-radius: 10px;
  }
`;
const Close = styled.div `
  position: absolute;
  right: 0;
  top: -24px;
  display: flex;
  cursor: pointer;

  svg {
    color: ${brand.white};
    width: 15px;
    height: 15px;

    g {
      stroke-width: 4px;
      opacity: 1;
    }
  }
`;
const BlankRows = styled.div `
  grid-row: span 2;
  border: 1px solid ${brand.white};

  @media ${until(Device.TabletLarge)} {
    max-height: 80px;
  }
`;
const Reset = styled.div `
  display: flex;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 100%;
  width: 25px;

  @media ${from(Device.TabletLarge)} {
    opacity: 0.5;
    transition: opacity 0.25s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  @media ${until(Device.TabletLarge)} {
    height: 30px;
    width: max-content;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;
const LastRowWrapper = styled.div `
  position: relative;
`;
export const SoilInfoTableStyles = {
    Container,
    ColumnWrapper,
    HeaderWrapper,
    Header,
    SmallHeader,
    LargeHeader,
    WideColumnWrapper,
    HeaderInner,
    FieldRow,
    FieldInput,
    TopSection,
    Title,
    Wrapper,
    Disclaimer,
    Modal,
    ModalInner,
    ModalTitle,
    ModalText,
    Close,
    Reset,
    BlankRows,
    LastRowWrapper,
};
