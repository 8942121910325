import { SolutionSubType } from '../../Context/types';
export const dummyData = {
    depth: 2,
    width: 3,
    length: 3,
    subType: SolutionSubType.None,
    groundwaterDepth: 1.5,
    name: 'jakestringer',
    email: 'JakeStringer@netconstruct.co.uk',
    telephone: '07111111111',
    companyName: 'NetConstruct',
    acceptedTs: true,
    schemeName: '12',
    siteName: '12',
    recaptchaToken: '03AFY_a8V5nqCVQ0sT6Rf9SIsbL58nOl8a-_qXRiKFWaT9dGT2Vp4lvs8q4ZkrIZbeAI72eUlN4YjRPfj76Ctk5qCEv5lG9nop4u6aIxbgU-k-wkRsgGEFw_aIO8Nk-HsV9CtKuDOiBJXXg6_DLFUlbJUWkB5px7-0l2tCDRSyTVB0UPzYCCfYNXUba_URwJX3EVzWoaCM_VQkQjhhIX0sxsgURTKPUms0rdkVLbfU7nMRoRZi2nDHJcu8zw43CSEkCGD_aXHxog_Mdeu-yQPip9CMcoVaBKWoNYn5JRDqqMUQ3b7iIOu63oxUddkkNaVbWfhm3NVf1VV7oBfzg1CWMgHdqKD5fNP2CzwTv3o-hzsbQ3YBnwul65du-oC14dDwdt5luZD3LuOVBPBqMCSTeegdBRgE0Q72bl3Bcru53xuSDlW5cBunSgYNdcxjku_TSC3UfaROiT5NmtXhdQVynni9hsUeoX1nacV9FLTF-eUs4_fEC6CHppYhB63f5GKCmUoxKPzrGFQV3ywiwcFTKz_asgM--QfMy_KQuIAthBY1vbJJHtdJMfY',
    postCode: 'ls15 7qp',
    endSafePanelsRequired: false,
    acceptedSoilModification: false,
    soilLayers: [
        {
            id: '3633',
            soilTypeId: 4,
            bgl: 0,
            description: 'Silt (Firm)',
            soilDensity: 19,
            saturatedDensity: 19,
            angleOfFriction: 28,
            wallFrictionFactor: 0,
            soilCohesion: 0,
            wallAdhesion: 0,
        },
        {
            id: '3889',
            soilTypeId: 2,
            bgl: 0.25,
            description: 'Road Construction',
            soilDensity: 21,
            saturatedDensity: 21,
            angleOfFriction: 40,
            wallFrictionFactor: 0,
            soilCohesion: 0,
            wallAdhesion: 0,
        },
        {
            id: '3889',
            soilTypeId: 2,
            bgl: 0.75,
            description: 'Peat',
            soilDensity: 21,
            saturatedDensity: 21,
            angleOfFriction: 40,
            wallFrictionFactor: 0,
            soilCohesion: 0,
            wallAdhesion: 0,
        },
        {
            id: '3835',
            soilTypeId: 2,
            bgl: 1.25,
            description: 'Clay (Soft)',
            soilDensity: 21,
            saturatedDensity: 21,
            angleOfFriction: 40,
            wallFrictionFactor: 0,
            soilCohesion: 0,
            wallAdhesion: 0,
        },
        {
            id: '3835',
            soilTypeId: 2,
            bgl: 1.47,
            description: 'Clay (Hard)',
            soilDensity: 21,
            saturatedDensity: 21,
            angleOfFriction: 40,
            wallFrictionFactor: 0,
            soilCohesion: 0,
            wallAdhesion: 0,
        },
        {
            id: '3890',
            soilTypeId: 5,
            bgl: 3,
            description: 'Ash',
            soilDensity: 21,
            saturatedDensity: 21,
            angleOfFriction: 40,
            wallFrictionFactor: 0,
            soilCohesion: 0,
            wallAdhesion: 0,
        },
    ],
    soilDisclaimer: true,
    soilInfoSource: 0,
    soilInfoSourceRef: '2',
    soilInfoSourceCompany: '2',
    groundwaterPresent: true,
    largeSurcharges: true,
    surchargeProfiles: [
        {
            surchargeDescription: 'Moving Plant up to 45Te',
            surchargeIcon: 2,
            surchargeSource: 2,
            surchargeType: 0,
            id: 1,
        },
    ],
    cultureCode: 'en-GB',
};
