import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import styled from 'styled-components';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 17px;

  p {
    margin-bottom: 10px;
    color: ${brand.black}
  }

  label {
    ${fonts.DaxPro.Medium};
  }

  textarea {
    ${fonts.DaxPro.Regular};
    font-size: 17px;
  }

  small {
    font-size: 13px;
  }
`;
const Heading = styled.h3 `
  color: ${brand.primary.alt};
  font-size: 30px;
  font-weight: bold;
`;
const SuccessText = styled.div `
  color: ${brand.black};
`;
export const EngineerCheckStyles = {
    Container,
    SuccessText,
    Heading,
};
