import React, { useEffect, useState } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SharedStyles } from '@ys/Components/Shared.styles';
import Radios from '@ys/Components/Steps/Controls/Radios/Radios';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import InfoIcon from '@ys/Components/InfoIcon/InfoIcon';
import { Tab } from '@stories/Widgets/YourSolution/YS/Context/types';
import { GroundwaterStyles as S } from './Groundwater.styles';
import { ReactComponent as Ground } from '@img/ysp/groundwater-ground.svg';
import { ReactComponent as Water } from '@img/ysp/groundwater-water.svg';
import { withMotion } from '@hoc/withMotion';
const Groundwater = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    const [hasAnimatedIn, setHasAnimatedIn] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setHasAnimatedIn(true);
        }, 5000);
    }, []);
    useEffect(() => {
        dispatch({
            type: 'set-footer',
            payload: {
                type: 'conditions',
                beforeNext: () => dispatch({ type: 'validate-groundwater' }),
            },
        });
    }, []);
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            state.tab === Tab.YourSolutionPlus ? (React.createElement(SharedStyles.TitleWithInfoIcon, null,
                React.createElement("h2", null, state.labels['heading']),
                state.labels['tooltip'] && (React.createElement(InfoIcon, { onClick: () => dispatch({
                        type: 'set-modal',
                        payload: {
                            description: state.labels['tooltip'],
                            fullWidth: true,
                            size: 'normal',
                        },
                    }) })))) : (React.createElement("h2", null, state.labels['heading_groundWater'])),
            React.createElement(S.Container, null,
                React.createElement(S.Ground, null,
                    React.createElement(Ground, null)),
                React.createElement(S.Water, { height: state.form.depth || 1.5, hasAnimatedIn: hasAnimatedIn },
                    React.createElement(Water, null)),
                React.createElement(S.ExcavationOverlay, { height: state.form.depth || 1.5 },
                    React.createElement(S.Arrow, null),
                    React.createElement("span", null,
                        state.form.depth,
                        "m"))),
            React.createElement(FormStyles.FormRow, { xCentered: true },
                React.createElement(Radios, { error: state.errors['groundwaterPresent'], value: state.form.groundwaterPresent, setValue: (value) => setFormField('groundwaterPresent', value) })))));
};
export default withMotion(Groundwater);
