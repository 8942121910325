import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, until } from '@helpers/media';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
const Container = styled.div `
  grid-column: 1 / 6;
  overflow-y: auto;
  height: 270px;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${brand.white};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${brand.primary.light};
    border-radius: 10px;
  }
`;
const SoilVisualiser = styled(m.div) `
  background-color: powderblue;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${brand.white};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${brand.primary.light};
    border-radius: 10px;
  }

  @media ${until(Device.TabletLarge)} {
    overflow-y: auto;
  }
`;
const ExcavationOverlay = styled.div `
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 75px;
  height: ${({ height }) => `${height}%`};
  background-color: ${brand.white};
  border: 2px solid ${brand.black};
  border-top: none;
  padding: 5px 7px;
  z-index: 1;

  display: flex;
  align-items: center;
  gap: 5px;

  span {
    color: ${brand.primary.alt};
    ${fonts.DaxPro.Medium};
    font-size: 13px;
  }
`;
const Arrow = styled.div `
  position: relative;
  height: calc(100% - 5px);
  width: 1px;
  background-color: ${brand.primary.alt};

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 35%;
    transform: translateX(-50%);
    background-color: ${brand.primary.alt};
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    width: 7px;
    height: 7px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 35%;
    transform: translateX(-50%);
    background-color: ${brand.primary.alt};
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    width: 7px;
    height: 7px;
  }
`;
const SoilLayer = styled(m.div).attrs(({ height }) => ({
    initial: 'hidden',
    animate: height === 0 ? 'hidden' : 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            height: 0,
            minHeight: 0,
        },
        visible: {
            height: `${height}px`,
            minHeight: `${height}px`,
        },
    },
    transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.35,
    },
})) `
  position: relative;
  width: 100%;
  background-image: ${({ background }) => `url('${background}')`};
  transform-origin: bottom;

  ${({ isLast, height }) => height === -1 && isLast
    ? css `
          flex-grow: 1;
        `
    : null}

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
`;
export const SoilVisualiserStyles = {
    Container,
    SoilVisualiser,
    ExcavationOverlay,
    Arrow,
    SoilLayer,
};
