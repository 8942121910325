import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import { m } from 'framer-motion';
import styled from 'styled-components';
import { DropdownStyles } from '../../Controls/Dropdown/Dropdown.styles';
import { DimensionsStyles } from '../../Dimensions/Dimensions.styles';
import { SurchargeIcon } from './SurchargeDetails';
import brand from '@helpers/brand';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  @media ${until(Device.TabletLarge)} {
    ${() => DropdownStyles.Container} {
      grid-column: 1 / -1;

      @media ${until(Device.TabletLarge)} {
        width: 100%;
      }
    }
  }
`;
const TopStep = styled.div `
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${from(Device.TabletLarge)} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
`;
const DimensionsWrapper = styled(DimensionsStyles.Container) `
  grid-column: 1 / 5;
  width: 100%;
`;
const SurchargesWrapper = styled.div `
  grid-column: 5 / -1;
  display: flex;
  flex-direction: column;
`;
const IconContainer = styled.div `
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 45px;

  @media ${until(Device.TabletLarge)} {
    min-height: 40px;
    grid-row: 1;
    grid-column: 2;
  }
`;
const IconWrapper = styled.div ``;
const SurchargeWrapper = styled.div `
  @media ${until(Device.TabletLarge)} {
    display: flex;
    flex-wrap: wrap;
  }

  @media ${from(Device.TabletLarge)} {
    display: grid;
  }

  gap: 5px 10px;
  align-items: center;
  margin-bottom: 15px;

  &[data-last='true'] {
    ${DropdownStyles.Container} {
      background-color: ${brand.ysp.input};
    }
  }

  @media ${from(Device.TabletLarge)} {
    gap: 15px;
    grid-template-columns: 45px 100px 1fr 13px;
  }
`;
const SurchargeName = styled.div `
  ${fonts.DaxPro.Medium};
  font-size: 17px;

  @media ${until(Device.TabletLarge)} {
    order: -1;
    width: 100px;
  }
`;
const getIconAnimation = (i) => {
    switch (i) {
        default:
        case SurchargeIcon.None:
            return 'default';
        case SurchargeIcon.Building:
            return 'building';
        case SurchargeIcon.Rail:
            return 'rail';
        case SurchargeIcon.Slope:
            return 'embankments';
        case SurchargeIcon.Road:
            return 'road';
    }
};
const BaseHidden = {
    opacity: 0,
    transition: {
        opacity: {
            delay: 0.5,
        },
    },
};
const BaseVisible = {
    opacity: 1,
};
const MotionWrapper = styled(m.div).attrs(({ icon }) => ({
    initial: `${getIconAnimation(icon)}-hidden`,
    animate: `${getIconAnimation(icon)}-visible`,
    exit: `${getIconAnimation(icon)}-exit`,
    variants: {
        'default-hidden': {
            ...BaseHidden,
            x: -50,
        },
        'default-visible': {
            ...BaseVisible,
            x: 0,
        },
        'default-exit': {
            ...BaseVisible,
            x: 50,
        },
        'building-hidden': {
            ...BaseHidden,
            y: 50,
        },
        'building-visible': {
            ...BaseVisible,
            y: 0,
        },
        'building-exit': {
            ...BaseVisible,
            y: 50,
        },
        'embankments-hidden': {
            ...BaseHidden,
            y: -50,
        },
        'embankments-visible': {
            ...BaseVisible,
            y: 0,
        },
        'embankments-exit': {
            ...BaseVisible,
            y: 50,
        },
        'rail-hidden': {
            ...BaseHidden,
            scale: 0,
        },
        'rail-visible': {
            ...BaseVisible,
            scale: 1,
        },
        'rail-exit': {
            ...BaseVisible,
            scale: 0,
        },
        'road-hidden': {
            ...BaseHidden,
            y: -50,
            x: -50,
        },
        'road-visible': {
            ...BaseVisible,
            y: 0,
            x: 0,
        },
        'road-exit': {
            ...BaseVisible,
            y: 50,
            x: 50,
        },
    },
    transition: {
        duration: 1,
    },
})) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 45px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 45px;
  }
`;
const ButtonWrapper = styled.div `
  @media ${from(Device.TabletLarge)} {
    padding-top: 25px;
    padding-left: 60px; // To offset the first grid column of the surcharges which houses the icon //
  }
`;
const DeleteWrapper = styled.div `
  cursor: pointer;

  @media ${until(Device.TabletLarge)} {
    grid-row: 1;
    grid-column: 4;
  }
`;
export const SurchargeDetailsStyles = {
    Container,
    TopStep,
    DimensionsWrapper,
    SurchargesWrapper,
    SurchargeWrapper,
    SurchargeName,
    IconWrapper,
    IconContainer,
    MotionWrapper,
    ButtonWrapper,
    DeleteWrapper,
};
