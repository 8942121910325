import { HydrateOption } from '@core/enums';
import { isSSR } from '@helpers/ssr';
import withWidget from '@hoc/withWidget';
import { YSContextProvider } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import React from 'react';
import YourSolution from './YourSolution';
const YourSolutionRoot = (props) => {
    return (React.createElement(YSContextProvider, { ...props },
        React.createElement(YourSolution, { closeModal: () => !isSSR() && window.history.back(), ...props })));
};
export default withWidget(YourSolutionRoot, 'YourSolutionRoot', { hydrate: HydrateOption.Always });
