import React from "react";
import styled, { css } from "styled-components";
import { Device, from, until } from "./media";
const MediaComponentStyles = styled.div `
  display: contents;

  ${({ $mobile: mobile }) => mobile &&
    css `
      @media ${from(Device.TabletLarge)} {
        display: none;
      }
    `}

  ${({ $desktop: desktop }) => desktop &&
    css `
      @media ${until(Device.TabletLarge)} {
        display: none;
      }
    `}
`;
export const MediaComponent = ({ children, ...rest }) => {
    return (React.createElement(MediaComponentStyles, { "$desktop": rest.desktop, "$mobile": rest.mobile }, children));
};
