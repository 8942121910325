import React, { useRef, useState } from 'react';
import { SoilTypeDropdownStyles as S } from './SoilTypeDropdown.styles';
import { ReactComponent as Chevron } from '@img/icons/chevron-down.svg';
import uniqueId from 'lodash/uniqueId';
import { useClickAway } from 'react-use';
const SoilTypeDropdown = ({ value, options, onChange, i, }) => {
    const [expanded, setExpanded] = useState(false);
    const ref = useRef();
    useClickAway(ref, () => expanded && setExpanded(false), ['click']);
    return (React.createElement(S.Container, null,
        React.createElement(S.TopSection, { onClick: () => !expanded && setExpanded(true) },
            !value ? (React.createElement("span", null, "Please Select Soil Type")) : (React.createElement(S.SelectedOption, null,
                React.createElement(S.SoilImage, { background: value.icon }),
                React.createElement("span", null, value.name))),
            React.createElement(Chevron, null)),
        React.createElement(S.DropdownWrapper, { i: i, ref: ref, active: expanded },
            React.createElement(S.BorderWrapper, null,
                React.createElement(S.Dropdown, null, options.map((option, i) => (React.createElement(S.DropdownOption, { key: `SoilTypeDropdown_${i}_${uniqueId()}`, onClick: () => {
                        onChange(option);
                        setExpanded(false);
                    } },
                    React.createElement(S.SoilImage, { background: option.icon }),
                    React.createElement("span", null, option.name)))))))));
};
export default SoilTypeDropdown;
