import brand from '@helpers/brand';
import styled from 'styled-components';
const Container = styled.div `
  background-color: ${brand.white};
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  canvas {
    margin: auto;
    max-height: 600px;
  }
`;
export const ChartStyles = {
    Container,
};
