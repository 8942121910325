import { withMotion } from '@hoc/withMotion';
import React from 'react';
import { useYSPContext } from '../../Context/YSContext';
import { Tab } from '../../Context/types';
import { LoadingStyles as S } from './Loading.styles';
const Loading = () => {
    const { state } = useYSPContext();
    return (React.createElement(S.Container, null,
        React.createElement(S.TopText, null, "Loading"),
        React.createElement(S.FadeTextWrapper, null,
            'Your'.split('').map((x, i) => (React.createElement(S.Letter, { colour: "grey", key: `Your_${i}`, i: i }, x))),
            (state.tab === Tab.YourSolution ? 'Solution' : 'Solution+').split('').map((x, i) => (React.createElement(S.Letter, { last: state.tab === Tab.YourSolutionPlus && x === '+', colour: "green", key: `Solution_${i}`, i: i + 4 }, x))))));
};
export default withMotion(Loading);
