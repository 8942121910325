import React, { useMemo } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { StepHeaderStyles as S } from './StepHeader.styles';
import { AnimatePresence } from 'framer-motion';
const StepHeader = () => {
    const { state } = useYSPContext();
    const stepsWithTitle = useMemo(() => {
        return state.steps.filter((x) => x.title && x.title !== 'Home');
    }, [state.steps]);
    return (React.createElement(S.Container, null,
        stepsWithTitle.map((x, i) => (React.createElement(S.Step, { key: x.id, hideEnd: i === stepsWithTitle.length - 1 }))),
        React.createElement(AnimatePresence, null,
            React.createElement(S.StepBackground, { steps: stepsWithTitle.length, step: state.progress + 1 }))));
};
export default StepHeader;
