import ApiService from '@core/api/services/ApiService';
import React, { useState } from 'react';
import { useYSPContext } from '../../../Context/YSContext';
import { Button } from '../../StepFooter/StepFooter.styles';
import { EngineerCheckStyles as S } from './EngineerCheck.styles';
const EngineerCheck = (props) => {
    const [comment, setComment] = useState();
    const [file, setFile] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState();
    const { state: { selectedSolution }, } = useYSPContext();
    const handleSubmit = async () => {
        if (selectedSolution) {
            setSubmitting(true);
            const response = await ApiService.request({
                controller: 'YSP',
                slug: 'engineer',
                method: 'POST',
                isMultipartFormData: true,
                files: file ? [file] : null,
                params: {
                    comment,
                    designRef: selectedSolution.designReference,
                },
            });
            setSuccess(response.result);
            setSubmitting(false);
        }
    };
    return (React.createElement(S.Container, null, success === true ? (React.createElement(S.SuccessText, { dangerouslySetInnerHTML: { __html: props.labels['engineer.modal.success'] } })) : (React.createElement(React.Fragment, null,
        React.createElement(S.Heading, null, props.labels['engineer.modal.heading']),
        React.createElement("div", { dangerouslySetInnerHTML: { __html: props.labels['engineer.modal.content'] } }),
        React.createElement("label", { htmlFor: "engineercheck-comment" }, "Comments*:"),
        React.createElement("textarea", { value: comment, onChange: (e) => setComment(e.currentTarget.value), id: "engineercheck-comment", rows: 4 }),
        React.createElement("br", null),
        React.createElement("label", { htmlFor: "engineercheck-file" }, "File:"),
        React.createElement("input", { onChange: (e) => e.currentTarget.files && setFile(e.currentTarget.files[0]), type: "file", id: "engineercheck-file", accept: ".pdf, .docx" }),
        React.createElement("small", null, "Please upload a PDF or Word document (.docx)."),
        React.createElement("hr", null),
        React.createElement(Button, { disabled: submitting || !comment, onClick: () => handleSubmit() }, "Submit")))));
};
export default EngineerCheck;
