import React, { useMemo } from 'react';
import { GraphStyles as S } from './Graph.styles';
import Decimal from 'decimal.js-light';
// import { frozenState } from './Graph.data';
import { imageUrl } from '@helpers/cloudinary';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
const Graph = () => {
    const { state } = useYSPContext();
    if (!state.form.soilLayers || !state.form.depth || !state.selectedSolution) {
        return React.createElement(React.Fragment, null);
    }
    const contentOffset = useMemo(() => {
        if (state.form.depth && state.selectedSolution) {
            return 100 - (state.form.depth / state.selectedSolution.solution.height) * 100;
        }
        return 0;
    }, [state.selectedSolution.solution.height - state.form.depth, state.form.depth]);
    // If you're at this point, these values are populated. I don't want to have to do 5 inline checks for undefined stuff just to shut eslint up //
    // when it's never going to cause an issue cos it'll never be undefined. Just casting them here to Required and moving on //
    const soilLayers = state.form.soilLayers;
    const form = state.form;
    return (React.createElement(S.Container, null,
        React.createElement(S.GraphWrapper, null,
            React.createElement(S.Title, null, state.selectedSolution.graphs.boxHeading),
            React.createElement(S.GraphContainer, null,
                React.createElement(S.LeftContent, { offset: contentOffset },
                    React.createElement(S.BGLLabel, { side: "left" }, "m BGL"),
                    React.createElement(S.Label, { x: "left", y: "top" }, "0.00"),
                    state.form.groundwaterPresent &&
                        state.form.groundwaterDepth &&
                        state.form.groundwaterDepth <= state.form.depth && (React.createElement(S.GroundwaterLevel, { top: (state.form.groundwaterDepth / state.form.depth) * 100 })),
                    React.createElement(S.Label, { x: "left", y: "bottom" }, new Decimal(state.form.depth || 2).toDecimalPlaces(2).toString())),
                React.createElement(S.Box, null,
                    React.createElement("img", { src: state.selectedSolution.solution.graphic }),
                    React.createElement("img", { src: state.selectedSolution.solution.graphic })),
                React.createElement(S.RightContent, { offset: contentOffset },
                    React.createElement(S.BGLLabel, { side: "right" }, "m BGL"),
                    React.createElement(S.Label, { x: "right", y: "top" }, "0.00"),
                    state.soilProfiles && soilLayers.length > 0 && (React.createElement(S.SoilContainer, null, soilLayers
                        .filter((x) => x.bgl <= form.depth)
                        .map((layer, i) => {
                        // This is horrible, TODO to fix it //
                        const height = i < soilLayers.filter((x) => x.bgl <= form.depth).length - 1
                            ? (soilLayers[i + 1].bgl / form.depth) * 100 -
                                (layer.bgl / form.depth) * 100
                            : 100 - (layer.bgl / form.depth) * 100;
                        return (React.createElement(S.SoilLayer, { title: layer.description, height: height, top: (layer.bgl / form.depth) * 100, key: `SoilLayer_${i}`, image: imageUrl(layer.image, 'c_fill,w_35') },
                            React.createElement("span", null, layer.description)));
                    }))),
                    React.createElement(S.Label, { x: "right", y: "bottom" }, new Decimal(state.form.depth || 2).toDecimalPlaces(2).toString()))))));
};
export default Graph;
