import { ReactComponent as Chevron } from '@img/icons/chevron-down.svg';
import { ReactComponent as Tick } from '@img/ysp/tick-unbound.svg';
import { ReactComponent as Warning } from '@img/ysp/warning-unbound.svg';
import uniqueId from 'lodash/uniqueId';
import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { DropdownStyles as S } from './Dropdown.styles';
const Dropdown = ({ value, options, onChange, i, short, placeholder, }) => {
    const [expanded, setExpanded] = useState(false);
    const ref = useRef(null);
    useClickAway(ref, () => expanded && setExpanded(false), ['click']);
    return (React.createElement(S.Container, { i: i, short: short },
        React.createElement(S.TopSection, { onClick: () => !expanded && setExpanded(true) },
            value < 0 || value === undefined ? (React.createElement("span", null, placeholder ? placeholder : 'Please Select....')) : (React.createElement(S.SelectedOption, null,
                React.createElement("span", null, options.find((x) => x.id === value)?.text))),
            React.createElement(Chevron, null)),
        React.createElement(S.DropdownWrapper, { ref: ref, active: expanded },
            React.createElement(S.BorderWrapper, null,
                React.createElement(S.Dropdown, null, options.map((option, i) => (React.createElement(S.DropdownOption, { key: `Dropdown_${i}_${uniqueId()}`, onClick: () => {
                        onChange(option.id);
                        setExpanded(false);
                    } },
                    option.selectable !== null && (React.createElement("span", null,
                        option.selectable && React.createElement(Tick, null),
                        " ",
                        option.selectable === false && React.createElement(Warning, null))),
                    React.createElement("span", null, option.text)))))))));
};
export default Dropdown;
