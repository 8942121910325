import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { Chart as ChartJS, LineElement, LinearScale, PointElement } from 'chart.js';
import React, { useMemo } from 'react';
import { Scatter } from 'react-chartjs-2';
import { GraphStyles } from '../Graph/Graph.styles';
import { ChartStyles as S } from './Chart.styles';
ChartJS.register([LinearScale, PointElement, LineElement]);
const Chart = ({ chart, title }) => {
    const { state } = useYSPContext();
    const chartData = useMemo(() => {
        return chart.values.map((values) => ({
            x: values[1],
            y: -values[0],
        }));
    }, [state.form.depth]);
    return (React.createElement(S.Container, null,
        React.createElement(GraphStyles.Title, null, title),
        React.createElement(Scatter, { height: 600, width: 440, options: {
                responsive: true,
                animation: false,
                layout: {
                    padding: {
                        left: 10,
                    },
                },
                scales: {
                    y: {
                        position: {
                            x: 0,
                        },
                    },
                    x: {
                        position: {
                            y: 0,
                        },
                    },
                },
            }, data: {
                datasets: [
                    {
                        showLine: true,
                        pointStyle: false,
                        borderColor: chart.colorHex,
                        borderWidth: 2,
                        data: chartData,
                    },
                ],
            } })));
};
export default Chart;
