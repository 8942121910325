import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
import { StepFooterStyles } from '../../../../StepFooter/StepFooter.styles';
const Container = styled(m.div).attrs(({ visible }) => ({
    initial: 'hidden',
    animate: visible ? 'visible' : 'hidden',
    variants: {
        hidden: {
            opacity: 0,
            y: 25,
        },
        visible: {
            opacity: 1,
            y: 0,
        },
    },
    transition: {
        duration: 0.5,
    },
})) `
  box-shadow: 0px 0px 6px 0px ${brand.grey.grey89};
  background-color: ${brand.white};
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  scroll-margin-top: 25px; // Avoid top being cut off when scrolling to it on the dropdown //

  @media ${until(Device.TabletLarge)} {
    padding-top: 60px;
  }

  border: 2px solid transparent;
  ${({ selected }) => selected &&
    css `
      border-color: ${brand.primary.alt};
    `};

  @media ${from(Device.TabletLarge)} {
    display: grid;
    grid-template-columns: 1fr 350px;
  }

  @media ${from(Device.DesktopLarge)} {
    gap: 100px;
  }

  position: relative;

  max-width: 1300px;
  width: 100%;
  margin: auto;
`;
const LeftSection = styled.div `
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-x: hidden;

  @media ${from(Device.TabletLarge)} {
    gap: 25px;
  }
`;
const RightSection = styled.div `
  padding-top: 70px; // Offset the floating tag //
  display: flex;
  justify-content: center;

  img {
    height: max-content;
  }

  @media ${until(Device.TabletLarge)} {
    display: none;
  }
`;
const Title = styled.div `
  ${fonts.DaxPro.Bold};
  font-size: 25px;
`;
const Description = styled.div `
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  line-height: 18px;

  @media ${from(Device.TabletLarge)} {
    font-size: 17px;
    line-height: 20px;
  }
`;
const SolutionTag = styled.div `
  position: absolute;
  right: 20px;
  top: 0;
  padding: 12px 15px;
  user-select: none;
  border-radius: 0px 0px 10px 10px;
  background-color: ${brand.primary.alt};
  color: ${brand.white};
  ${fonts.DaxPro.Medium};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  gap: 10px;

  @media ${from(Device.TabletLarge)} {
    right: 50px;
  }
`;
const LongSmallButton = styled(StepFooterStyles.ButtonSmall) `
  background-color: ${brand.primary.alt};
  padding: 6px 35px;
  font-size: 18px;
  ${fonts.DaxPro.Bold};
  border: 2px solid transparent;
  transition: border-color 0.15s ease-in-out, background-color 0.25s ease-in-out;
  display: flex;
  gap: 10px;

  ${({ inverse }) => inverse &&
    css `
      border-color: ${brand.primary.alt};
      color: ${brand.primary.alt};
      background-color: ${brand.white};

      }
      
      svg {
        color: ${brand.primary.alt};
        fill: ${brand.primary.alt};
      }
      &:hover {
        color: ${brand.primary.alt} !important;
      }
    `};

  @media ${until(Device.TabletLarge)} {
    justify-content: center;
    padding: 6px 12px;
  }

  align-items: center;

  svg {
    width: 22px;
  }

  ${({ orange }) => orange &&
    css `
      background-color: ${brand.divisionTags.sandhurst};

      &:hover {
        background-color: ${brand.divisionTags.sandhurst} !important;
      }
    `}
`;
const Scrollbars = css `
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${brand.grey.grey89};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${brand.primary.light};
  }
`;
const TableContainer = styled.div `
  display: grid;
  grid-template-columns: repeat(5, 150px);
  --rows: ${({ rows }) => rows};

  @media ${from(Device.TabletLarge)} {
    grid-template-columns: repeat(5, 125px);
  }

  overflow-x: auto;
  padding-bottom: 15px;
  ${Scrollbars}
`;
const Column = styled.div `
  grid-column: span ${({ span }) => span};

  display: grid;
  grid-template-rows: repeat(var(--rows), 40px);

  @media ${until(Device.TabletLarge)} {
    ${({ isPassFailColumn }) => isPassFailColumn &&
    css `
        order: -1;
      `};
  }
`;
const HeaderSection = styled.div `
  // Always spans two //
  grid-row: span 2;

  display: grid;
  grid-template-rows: 1fr 1fr;
`;
const Header = styled.div `
  grid-row: span ${({ span }) => span};
  background-color: ${brand.grey.grey75};
  color: ${brand.black};
  ${fonts.DaxPro.Medium};
  padding: 10px 15px;
  display: flex;
  align-items: center;
  border: 1px solid ${brand.white};
  font-size: 15px;
  position: relative;

  sub {
    font-size: 12px;
    position: relative;
    top: 2px;
    left: 2px;
  }

  ${({ center }) => center &&
    css `
      justify-content: center;
      text-align: center;
    `}
`;
const Cell = styled.div `
  grid-row: span 1;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: ${brand.grey.grey96};
  text-align: center;
  color: ${brand.black};
  border: 1px solid ${brand.white};
  ${fonts.DaxPro.Regular};
  font-size: 17px;
`;
const PassFail = styled(Cell) `
  color: ${brand.white};
  ${fonts.DaxPro.Bold};

  ${({ pass }) => pass
    ? css `
          background-color: ${brand.primary.alt};
        `
    : css `
          background-color: ${brand.divisionTags.sandhurst};
        `}
`;
const TableHeader = styled.div `
  ${fonts.DaxPro.Medium};
  font-size: 17px;
`;
const StrutsTableWrapper = styled.div `
  display: grid;
  grid-template-columns: repeat(10, 50px);
  --rows: ${({ rows }) => rows};

  overflow-x: auto;
  padding-bottom: 15px;
  ${Scrollbars};
`;
const Blank = styled.div `
  grid-row: span 2;

  @media ${until(Device.TabletLarge)} {
    background-color: ${brand.grey.grey75};
    margin: 1px;
  }
`;
const FailText = styled.div `
  ${fonts.DaxPro.Bold};
  color: ${brand.divisionTags.sandhurst};
`;
const FailBoxWrapper = styled.div `
  padding: 15px;
  border: 2px solid ${brand.divisionTags.sandhurst};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const FailTopRow = styled.div `
  display: flex;
  gap: 7px;
`;
const FailNumber = styled.div `
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  user-select: none;
  color: ${brand.divisionTags.sandhurst};
  ${fonts.DaxPro.Bold};
  border: 2px solid ${brand.divisionTags.sandhurst};
`;
const FailDescription = styled.div `
  ${fonts.DaxPro.Regular};
`;
const MobileImage = styled.div `
  @media ${from(Device.TabletLarge)} {
    display: none;
  }
`;
const DescriptionAndTable = styled.div `
  display: flex;
  gap: 10px;

  @media ${until(Device.TabletLarge)} {
    flex-direction: column;
  }
`;
export const SolutionStyles = {
    Container,
    LeftSection,
    Title,
    Description,
    SolutionTag,
    RightSection,
    LongSmallButton,
    TableContainer,
    Column,
    HeaderSection,
    Header,
    Cell,
    PassFail,
    TableHeader,
    StrutsTableWrapper,
    Blank,
    FailText,
    FailBoxWrapper,
    FailTopRow,
    FailNumber,
    FailDescription,
    MobileImage,
    DescriptionAndTable,
};
