import Decimal from 'decimal.js-light';
import React, { useState } from 'react';
import { InlineNumericalInputStyles as S } from './InlineNumericalInput.styles';
const InlineNumericalInput = ({ unit, value, onChange, min, max, disabled, lock, absoluteBounds, }) => {
    const [hasFocus, setHasFocus] = useState(false);
    return (React.createElement(S.Container, { title: disabled
            ? `You cannot change the value of the first entry.\nValues are calculated as 'depth from top' - therefore, the first entry must be 0.`
            : undefined },
        React.createElement(S.Input, { onFocus: () => {
                lock && lock(true);
                setHasFocus(true);
            }, onBlur: () => {
                lock && lock(false);
                setHasFocus(false);
                if (value && !value.toString().includes('.')) {
                    onChange(`${value}.0`);
                }
                else if (value === undefined) {
                    onChange('0.0');
                }
            }, min: min !== undefined ? (absoluteBounds ? min : new Decimal(min).add(0.1).toNumber()) : 0.1, max: max !== undefined
                ? absoluteBounds
                    ? max
                    : new Decimal(max).sub(0.1).toNumber()
                : undefined, step: 0.1, type: "number", onChange: (e) => {
                onChange(e.currentTarget.value);
            }, readOnly: disabled, disabled: disabled, value: disabled
                ? 0
                : !hasFocus
                    ? value && value.toString().includes('.')
                        ? value
                        : `${value}.0`
                    : undefined }),
        React.createElement(S.EndSection, null, unit)));
};
export default InlineNumericalInput;
