import React, { useEffect, useRef, useState } from 'react';
import { ZoomImageStyles as S } from './ZoomImage.styles';
import { ReactComponent as Zoom } from '@img/ysp/zoom.svg';
import { ReactComponent as ZoomOut } from '@img/ysp/zoom-out.svg';
import { ReactComponent as Close } from '@img/ysp/icon-cross.svg';
import { ReactComponent as Reset } from '@img/ysp/restart.svg';
import { AnimatePresence } from 'framer-motion';
import { withMotion } from '@hoc/withMotion';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
const ZoomImage = ({ imageUrl }) => {
    const { dispatch } = useYSPContext();
    const [modalOpen, setModalOpen] = useState(false);
    const zoomRef = useRef(null);
    useEffect(() => {
        dispatch({ type: 'set-zoom-modal', payload: modalOpen });
    }, [modalOpen]);
    return (React.createElement(React.Fragment, null,
        React.createElement(S.Container, { onClick: () => setModalOpen(true) },
            React.createElement(S.Image, { src: imageUrl }),
            React.createElement(S.ZoomIcon, null,
                React.createElement(Zoom, null))),
        React.createElement(AnimatePresence, null,
            React.createElement(S.ModalBackground, { key: `ZoomImageModal`, visible: modalOpen, onClick: () => setModalOpen(false) },
                React.createElement(S.ModalContent, { onClick: (e) => e.stopPropagation() },
                    React.createElement(S.Close, { onClick: () => setModalOpen(false) },
                        React.createElement(Close, null)),
                    React.createElement(S.ZoomedImage, null,
                        React.createElement(TransformWrapper, { ref: zoomRef, centerOnInit: true },
                            React.createElement(TransformComponent, null,
                                React.createElement("img", { src: imageUrl })))),
                    React.createElement(S.ControlsBar, null,
                        React.createElement(S.Control, { title: "Zoom In", onClick: () => zoomRef.current && zoomRef.current.zoomIn() },
                            React.createElement(Zoom, null)),
                        React.createElement(S.Control, { title: "Zoom Out", onClick: () => zoomRef.current && zoomRef.current.zoomOut() },
                            React.createElement(ZoomOut, null)),
                        React.createElement(S.Control, { title: "Reset Zoom", onClick: () => zoomRef.current && zoomRef.current.resetTransform() },
                            React.createElement(Reset, null))))))));
};
export default withMotion(ZoomImage);
