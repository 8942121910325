import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import styled from 'styled-components';
import { Containers, StepStyles } from '../../../../Shared/Step.styles';
import { StepFooterStyles } from '../../../../StepFooter/StepFooter.styles';
import { SolutionStyles } from '../Solution/Solution.styles';
const Container = styled.div `
  display: flex;
  flex-direction: column;

  ${SolutionStyles.LongSmallButton} {
    font-size: 17px;
    padding: 6px 25px;
  }
`;
const TopContent = styled.div `
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;
const BackButton = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: ${brand.primary.alt};
  cursor: pointer;
  width: max-content;
  ${fonts.DaxPro.Medium};
  margin-top: 15px;

  svg {
    width: 20px;
    transform: rotate(180deg);
  }
`;
const ButtonContainer = styled.div `
  display: flex;
  gap: 5px;

  @media ${until(Device.TabletLarge)} {
    flex-direction: column;
  }
`;
const RestartButton = styled(StepFooterStyles.ButtonSmall) `
  ${fonts.DaxPro.Medium}
  background-color: ${brand.white} !important;
  border: 2px solid ${brand.primary.alt};
  color: ${brand.black};
  display: flex;
  align-items: center;
  font-size: 17px !important;

  &:hover {
    color: ${brand.black} !important;
  }

  @media ${until(Device.TabletLarge)} {
    justify-content: center;
  }

  svg {
    margin-right: 10px;
  }

  span {
    color: ${brand.primary.alt};

    &:last-of-type {
      position: relative;
      bottom: 4px;
      font-size: 20px;
    }
  }
`;
const Solution = styled.div `
  box-shadow: 0px 0px 6px 0px ${brand.grey.grey89};
  display: flex;
  padding: 25px;
  flex-direction: column;
  gap: 35px;
  max-width: 1300px;
  width: 100%;
  margin: auto;
  margin-top: 45px;
  background-color: ${brand.white};

  @media ${until(Device.TabletLarge)} {
    position: relative;
    padding: 10px;
  }
`;
const TopSection = styled.div `
  @media ${until(Device.TabletLarge)} {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  @media ${from(Device.TabletLarge)} {
    display: grid;
    grid-template-columns: 250px 1fr auto;
    position: relative;
  }
`;
const Details = styled.div `
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media ${from(Device.TabletLarge)} {
    padding-top: 10px;
  }

  @media ${until(Device.TabletLarge)} {
    order: 1;
  }
`;
const Title = styled.div `
  font-size: 22px;
  ${fonts.DaxPro.Bold};
`;
const DesignReference = styled.div `
  font-size: 17px;
  color: ${brand.primary.alt};
  ${fonts.DaxPro.Medium};
`;
const Table = styled.div `
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 5px;

  &[data-solution-mode='true'] {
    min-width: 265px;
    margin-top: 0;
  }

  @media ${until(Device.TabletLarge)} {
    border-top: 1px solid ${brand.grey.grey75};
    padding-top: 20px;
  }
`;
const Row = styled.div `
  display: flex;
  gap: 5px;

  [data-solution-mode='true'] & {
    padding-left: 15px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      background-color: ${brand.primary.alt};
      border-radius: 50%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  strong {
    ${fonts.DaxPro.Medium};
  }
`;
const SolutionTag = styled.div `
  position: absolute;
  top: -25px;
  right: 20px;
  padding: 12px 15px;
  user-select: none;
  border-radius: 0px 0px 10px 10px;
  background-color: ${brand.primary.alt};
  color: ${brand.white};
  ${fonts.DaxPro.Medium};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  gap: 10px;
  width: max-content;
  max-width: 100%;

  @media ${until(Device.TabletLarge)} {
    display: none;
  }
`;
const MobileSolutionTag = styled(SolutionTag) `
  top: 0;
  right: 15px;

  @media ${from(Device.TabletLarge)} {
    display: none;
  }
`;
const EndSection = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${from(Device.TabletLarge)} {
    padding: 35px 20px 0px;
  }

  ${StepFooterStyles.ButtonSmall} {
    @media ${from(Device.TabletLarge)} {
      margin-top: 45px;
    }
    font-size: 17px;
    border: 1px solid ${brand.primary.alt};
  }
`;
const TableContainers = styled.div `
  display: flex;
  gap: 10px;
  justify-content: space-between;

  @media ${until(Device.TabletLarge)} {
    flex-direction: column;
  }

  ${SolutionStyles.TableHeader} {
    margin-bottom: 15px;
  }
`;
const TableWrapper = styled.div `
  overflow: hidden;
  width: 100%;

  ${SolutionStyles.TableContainer} {
    grid-template-columns: repeat(5, 125px);

    @media ${from(Device.TabletLarge)} {
      grid-template-columns: repeat(5, auto);
    }

    ${SolutionStyles.Header} {
      font-size: 14px;
    }

    ${SolutionStyles.Cell} {
      font-size: 15px;
    }
  }

  ${SolutionStyles.StrutsTableWrapper} {
    grid-template-columns: repeat(10, auto);
  }
`;
const GraphsWrapper = styled.div `
  gap: 15px;
  padding: 15px;
  background-color: ${brand.grey.grey89};

  @media ${until(Device.TabletLarge)} {
    display: flex;
    flex-direction: column;
  }

  @media ${from(Device.TabletLarge)} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 35px;
    gap: 35px;
  }
`;
const GridWrapper = styled(StepStyles.Grid) `
  max-width: unset;

  @media ${until(Device.TabletLarge)} {
    ${() => Containers.Wide} {
      grid-column: 1 / -1;
    }
  }

  @media ${from(Device.TabletLarge)} {
    max-width: 83vw;
  }
`;
const ImageWrapper = styled.div `
  @media ${until(Device.TabletLarge)} {
    display: none;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: auto;
    aspect-ratio: 1 /1;
    object-fit: contain;
  }
`;
export const SummaryStyles = {
    Container,
    TopContent,
    BackButton,
    ButtonContainer,
    RestartButton,
    Solution,
    TopSection,
    Details,
    Title,
    DesignReference,
    Table,
    Row,
    SolutionTag,
    EndSection,
    TableContainers,
    TableWrapper,
    GraphsWrapper,
    GridWrapper,
    MobileSolutionTag,
    ImageWrapper,
};
