import React, { useEffect, useMemo, useState } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { DepthStyles as S } from './Depth.styles';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import InfoIcon from '../../InfoIcon/InfoIcon';
import { padNumber } from '../SoilProfiles/SoilInfoTable/SoilInfoTable';
const Depth = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    const [hasFocus, setHasFocus] = useState(false);
    useEffect(() => {
        //
        dispatch({
            type: 'set-footer',
            payload: {
                beforeNext: () => dispatch({ type: 'validate-depth' }),
            },
        });
    }, []);
    const svgDepth = useMemo(() => {
        let _svgDepth = 17;
        if (state.form.depth) {
            const factor = state.form.depth > 5 ? 5 : state.form.depth;
            _svgDepth = 17 * factor;
            if (_svgDepth < 17) {
                _svgDepth = 17;
            }
        }
        return _svgDepth;
    }, [state.form.depth]);
    const handleDepthChange = (value) => {
        let floatValue = parseFloat(value);
        if (floatValue > 99999) {
            floatValue = 99999;
        }
        if (value === '' || floatValue < 0) {
            setFormField('depth', undefined);
        }
        else {
            setFormField('depth', floatValue);
        }
    };
    const handleKeyPress = (event) => {
        if (event.charCode == 13) {
            event.preventDefault();
        }
    };
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            React.createElement(SharedStyles.TitleWithInfoIcon, null,
                React.createElement("h2", null, state.labels['heading']),
                state.labels['tooltip'] && (React.createElement(InfoIcon, { onClick: () => {
                        dispatch({
                            type: 'set-modal',
                            payload: {
                                description: state.labels['tooltip'],
                                fullWidth: true,
                                size: 'normal',
                            },
                        });
                    } }))),
            React.createElement(S.Container, null,
                state.form.depth !== undefined && (React.createElement(S.Label, { style: { height: `${svgDepth}px` } },
                    React.createElement(S.Indicator, null),
                    state.form.depth,
                    "m")),
                React.createElement("svg", { id: "icon", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 294.928 102.119" },
                    React.createElement("g", { id: "Group_44", "data-name": "Group 44", transform: "translate(-102.872 -342.491)" },
                        React.createElement("path", { id: "Path_69", "data-name": "Path 69", d: "M393.309,349.21c-2.85-.27-5.471,2.191-8.305,1.786-1.845-.264-3.425-1.722-5.289-1.694-1.835.027-3.415,1.5-5.25,1.519-1.787.019-3.354-1.345-5.14-1.391-2.072-.052-3.886,1.672-5.957,1.59-1.9-.074-3.438-1.642-5.309-1.984-2.939-.537-5.654,1.729-8.315,3.087-2.35-.241-4.158-2.6-6.52-2.52-2.094.07-3.643,2.031-5.682,2.51-3.167.744-6.193-2.257-9.43-1.909-1.589.17-2.969,1.128-4.493,1.6a7.629,7.629,0,0,1-4.974-.172c-1.924-.748-3.9-2.31-5.809-1.519a14.769,14.769,0,0,0-1.737,1.115,5.885,5.885,0,0,1-5.462-.177c-1.365-.671-2.841-1.691-4.268-1.16-1.27.473-1.979,2.06-3.329,2.21-1.787.2-2.778-2.209-4.524-2.631-2.268-.548-4.1,2.457-6.427,2.252-1.368-.12-2.394-1.328-3.726-1.662a3.635,3.635,0,0,0-3.9,1.877l.023,5.975H221.493v-7.046c-2.5.043-2.568-1.4-4.67-1.6-2.85-.27-5.472,2.191-8.306,1.786-1.845-.265-3.424-1.722-5.289-1.694-1.835.027-3.415,1.5-5.25,1.519-1.787.019-3.354-1.345-5.14-1.391-2.072-.053-3.886,1.672-5.957,1.59-1.9-.074-3.438-1.642-5.309-1.984-2.939-.537-5.654,1.729-8.315,3.087-2.35-.241-4.159-2.6-6.52-2.52-2.095.07-3.644,2.031-5.682,2.51-3.167.744-6.193-2.257-9.43-1.909-1.589.17-2.969,1.128-4.494,1.6a7.619,7.619,0,0,1-4.973-.172c-1.925-.747-3.9-2.309-5.81-1.519a14.964,14.964,0,0,0-1.737,1.115,5.885,5.885,0,0,1-5.462-.176c-1.365-.671-2.841-1.691-4.268-1.16-1.271.472-1.98,2.06-3.329,2.21-1.787.2-2.777-2.208-4.524-2.631-2.268-.547-4.1,2.457-6.427,2.253-1.368-.121-2.4-1.328-3.726-1.662a3.116,3.116,0,0,0-3.537,1.8v95.391H221.307l58.517-.117v.058H397.792V350.794C395.291,350.837,395.411,349.409,393.309,349.21Z", transform: "translate(0 -2.705)", fill: "#c9c9c9" }),
                        React.createElement("path", { id: "Path_70", "data-name": "Path 70", d: "M112.329,367.252a1.794,1.794,0,0,1,.43-.3,3.924,3.924,0,0,1,1.972-.286,4.884,4.884,0,0,1,1.776.358,3.539,3.539,0,0,1,1.69,3.2c.085,1.363-6.3,2.116-6.453-.942A2.506,2.506,0,0,1,112.329,367.252Z", transform: "translate(-3.513 -10.111)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_71", "data-name": "Path 71", d: "M139.745,365.494a.882.882,0,0,1-.031-.259,1.961,1.961,0,0,1,.334-.938,2.447,2.447,0,0,1,.573-.7,1.77,1.77,0,0,1,1.81,0c.622.281-.538,3.277-1.926,2.632A1.254,1.254,0,0,1,139.745,365.494Z", transform: "translate(-15.219 -8.725)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_72", "data-name": "Path 72", d: "M134.752,404.79a1.776,1.776,0,0,1,.429-.3,3.924,3.924,0,0,1,1.972-.286,4.881,4.881,0,0,1,1.776.357,3.539,3.539,0,0,1,1.69,3.2c.085,1.363-6.3,2.115-6.453-.942A2.5,2.5,0,0,1,134.752,404.79Z", transform: "translate(-12.895 -25.816)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_73", "data-name": "Path 73", d: "M168.736,381.5a1.78,1.78,0,0,1,.43-.3,3.922,3.922,0,0,1,1.971-.286,4.883,4.883,0,0,1,1.776.357,3.539,3.539,0,0,1,1.69,3.2c.085,1.363-6.3,2.115-6.452-.942A2.506,2.506,0,0,1,168.736,381.5Z", transform: "translate(-27.114 -16.072)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_74", "data-name": "Path 74", d: "M193.753,360.509a1.8,1.8,0,0,1,.43-.3,3.921,3.921,0,0,1,1.971-.286,4.868,4.868,0,0,1,1.776.358,3.537,3.537,0,0,1,1.69,3.2c.085,1.363-6.3,2.116-6.452-.942A2.506,2.506,0,0,1,193.753,360.509Z", transform: "translate(-37.581 -7.289)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_75", "data-name": "Path 75", d: "M208.229,388.426a1.8,1.8,0,0,1,.43-.3,3.924,3.924,0,0,1,1.972-.286,4.883,4.883,0,0,1,1.776.358,3.538,3.538,0,0,1,1.69,3.2c.085,1.363-6.3,2.116-6.453-.942A2.506,2.506,0,0,1,208.229,388.426Z", transform: "translate(-43.637 -18.97)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_76", "data-name": "Path 76", d: "M238.735,361a10.4,10.4,0,0,1,4.182-.338c1.779.341,1.983,2.771,1.91,4.332-.141,3.025-6.34,1.633-8.213,1.113-1.453-.4-.677-2.771-.108-3.6A3.707,3.707,0,0,1,238.735,361Z", transform: "translate(-55.414 -7.562)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_77", "data-name": "Path 77", d: "M262.76,384.335a1.339,1.339,0,0,1,.3-.3,1.987,1.987,0,0,1,1.368-.286,2.528,2.528,0,0,1,1.233.358c.847.554,1.12,1.968,1.173,3.2.059,1.363-4.371,2.115-4.48-.942A3.313,3.313,0,0,1,262.76,384.335Z", transform: "translate(-66.529 -17.258)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_78", "data-name": "Path 78", d: "M273.11,360.509a1.8,1.8,0,0,1,.43-.3,3.921,3.921,0,0,1,1.971-.286,4.871,4.871,0,0,1,1.776.358,3.537,3.537,0,0,1,1.69,3.2c.085,1.363-6.3,2.116-6.452-.942A2.506,2.506,0,0,1,273.11,360.509Z", transform: "translate(-70.783 -7.289)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_79", "data-name": "Path 79", d: "M103.338,351.966a3.116,3.116,0,0,1,3.537-1.8c1.331.333,2.358,1.541,3.726,1.662,2.325.2,4.16-2.8,6.427-2.253,1.747.422,2.737,2.829,4.524,2.631,1.349-.149,2.058-1.737,3.329-2.21,1.427-.53,2.9.489,4.268,1.16a5.883,5.883,0,0,0,5.462.176,14.952,14.952,0,0,1,1.737-1.115c1.908-.79,3.885.772,5.81,1.519a7.619,7.619,0,0,0,4.973.172c1.525-.473,2.905-1.431,4.494-1.6,3.237-.347,6.263,2.653,9.43,1.909,2.039-.479,3.587-2.44,5.682-2.51,2.361-.079,4.17,2.279,6.52,2.52,2.661-1.359,5.376-3.625,8.315-3.087,1.872.341,3.409,1.909,5.309,1.984,2.072.081,3.885-1.643,5.957-1.59,1.786.045,3.353,1.409,5.14,1.391,1.835-.019,3.415-1.492,5.25-1.519,1.864-.028,3.443,1.43,5.289,1.694,2.834.406,5.455-2.055,8.306-1.786,2.1.2,2.169,1.642,4.67,1.6", transform: "translate(0 -2.747)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_80", "data-name": "Path 80", d: "M290.422,468.643a1.8,1.8,0,0,1,.43-.3,3.928,3.928,0,0,1,1.971-.286,4.893,4.893,0,0,1,1.776.358c1.3.589,2.529,3.183.876,4.044-1.569.818-5.521.541-5.639-1.785A2.506,2.506,0,0,1,290.422,468.643Z", transform: "translate(-78.027 -52.532)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_81", "data-name": "Path 81", d: "M290.422,429.136a1.8,1.8,0,0,1,.43-.3,3.928,3.928,0,0,1,1.971-.286,4.892,4.892,0,0,1,1.776.358,3.539,3.539,0,0,1,1.69,3.2c.086,1.363-6.3,2.115-6.452-.942A2.506,2.506,0,0,1,290.422,429.136Z", transform: "translate(-78.027 -36.003)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_82", "data-name": "Path 82", d: "M304.029,498.965a1.8,1.8,0,0,1,.429-.3,3.931,3.931,0,0,1,1.972-.286,4.9,4.9,0,0,1,1.776.358,3.539,3.539,0,0,1,1.69,3.2c.085,1.363-6.3,2.115-6.452-.942A2.507,2.507,0,0,1,304.029,498.965Z", transform: "translate(-83.72 -65.219)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_83", "data-name": "Path 83", d: "M342.336,472.686a10.4,10.4,0,0,1,3.047,2.884c.936,1.551-.735,3.327-1.944,4.316-2.344,1.918-5.453-3.624-6.319-5.364-.672-1.35,1.609-2.356,2.6-2.486A3.707,3.707,0,0,1,342.336,472.686Z", transform: "translate(-97.762 -54.188)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_84", "data-name": "Path 84", d: "M383.661,496.561a1.349,1.349,0,0,1,.3-.3,1.982,1.982,0,0,1,1.368-.286,2.523,2.523,0,0,1,1.234.357c.847.554,1.119,1.968,1.173,3.2.059,1.363-4.371,2.115-4.48-.942A3.311,3.311,0,0,1,383.661,496.561Z", transform: "translate(-117.114 -64.213)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_85", "data-name": "Path 85", d: "M110.167,478.409a1.785,1.785,0,0,1,.429-.3,3.919,3.919,0,0,1,1.972-.286,4.882,4.882,0,0,1,1.776.357,3.539,3.539,0,0,1,1.69,3.2c.085,1.363-6.3,2.115-6.452-.942A2.507,2.507,0,0,1,110.167,478.409Z", transform: "translate(-2.609 -56.619)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_86", "data-name": "Path 86", d: "M140.739,466.6a1.778,1.778,0,0,1,.43-.3,3.922,3.922,0,0,1,1.971-.287,4.895,4.895,0,0,1,1.776.358,3.539,3.539,0,0,1,1.69,3.2c.085,1.363-6.3,2.115-6.452-.942A2.507,2.507,0,0,1,140.739,466.6Z", transform: "translate(-15.4 -51.677)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_87", "data-name": "Path 87", d: "M140.739,496.923a1.777,1.777,0,0,1,.43-.3,3.921,3.921,0,0,1,1.971-.286,4.882,4.882,0,0,1,1.776.357,3.539,3.539,0,0,1,1.69,3.2c.085,1.363-6.3,2.115-6.452-.942A2.508,2.508,0,0,1,140.739,496.923Z", transform: "translate(-15.4 -64.364)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_88", "data-name": "Path 88", d: "M178.773,476.92a10.394,10.394,0,0,1,1.725,3.824c.278,1.79-1.941,2.8-3.436,3.256-2.9.885-3.671-5.421-3.813-7.36-.11-1.5,2.382-1.57,3.352-1.313A3.708,3.708,0,0,1,178.773,476.92Z", transform: "translate(-29.249 -55.53)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_89", "data-name": "Path 89", d: "M209.746,490.427a1.363,1.363,0,0,1,.3-.3,1.987,1.987,0,0,1,1.369-.286,2.515,2.515,0,0,1,1.233.358c.847.554,1.12,1.967,1.173,3.2.059,1.363-4.371,2.116-4.48-.942A3.317,3.317,0,0,1,209.746,490.427Z", transform: "translate(-44.348 -61.647)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_90", "data-name": "Path 90", d: "M248.956,490.427a1.794,1.794,0,0,1,.43-.3,3.924,3.924,0,0,1,1.972-.286,4.874,4.874,0,0,1,1.776.358,3.538,3.538,0,0,1,1.69,3.2c.085,1.363-6.3,2.116-6.453-.942A2.506,2.506,0,0,1,248.956,490.427Z", transform: "translate(-60.678 -61.647)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_91", "data-name": "Path 91", d: "M121.16,438.546a1.8,1.8,0,0,1,.186.488,3.923,3.923,0,0,1-.193,1.983,4.872,4.872,0,0,1-.771,1.64,3.539,3.539,0,0,1-3.511.878c-1.344-.243-.554-6.619,2.453-6.042A2.508,2.508,0,0,1,121.16,438.546Z", transform: "translate(-5.398 -39.733)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_92", "data-name": "Path 92", d: "M162.432,427.97a10.391,10.391,0,0,1,4.181-.337c1.78.34,1.983,2.77,1.911,4.331-.141,3.025-6.341,1.633-8.213,1.113-1.453-.4-.677-2.771-.108-3.6A3.706,3.706,0,0,1,162.432,427.97Z", transform: "translate(-23.489 -35.582)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_93", "data-name": "Path 93", d: "M202.173,447.487a1.353,1.353,0,0,1,.3-.3,1.988,1.988,0,0,1,1.368-.286,2.528,2.528,0,0,1,1.233.358c.847.554,1.12,1.968,1.173,3.2.059,1.363-4.371,2.115-4.48-.942A3.315,3.315,0,0,1,202.173,447.487Z", transform: "translate(-41.18 -43.681)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_94", "data-name": "Path 94", d: "M239.009,408.881a1.785,1.785,0,0,1,.429-.3,3.924,3.924,0,0,1,1.972-.286,4.881,4.881,0,0,1,1.776.357,3.539,3.539,0,0,1,1.69,3.2c.086,1.363-6.3,2.115-6.452-.942A2.507,2.507,0,0,1,239.009,408.881Z", transform: "translate(-56.516 -27.528)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_95", "data-name": "Path 95", d: "M254.511,437.381a1.8,1.8,0,0,1,.43-.3,3.921,3.921,0,0,1,1.971-.286,4.879,4.879,0,0,1,1.776.358,3.538,3.538,0,0,1,1.69,3.2c.086,1.363-6.3,2.116-6.452-.942A2.506,2.506,0,0,1,254.511,437.381Z", transform: "translate(-63.002 -39.452)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_96", "data-name": "Path 96", d: "M206.72,423.169c-.287-.277-.559-.572-.818-.876a6.115,6.115,0,0,1-1.614-2.43,1.18,1.18,0,0,1,.722-1.377,1.577,1.577,0,0,1,1.162.309,8.857,8.857,0,0,1,3.644,4.4,1.339,1.339,0,0,1,.116.565c-.061.78-1.228.895-1.887.473A8.536,8.536,0,0,1,206.72,423.169Z", transform: "translate(-42.228 -31.784)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_97", "data-name": "Path 97", d: "M250.6,468.957c-.323.236-.66.452-1,.656a6.11,6.11,0,0,1-2.671,1.173,1.18,1.18,0,0,1-1.232-.947,1.576,1.576,0,0,1,.5-1.092,8.853,8.853,0,0,1,4.957-2.835,1.341,1.341,0,0,1,.576-.018c.758.194.671,1.364.142,1.941A8.507,8.507,0,0,1,250.6,468.957Z", transform: "translate(-59.558 -51.621)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_98", "data-name": "Path 98", d: "M397.648,469.067c-.087.39-.195.776-.319,1.156a6.11,6.11,0,0,1-1.224,2.647,1.18,1.18,0,0,1-1.551.106,1.578,1.578,0,0,1-.345-1.152,8.854,8.854,0,0,1,1.838-5.407,1.33,1.33,0,0,1,.421-.4c.7-.357,1.405.578,1.391,1.36A8.533,8.533,0,0,1,397.648,469.067Z", transform: "translate(-121.699 -51.652)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_99", "data-name": "Path 99", d: "M368.035,433.227a1.8,1.8,0,0,1,.43-.3,3.928,3.928,0,0,1,1.971-.286,4.894,4.894,0,0,1,1.776.358c1.3.589,2.529,3.183.876,4.044-1.569.818-5.521.541-5.639-1.785A2.506,2.506,0,0,1,368.035,433.227Z", transform: "translate(-110.5 -37.714)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1.188" }),
                        React.createElement("path", { id: "Path_101", "data-name": "Path 101", d: "M415.776,367.151a1.793,1.793,0,0,1,.429-.3,3.931,3.931,0,0,1,1.972-.286,4.9,4.9,0,0,1,1.776.358,3.539,3.539,0,0,1,1.69,3.2c.085,1.363-6.3,2.115-6.452-.942A2.507,2.507,0,0,1,415.776,367.151Z", transform: "translate(-130.474 -10.068)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_102", "data-name": "Path 102", d: "M443.191,365.393a.894.894,0,0,1-.031-.259,1.967,1.967,0,0,1,.334-.938,2.455,2.455,0,0,1,.573-.7,1.769,1.769,0,0,1,1.81,0c.622.281-.537,3.277-1.925,2.631A1.253,1.253,0,0,1,443.191,365.393Z", transform: "translate(-142.18 -8.683)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_103", "data-name": "Path 103", d: "M438.2,404.689a1.8,1.8,0,0,1,.43-.3,3.921,3.921,0,0,1,1.971-.286,4.883,4.883,0,0,1,1.776.358,3.538,3.538,0,0,1,1.69,3.2c.085,1.363-6.3,2.116-6.452-.942A2.506,2.506,0,0,1,438.2,404.689Z", transform: "translate(-139.856 -25.774)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_104", "data-name": "Path 104", d: "M472.182,381.4a1.8,1.8,0,0,1,.43-.3,3.921,3.921,0,0,1,1.971-.286,4.879,4.879,0,0,1,1.776.358,3.538,3.538,0,0,1,1.69,3.2c.085,1.363-6.3,2.116-6.452-.942A2.506,2.506,0,0,1,472.182,381.4Z", transform: "translate(-154.074 -16.03)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_105", "data-name": "Path 105", d: "M497.2,360.408a1.8,1.8,0,0,1,.43-.3,3.93,3.93,0,0,1,1.972-.286,4.9,4.9,0,0,1,1.776.358,3.539,3.539,0,0,1,1.69,3.2c.085,1.363-6.3,2.115-6.453-.942A2.506,2.506,0,0,1,497.2,360.408Z", transform: "translate(-164.541 -7.247)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_106", "data-name": "Path 106", d: "M511.676,388.325a1.778,1.778,0,0,1,.43-.3,3.922,3.922,0,0,1,1.971-.287,4.9,4.9,0,0,1,1.776.358,3.539,3.539,0,0,1,1.69,3.2c.086,1.363-6.3,2.115-6.452-.942A2.507,2.507,0,0,1,511.676,388.325Z", transform: "translate(-170.598 -18.928)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_107", "data-name": "Path 107", d: "M542.182,360.9a10.382,10.382,0,0,1,4.182-.338c1.779.341,1.983,2.771,1.911,4.331-.141,3.025-6.341,1.633-8.214,1.114-1.453-.4-.676-2.772-.108-3.6A3.7,3.7,0,0,1,542.182,360.9Z", transform: "translate(-182.375 -7.52)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_108", "data-name": "Path 108", d: "M566.206,384.235a1.337,1.337,0,0,1,.3-.3,1.985,1.985,0,0,1,1.369-.286,2.524,2.524,0,0,1,1.234.357c.846.554,1.119,1.968,1.172,3.2.059,1.363-4.371,2.115-4.479-.942A3.312,3.312,0,0,1,566.206,384.235Z", transform: "translate(-193.49 -17.216)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_109", "data-name": "Path 109", d: "M576.556,360.408a1.8,1.8,0,0,1,.43-.3,3.928,3.928,0,0,1,1.971-.286,4.893,4.893,0,0,1,1.776.358,3.539,3.539,0,0,1,1.69,3.2c.086,1.363-6.3,2.115-6.452-.942A2.506,2.506,0,0,1,576.556,360.408Z", transform: "translate(-197.744 -7.247)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_110", "data-name": "Path 110", d: "M406.784,351.865a3.116,3.116,0,0,1,3.537-1.8c1.331.333,2.358,1.541,3.726,1.662,2.325.2,4.16-2.8,6.427-2.252,1.747.422,2.737,2.829,4.524,2.631,1.349-.15,2.058-1.737,3.329-2.21,1.427-.53,2.9.489,4.268,1.16a5.885,5.885,0,0,0,5.462.177,14.769,14.769,0,0,1,1.737-1.115c1.907-.79,3.885.771,5.809,1.519a7.629,7.629,0,0,0,4.974.172c1.524-.474,2.9-1.431,4.493-1.6,3.237-.347,6.263,2.653,9.43,1.909,2.039-.479,3.588-2.44,5.682-2.51,2.362-.079,4.17,2.279,6.52,2.52,2.661-1.359,5.376-3.624,8.315-3.087,1.872.342,3.408,1.909,5.309,1.984,2.071.081,3.885-1.642,5.957-1.59,1.786.046,3.353,1.409,5.14,1.391,1.835-.019,3.415-1.492,5.25-1.519,1.864-.028,3.443,1.43,5.289,1.694,2.834.406,5.455-2.055,8.305-1.786,2.1.2,1.982,1.627,4.483,1.584", transform: "translate(-126.961 -2.705)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_111", "data-name": "Path 111", d: "M593.868,468.543a1.777,1.777,0,0,1,.43-.3,3.924,3.924,0,0,1,1.972-.286,4.884,4.884,0,0,1,1.776.357c1.3.589,2.529,3.184.876,4.044-1.569.817-5.521.54-5.64-1.785A2.507,2.507,0,0,1,593.868,468.543Z", transform: "translate(-204.987 -52.49)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_112", "data-name": "Path 112", d: "M593.868,429.036a1.777,1.777,0,0,1,.43-.3,3.924,3.924,0,0,1,1.972-.286,4.885,4.885,0,0,1,1.776.357,3.54,3.54,0,0,1,1.69,3.2c.085,1.363-6.3,2.115-6.453-.942A2.508,2.508,0,0,1,593.868,429.036Z", transform: "translate(-204.987 -35.961)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_113", "data-name": "Path 113", d: "M413.613,478.309a1.8,1.8,0,0,1,.43-.3,3.921,3.921,0,0,1,1.971-.286,4.869,4.869,0,0,1,1.776.358,3.537,3.537,0,0,1,1.69,3.2c.085,1.363-6.3,2.116-6.452-.942A2.506,2.506,0,0,1,413.613,478.309Z", transform: "translate(-129.569 -56.576)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_114", "data-name": "Path 114", d: "M444.185,466.5a1.794,1.794,0,0,1,.43-.3,3.922,3.922,0,0,1,1.971-.286,4.881,4.881,0,0,1,1.776.357,3.538,3.538,0,0,1,1.69,3.2c.086,1.363-6.3,2.116-6.452-.942A2.506,2.506,0,0,1,444.185,466.5Z", transform: "translate(-142.36 -51.636)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_115", "data-name": "Path 115", d: "M444.185,496.822a1.8,1.8,0,0,1,.43-.3,3.921,3.921,0,0,1,1.971-.286,4.881,4.881,0,0,1,1.776.358,3.537,3.537,0,0,1,1.69,3.2c.086,1.363-6.3,2.116-6.452-.942A2.506,2.506,0,0,1,444.185,496.822Z", transform: "translate(-142.36 -64.322)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_116", "data-name": "Path 116", d: "M482.219,476.82a10.389,10.389,0,0,1,1.725,3.824c.278,1.79-1.941,2.8-3.436,3.256-2.9.885-3.671-5.421-3.812-7.36-.11-1.5,2.382-1.57,3.352-1.313A3.7,3.7,0,0,1,482.219,476.82Z", transform: "translate(-156.21 -55.488)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_117", "data-name": "Path 117", d: "M513.192,490.326a1.352,1.352,0,0,1,.3-.3,1.987,1.987,0,0,1,1.368-.286,2.527,2.527,0,0,1,1.233.358c.847.554,1.12,1.968,1.173,3.2.059,1.363-4.371,2.116-4.48-.942A3.314,3.314,0,0,1,513.192,490.326Z", transform: "translate(-171.309 -61.604)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_118", "data-name": "Path 118", d: "M552.4,490.326a1.792,1.792,0,0,1,.429-.3,3.931,3.931,0,0,1,1.972-.286,4.895,4.895,0,0,1,1.776.358,3.538,3.538,0,0,1,1.69,3.2c.086,1.363-6.3,2.116-6.452-.942A2.506,2.506,0,0,1,552.4,490.326Z", transform: "translate(-187.638 -61.604)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_119", "data-name": "Path 119", d: "M424.606,438.446a1.792,1.792,0,0,1,.186.489,3.923,3.923,0,0,1-.192,1.983,4.9,4.9,0,0,1-.771,1.64,3.539,3.539,0,0,1-3.512.878c-1.344-.242-.553-6.619,2.453-6.042A2.505,2.505,0,0,1,424.606,438.446Z", transform: "translate(-132.359 -39.691)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_120", "data-name": "Path 120", d: "M465.878,427.87a10.383,10.383,0,0,1,4.182-.338c1.779.341,1.983,2.771,1.91,4.331-.141,3.025-6.34,1.633-8.213,1.114-1.453-.4-.677-2.772-.108-3.6A3.7,3.7,0,0,1,465.878,427.87Z", transform: "translate(-150.45 -35.54)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_121", "data-name": "Path 121", d: "M505.619,447.387a1.339,1.339,0,0,1,.3-.3,1.985,1.985,0,0,1,1.369-.286,2.523,2.523,0,0,1,1.234.357c.847.554,1.119,1.968,1.173,3.2.059,1.363-4.372,2.115-4.48-.942A3.312,3.312,0,0,1,505.619,447.387Z", transform: "translate(-168.14 -43.639)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_122", "data-name": "Path 122", d: "M542.455,408.78a1.8,1.8,0,0,1,.43-.3,3.921,3.921,0,0,1,1.971-.286,4.881,4.881,0,0,1,1.776.358,3.538,3.538,0,0,1,1.69,3.2c.086,1.363-6.3,2.116-6.452-.942A2.506,2.506,0,0,1,542.455,408.78Z", transform: "translate(-183.476 -27.486)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_123", "data-name": "Path 123", d: "M557.957,437.28a1.807,1.807,0,0,1,.43-.3,3.93,3.93,0,0,1,1.972-.286,4.895,4.895,0,0,1,1.776.358,3.539,3.539,0,0,1,1.69,3.2c.085,1.363-6.3,2.115-6.453-.942A2.507,2.507,0,0,1,557.957,437.28Z", transform: "translate(-189.962 -39.41)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_124", "data-name": "Path 124", d: "M510.166,423.069c-.287-.278-.559-.573-.818-.877a6.116,6.116,0,0,1-1.614-2.43,1.181,1.181,0,0,1,.722-1.377,1.583,1.583,0,0,1,1.162.309,8.852,8.852,0,0,1,3.644,4.4,1.33,1.33,0,0,1,.117.565c-.06.78-1.229.895-1.888.473A8.566,8.566,0,0,1,510.166,423.069Z", transform: "translate(-169.188 -31.742)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_125", "data-name": "Path 125", d: "M554.043,468.856c-.323.235-.66.452-1,.655a6.112,6.112,0,0,1-2.671,1.173,1.18,1.18,0,0,1-1.232-.947,1.579,1.579,0,0,1,.5-1.092,8.856,8.856,0,0,1,4.957-2.835,1.344,1.344,0,0,1,.577-.018c.758.194.67,1.364.141,1.941A8.546,8.546,0,0,1,554.043,468.856Z", transform: "translate(-186.519 -51.578)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_126", "data-name": "Path 126", d: "M321.547,400.6a1.792,1.792,0,0,1,.429-.3,3.93,3.93,0,0,1,1.972-.286,4.882,4.882,0,0,1,1.776.358,3.538,3.538,0,0,1,1.69,3.2c.086,1.363-6.3,2.116-6.453-.942A2.5,2.5,0,0,1,321.547,400.6Z", transform: "translate(-91.049 -24.063)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_127", "data-name": "Path 127", d: "M392.1,393.566a10.388,10.388,0,0,1,3.047,2.884c.936,1.551-.735,3.327-1.944,4.317-2.344,1.917-5.453-3.624-6.319-5.364-.672-1.35,1.609-2.356,2.6-2.486A3.7,3.7,0,0,1,392.1,393.566Z", transform: "translate(-118.585 -21.084)", fill: "none", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" }),
                        React.createElement("path", { id: "Path_100", "data-name": "Path 100", d: `M306.492,342.491v${svgDepth}H364.6V342.491`, transform: "translate(-84.999)", fill: "#fff", stroke: "#040405", strokeMiterlimit: "10", strokeWidth: "1" })))),
            React.createElement(FormStyles.FormControl, { center: true, invalid: state.errors['depth'] !== undefined },
                React.createElement("label", { htmlFor: "txtDepth" }, "Depth"),
                React.createElement(FormStyles.FormError, null, "Please enter a depth."),
                React.createElement("input", { type: "number", id: "txtDepth", value: hasFocus ? state.form.depth : padNumber(state.form.depth), onChange: (e) => handleDepthChange(e.currentTarget.value), onKeyPress: (e) => handleKeyPress(e), onFocus: () => setHasFocus(true), onBlur: () => setHasFocus(false), step: "0.25", min: 0 }),
                React.createElement(FormStyles.FormSub, null, "m")))));
};
export default Depth;
