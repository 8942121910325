import React, { useEffect } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import { checkCanTransfer } from '@helpers/ysToYsp';
import { NoSolutionsStyles as S } from './NoSolutions.styles';
import YSButton, { YSLinkButton } from '../../Shared/YSButton/YSButton';
const NoSolutions = () => {
    const { state, dispatch } = useYSPContext();
    useEffect(() => {
        dispatch({
            type: 'set-footer',
            payload: {
                type: 'default',
            },
        });
    }, []);
    if (!state.results) {
        return null;
    }
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            React.createElement(SharedStyles.CenterText, { dangerouslySetInnerHTML: { __html: state.results.responseMessage } }),
            React.createElement(S.Buttons, null,
                state.results.telNo && (React.createElement(YSLinkButton, { href: `tel:${state.results.telNo}`, fill: "outline", colour: "green", icon: "phone", iconPosition: "left" }, "Call")),
                state.results.emailAddress && (React.createElement(YSLinkButton, { href: `mailto:${state.results.emailAddress}`, fill: "outline", colour: "green", icon: "email", iconPosition: "left" }, "Email"))),
            checkCanTransfer(state.form) && (React.createElement(S.SwapToYSP, null,
                React.createElement(S.OrLabel, null, "or"),
                React.createElement("p", null,
                    "The parameters you entered have not yielded any results, however",
                    ' ',
                    React.createElement("strong", null, "YourSolution+"),
                    " may be able to find some using more in-depth questions.",
                    React.createElement("br", null),
                    "All applicable answers entered on YourSolution will be carried over."),
                React.createElement(YSButton, { onClick: () => dispatch({ type: 'swap-to-ys-plus' }) }, "Swap to YourSolution+"))))));
};
export default NoSolutions;
