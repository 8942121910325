import React, { useEffect, useRef, useState } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import Checkbox from '@ys/Components/Steps/Controls/Checkbox/Checkbox';
import { TermsAndConditionsStyles as S } from './TermsAndConditions.styles';
import debounce from 'lodash/debounce';
import inRange from 'lodash/inRange';
import { Containers, StepStyles } from '@ys/Components/Shared/Step.styles';
import YSButton from '../../Shared/YSButton/YSButton';
const TermsAndConditions = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    const textboxRef = useRef(null);
    const [attachedListener, setAttachedListener] = useState(false);
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
    const trackScroll = (el) => {
        setHasScrolledToBottom(inRange(el.scrollTop + el.clientHeight, el.scrollHeight - 50, el.scrollHeight + 50));
    };
    const debouncedTrackScroll = debounce(trackScroll, 250);
    const trackScrollHandler = (ev) => {
        ev.target && debouncedTrackScroll(ev.target);
    };
    useEffect(() => {
        if (textboxRef.current && !attachedListener) {
            textboxRef.current.addEventListener('scroll', trackScrollHandler);
            setAttachedListener(true);
        }
    }, [textboxRef]);
    useEffect(() => {
        dispatch({
            type: 'set-footer',
            payload: {
                nextDisabled: !hasScrolledToBottom || !state.form.acceptedTs,
            },
        });
    }, [hasScrolledToBottom, state.form.acceptedTs]);
    return (React.createElement(StepStyles.Grid, null,
        React.createElement(Containers.Thin, null,
            React.createElement(S.Container, null,
                React.createElement(S.Title, null, state.labels['terms-and-conditions'] || 'Terms & Conditions'),
                React.createElement(S.TextContainer, { ref: textboxRef, dangerouslySetInnerHTML: { __html: state.labels['terms.details'] } }),
                React.createElement(Checkbox, { value: state.form.acceptedTs || false, label: state.labels['terms.accept'], onChange: () => setFormField('acceptedTs', !state.form.acceptedTs) }),
                React.createElement(YSButton, { icon: "arrow", disabled: !hasScrolledToBottom || !state.form.acceptedTs, onClick: () => {
                        if (hasScrolledToBottom && state.form.acceptedTs) {
                            dispatch({ type: 'next-step' });
                        }
                    } }, state.labels['accept'] || 'Accept')))));
};
export default TermsAndConditions;
