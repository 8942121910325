import React, { useEffect } from 'react';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { SolutionSubType } from '@stories/Widgets/YourSolution/YS/Context/types';
import Boxes from '@ys/Components/Steps/Controls/Boxes/Boxes';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { FormStyles } from '../../../Shared/Form.styles';
const SheetedSolution = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    useEffect(() => {
        //
        dispatch({
            type: 'set-footer',
            payload: {
                type: 'support',
                beforeNext: () => dispatch({ type: 'validate-sub-type' }),
            },
        });
    }, []);
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            React.createElement("h2", null, state.labels['heading_frameType']),
            React.createElement(Boxes, { error: state.errors['subType'], boxes: [
                    {
                        id: SolutionSubType.SingleFrame,
                        title: 'Single frame Propped cantilever',
                        description: state.labels['tooltip_singleFrame'],
                        imageUrl: '/sitefiles/images/ism/products/B-1F-1Frame.png',
                    },
                    {
                        id: SolutionSubType.TwoFrame,
                        title: '2 frame, no toe solution',
                        description: state.labels['tooltip_twoFrame'],
                        imageUrl: '/sitefiles/images/ism/products/B-2F-2Frame.png',
                    },
                ], value: state.form.subType, setValue: (value) => setFormField('subType', value) }))));
};
export default SheetedSolution;
