import { domMax, LazyMotion } from 'framer-motion';
import React from 'react';
export function withMotionMax(WrappedComponent) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    const ComponentWithMotionMax = (props) => {
        return (React.createElement(LazyMotion, { features: domMax, strict: true },
            React.createElement(WrappedComponent, { ...props })));
    };
    ComponentWithMotionMax.displayName = `withMotionMax(${displayName})`;
    return ComponentWithMotionMax;
}
