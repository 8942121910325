import brand from '@helpers/brand';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
const Container = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--ysp-steps-height);
  position: relative;
`;
const Step = styled.div `
  flex: 1 1 auto;
  background-color: #ccddab;
  width: 100%;
  height: 100%;
  margin-right: 4px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: 20;
    width: 4px;
    height: 100%;
    top: 0;
    left: 100%;
    background-color: ${brand.white};
  }

  ${({ hideEnd }) => hideEnd &&
    css `
      margin-right: 0px;
      &::after {
        display: none;
      }
    `}
`;
const StepBackground = styled(m.div).attrs(({ step, steps }) => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            width: 0,
        },
        visible: {
            width: `calc((100dvw / ${steps}) * ${step})`,
        },
    },
    transition: {
        type: 'tween',
        duration: 1,
        ease: 'easeInOut',
    },
})) `
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  background-color: ${brand.primary.dark};
  cursor: pointer;
`;
export const StepHeaderStyles = {
    Container,
    Step,
    StepBackground,
};
