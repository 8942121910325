import React, { useEffect } from 'react';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import InfoIcon from '@ys/Components/InfoIcon/InfoIcon';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import EndSafe from '@img/ysp/endsafe.png';
import Radios from '../Controls/Radios/Radios';
import { EndSafeSide } from '../../../Context/types';
const EndSafeSides = () => {
    const { state, dispatch, setFormField } = useYSPContext();
    useEffect(() => {
        dispatch({
            type: 'set-footer',
            payload: {
                nextDisabled: state.form.endSafeSides === undefined,
            },
        });
    }, [state.form.endSafeSides]);
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(FormStyles.Form, null,
            React.createElement(SharedStyles.TitleWithInfoIcon, null,
                React.createElement("h2", null, state.labels['sides.heading']),
                state.labels['tooltip'] && (React.createElement(InfoIcon, { onClick: () => {
                        dispatch({
                            type: 'set-modal',
                            payload: {
                                description: state.labels['tooltip'],
                                fullWidth: true,
                                size: 'normal',
                            },
                        });
                    } }))),
            React.createElement(FormStyles.FormRow, null,
                React.createElement("img", { src: EndSafe, alt: "EndSafe Panels" })),
            React.createElement(FormStyles.FormRow, { xCentered: true, style: { marginTop: 25 } },
                React.createElement(Radios, { options: [
                        {
                            label: 'One',
                            value: EndSafeSide.One,
                        },
                        {
                            label: 'Both',
                            value: EndSafeSide.Both,
                        },
                    ], value: state.form.endSafeSides, error: state.errors['endSafeSides'], setValue: (value) => {
                        setFormField('endSafeSides', value);
                    } })))));
};
export default EndSafeSides;
