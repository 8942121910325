import { m } from 'framer-motion';
import styled from 'styled-components';
const Container = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            opacity: 0,
            scale: 0.75,
        },
        visible: {
            opacity: 1,
            scale: 1,
        },
    },
})) `
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StepLoadingStyles = {
    Container,
};
