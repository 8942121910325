import { fonts } from '@helpers/fonts';
import styled, { css } from 'styled-components';
import { m } from 'framer-motion';
import brand from '@helpers/brand';
import { Device, from } from '@helpers/media';
const Container = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            y: -25,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
        },
    },
    transition: {
        duration: 1,
    },
})) `
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  @media ${from(Device.TabletLarge)} {
    gap: 25px;
  }
`;
const Text = styled(m.div) `
  font-size: 50px !important;
  line-height: 100% !important;
  user-select: none;
  ${fonts.DaxPro.Bold}

  @media ${from(Device.Tablet)} {
    font-size: 75px !important;
  }

  @media ${from(Device.TabletLarge)} {
    font-size: 100px !important;
  }
`;
const FadeTextWrapper = styled.div `
  display: flex;
`;
const TopText = styled(Text) `
  font-size: 35px !important;

  @media ${from(Device.Tablet)} {
    font-size: 45px !important;
  }

  @media ${from(Device.TabletLarge)} {
    font-size: 50px !important;
  }
`;
const Letter = styled(Text).attrs(({ i }) => ({
    animate: 'animation',
    variants: {
        animation: {
            opacity: [0, 1, 0],
            transition: {
                type: 'tween',
                opacity: {
                    delay: i / 15 + 0.6,
                    duration: 2,
                    repeat: Infinity,
                },
            },
        },
    },
})) `
  ${({ colour }) => colour === 'green'
    ? css `
          color: ${brand.primary.alt};
        `
    : css `
          color: ${brand.grey.grey20};
        `}

  ${({ last }) => last &&
    css `
      color: ${brand.divisionTags.sandhurst};
      position: relative;
      top: -10px;

      @media ${from(Device.TabletLarge)} {
        top: -15px;
      }
    `};
`;
export const LoadingStyles = {
    Container,
    Text,
    Letter,
    TopText,
    FadeTextWrapper,
};
