import brand from '@helpers/brand';
import { Device, from, until } from '@helpers/media';
import { m } from 'framer-motion';
import styled, { css } from 'styled-components';
const Container = styled.div `
  display: flex;
  position: relative;
  width: max-content;
  max-width: 100%;
  cursor: pointer;
  aspect-ratio: 1 / 1;
  max-height: 165px;

  @media ${from(Device.TabletLarge)} {
    display: none;
  }
`;
const ZoomIcon = styled.div `
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  background-color: ${brand.grey.grey89};
  color: ${brand.grey.grey20};
  border-radius: 6px;

  svg {
    display: flex;
    width: 20px;
    height: 20px;
  }
`;
const Image = styled.img `
  width: 100%;
  padding: 7px;
  border: 2px solid ${brand.grey.grey89};
  border-radius: 5px;

  @media ${until(Device.TabletLarge)} {
    object-fit: contain;
  }
`;
const ModalBackground = styled(m.div).attrs(({ visible }) => ({
    initial: 'hidden',
    animate: visible ? 'visible' : 'hidden',
    exit: 'hidden',
    variants: {
        hidden: {
            opacity: 0,
            y: 25,
        },
        visible: {
            opacity: 1,
            y: 0,
        },
    },
})) `
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ visible }) => !visible &&
    css `
      pointer-events: none;
    `}
`;
const ModalContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 90vw;
  max-height: 85dvh;
  width: 100%;
  height: 100%;
`;
const Close = styled.div `
  margin-left: auto;
  width: 25px;
  height: 25px;
  color: ${brand.white};
  cursor: pointer;
`;
const ZoomedImage = styled.div `
  padding: 10px;
  background-color: ${brand.white};
  flex-grow: 1;

  .react-transform-wrapper {
    height: 100%;
    width: 100%;
  }
`;
const ControlsBar = styled.div `
  display: flex;
  gap: 10px;
  justify-content: center;
`;
const Control = styled.div `
  width: 35px;
  height: 35px;
  padding: 5px;
  background-color: ${brand.grey.grey89};
  color: ${brand.black};
  border-radius: 5px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
`;
export const ZoomImageStyles = {
    Container,
    Image,
    ZoomIcon,
    ModalBackground,
    ModalContent,
    Close,
    ZoomedImage,
    ControlsBar,
    Control,
};
