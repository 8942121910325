import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import styled, { css } from 'styled-components';
const Container = styled.div `
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 45px auto;
  overflow: hidden;

  svg {
    display: block;
    width: 100%;
  }
`;
const Ground = styled.div ``;
const Water = styled.div `
  position: absolute;
  left: 0;
  transition: top 0.2s ease-in-out;

  ${({ height }) => css `
      top: ${height * 20 <= 5 ? 5 : height * 20}px;
    `}

  width: 100%;
`;
const ExcavationOverlay = styled.div `
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 75px;
  ${({ height }) => height !== undefined &&
    css `
      height: min(${height * 20}px, 100px);
    `};
  background-color: ${brand.white};
  border: 2px solid ${brand.black};
  border-top: none;
  padding: 5px 7px;
  z-index: 1;

  display: flex;
  align-items: center;
  gap: 5px;

  span {
    color: ${brand.primary.alt};
    ${fonts.DaxPro.Medium};
    font-size: 13px;
  }
`;
const Arrow = styled.div `
  position: relative;
  height: calc(100% - 5px);
  width: 1px;
  background-color: ${brand.primary.alt};

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 35%;
    transform: translateX(-50%);
    background-color: ${brand.primary.alt};
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    width: 7px;
    height: 7px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 35%;
    transform: translateX(-50%);
    background-color: ${brand.primary.alt};
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    width: 7px;
    height: 7px;
  }
`;
export const GroundwaterDepthStyles = {
    Container,
    Ground,
    Water,
    ExcavationOverlay,
    Arrow,
};
