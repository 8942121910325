import React, { useEffect, useMemo, useState } from 'react';
import { SharedStyles } from '@ys/Components/Shared.styles';
import { FormStyles } from '@ys/Components/Shared/Form.styles';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import Solution from './Solution/Solution';
import { Containers, StepStyles } from '@ys/Components/Shared/Step.styles';
import { StepID } from '@stories/Widgets/YourSolution/YS/Context/types';
import { YSPSolutionsStyles as S } from './YSPSolutions.styles';
import { withMotion } from '@hoc/withMotion';
import { ReactComponent as Warning } from '@img/ysp/warning.svg';
import { SolutionStyles } from './Solution/Solution.styles';
import Dropdown from '../../Controls/Dropdown/Dropdown';
import { isSSR } from '@helpers/ssr';
const YSPSolutions = () => {
    const { state, dispatch } = useYSPContext();
    const [selectedSolution, setSelectedSolution] = useState();
    useEffect(() => {
        if (selectedSolution !== undefined && !isSSR()) {
            const matchingSolution = document.querySelector(`div[data-solution-id='${selectedSolution.name}']`);
            if (matchingSolution) {
                matchingSolution.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [selectedSolution]);
    const dropdownOptions = useMemo(() => {
        if (state.yspResults) {
            if (state.yspResults.solutions && state.yspResults.solutions.length > 0) {
                return state.yspResults.solutions.map((s, i) => ({
                    id: i,
                    text: s.name,
                    selectable: s.selectable,
                }));
            }
        }
        return [];
    }, [state.yspResults]);
    if (!state.yspResults) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(SharedStyles.Question, null,
        React.createElement(StepStyles.Grid, null,
            React.createElement(Containers.Wide, null,
                React.createElement(FormStyles.Form, null,
                    React.createElement("h2", null, state.labels['heading'] || 'Proposed Solutions for your excavation')),
                state.yspResults.solutions && (React.createElement(React.Fragment, null,
                    React.createElement(S.DropdownWrapper, null,
                        React.createElement("span", { dangerouslySetInnerHTML: {
                                __html: state.labels['content'] ||
                                    'Please select your preferred box type from the dropdown or select from the list below.',
                            } }),
                        React.createElement(Dropdown, { short: true, options: dropdownOptions, onChange: (s) => state.yspResults && setSelectedSolution(state.yspResults.solutions[s]), value: selectedSolution ? state.yspResults.solutions.indexOf(selectedSolution) : -1 })),
                    React.createElement(S.Solutions, null, state.yspResults.solutions.map((solution, i) => (React.createElement(Solution, { solution: solution, key: `Solution_${i}`, i: i })))))),
                state.yspResults.territoryManager && (React.createElement(S.NoSolutions, null,
                    React.createElement(Warning, null),
                    React.createElement(S.NoSolutionsTitle, { dangerouslySetInnerHTML: { __html: state.yspResults.noSolutionHeading || '' } }),
                    React.createElement(S.NoSolutionsSubtitle, { dangerouslySetInnerHTML: { __html: state.yspResults.noSolutionText || '' } }),
                    React.createElement(SolutionStyles.LongSmallButton, { orange: true, onClick: () => dispatch({ type: 'go-to-step', payload: StepID.Dimensions }) }, "Change parameters"),
                    React.createElement(S.NoSolutionsGetInTouch, { dangerouslySetInnerHTML: { __html: state.yspResults.contactText || '' } }),
                    React.createElement(S.NoSolutionsDetails, null,
                        React.createElement("span", null, state.yspResults.territoryManager.name),
                        React.createElement("a", { href: `mailto:${state.yspResults.territoryManager.email}` },
                            React.createElement("span", null, state.yspResults.territoryManager.email)),
                        React.createElement("a", { href: `tel:${state.yspResults.territoryManager.telephone}` },
                            React.createElement("span", null, state.yspResults.territoryManager.telephone)))))))));
};
export default withMotion(YSPSolutions);
