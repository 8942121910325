import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import styled, { css } from 'styled-components';
const Container = styled.div `
  padding: 10px;
  background-color: ${brand.white};
  overflow: auto;

  @media ${from(Device.ActualDesktop)} {
    padding: 25px;
  }
`;
const GraphWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: max-content;
  @media ${from(Device.ActualDesktop)} {
    width: 100%;
  }
`;
const GraphContainer = styled.div `
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  min-height: 500px;
  min-width: 440px;
  position: relative;
  margin-top: 35px;
`;
const Title = styled.div `
  ${fonts.DaxPro.Medium};
`;
const Graph = styled.div `
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
`;
const Box = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: inherit;
  border-bottom: 3px solid ${brand.ysp.ground};
  align-items: flex-end;

  img {
    display: flex;
    max-height: 500px;
    width: 100%;
    height: inherit;
    object-fit: contain;
    object-position: center right;

    &:first-of-type {
      transform: rotateY(180deg);
    }
  }
`;
const ProportionalContent = styled.div `
  border-top: 3px solid ${brand.ysp.ground}; // Ground level
  border-bottom: 3px dashed ${brand.ysp.excavation}; // Dig level
  position: relative;

  ${({ offset }) => css `
      top: ${offset}%;
      height: calc(100% - ${offset}%);
    `};
`;
const LeftContent = styled(ProportionalContent) `
  border-right: 3px solid ${brand.ysp.ground};
`;
const RightContent = styled(ProportionalContent) `
  border-left: 3px solid ${brand.ysp.ground};
`;
const BGLLabel = styled.div `
  position: absolute;

  ${({ side }) => side === 'left'
    ? css `
          left: 0;
        `
    : css `
          right: 0;
        `}
  bottom: calc(100% + 15px);
  width: max-content;
`;
const Label = styled.div `
  padding: 3px;
  background-color: ${brand.white};
  position: absolute;
  z-index: 1;

  ${({ x }) => x === 'left'
    ? css `
          right: 10px;
        `
    : css `
          right: 0px;
          color: ${brand.ysp.ground};
        `}

  ${({ y }) => y === 'top'
    ? css `
          top: 0;
          transform: translateY(calc(-50% - 2px)); // Offset the border too //
        `
    : css `
          bottom: 0;
          transform: translateY(calc(50% + 2px)); // Offset the border too //
        `}
`;
const SoilContainer = styled.div `
  position: relative;
  height: 100%;
`;
const SoilLayer = styled.div `
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  background-image: url('${({ image }) => image}');
  background-size: 20px;

  ${({ top }) => css `
      top: ${top}%;
    `};
  ${({ height }) => css `
      height: ${height}%;
    `};

  span {
    padding: 5px;
    font-size: 13px;
    border-radius: 2px;
    background-color: ${brand.white};
    max-width: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
const GroundwaterLevel = styled.div `
  position: absolute;
  background-color: #b2e0ed;
  border-top: 3px dashed #137ff6;
  width: 100%;

  &::before {
    content: 'GWL';
    position: absolute;
    bottom: calc(100% + 13px);
    font-size: 14px;
    left: 0;
    color: #137ff6;
  }

  ${({ top }) => css `
      top: ${top}%;
      height: calc(100% - ${top}%);
    `};
`;
export const GraphStyles = {
    Container,
    GraphWrapper,
    Title,
    Graph,
    GraphContainer,
    Box,
    ProportionalContent,
    LeftContent,
    RightContent,
    BGLLabel,
    Label,
    SoilContainer,
    SoilLayer,
    GroundwaterLevel,
};
