import { ReactComponent as Close } from '@img/ysp/icon-cross.svg';
import YSLogoNoText from '@img/ysp/ys-no-strapline.png';
import YSLogo from '@img/ysp/ys-strapline.png';
import YSPLogo from '@img/ysp/ysp-header-logo-crop.png';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import { Tab } from '@ys/Context/types';
import { AnimateSharedLayout } from 'framer-motion';
import React from 'react';
import { TabsStyles as S } from './Tabs.styles';
const Tabs = (props) => {
    const { state, dispatch } = useYSPContext();
    return (React.createElement(React.Fragment, null,
        React.createElement(S.BackToMainSiteMobile, null,
            state.tab === Tab.YourSolution ? (React.createElement(S.Image, { src: YSLogoNoText })) : (React.createElement(S.Image, { src: YSPLogo })),
            React.createElement(Close, { onClick: () => props.closeModal && props.closeModal() })),
        React.createElement(S.Container, null,
            React.createElement(AnimateSharedLayout, null,
                React.createElement(S.Tab, { onClick: () => dispatch({ type: 'set-tab', payload: Tab.YourSolution }) },
                    state.mobile ? React.createElement(S.Image, { src: YSLogoNoText }) : React.createElement(S.Image, { src: YSLogo }),
                    state.tab === Tab.YourSolution && (React.createElement(S.BackgroundEffect, { layoutId: "background-effect", tab: Tab.YourSolution })),
                    React.createElement(S.BottomLine, null)),
                React.createElement(S.Tab, { onClick: () => dispatch({ type: 'set-tab', payload: Tab.YourSolutionPlus }) },
                    state.mobile ? React.createElement(S.Image, { src: YSPLogo }) : React.createElement(S.Image, { src: YSPLogo }),
                    state.tab === Tab.YourSolutionPlus && (React.createElement(S.BackgroundEffect, { layoutId: "background-effect", tab: Tab.YourSolutionPlus })),
                    React.createElement(S.BottomLine, null))))));
};
export default Tabs;
